import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';


import { convertResponseToDocumentWorkflowLevelDTO, DocumentWorkflowDetailsDTO, DocumentWorkflowLevelDTO, DocumentWorkflowLevelFormDTO } from '../dto/document-workflow-level.dto';
import { FormGroup } from '@angular/forms';
import { WorkFlowLevelTypeDTO } from '../../general-settings/dto/workflow-level-types.dto';

@Injectable({
  providedIn: 'root',
})
export class DocumentWorkflowLevelConversion
 
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

    getWorkFlowLevelById(optionId: number, workFlowLevelTypes: WorkFlowLevelTypeDTO[]){
      return workFlowLevelTypes.find((level) => optionId === level.id);
    }

    formGroupToForm(formGroup: FormGroup, workFlowLevelTypes: WorkFlowLevelTypeDTO[], workflowLevels: DocumentWorkflowLevelDTO[]): any {

      interface FormValues {
        LevelType?: number;
        ParentLevel?: number;
        ApprovalType?: string;
        ApproverUser?: number;
        ApproverUsers?: number[];
        Roles?: number;
      }
      const values = formGroup.value as FormValues;
      const levelType = values.LevelType ? values.LevelType : 0;

      const level = this.getWorkFlowLevelById(levelType, workFlowLevelTypes)
      let parentLevel = values.ParentLevel ? parseInt(values.ParentLevel.toString(), 10) : undefined;
      const highestLevel = workflowLevels.reduce((max, level) => level.level > max ? level.level : max, 0);
      if(workflowLevels.length > 0 && parentLevel === undefined){
        parentLevel = highestLevel
      }

      const levelTypeId = values.LevelType ? values.LevelType : undefined;
      const approvalType = values.ApprovalType || '';
      const approverUserId = values.ApproverUser ? [values.ApproverUser] : undefined;
      const approverUserIds = values.ApproverUsers
    
      const roleId = values.Roles ? values.Roles : undefined;
      let formGroupUpdate: any;
      switch (level?.type) {
        case 'individual':
            formGroupUpdate = {
                parent_level: parentLevel,
                level_type_id: levelTypeId,
                approval_type: "anyone",
                user_ids: approverUserId
            };
            break;
        case 'multiple-users':
            formGroupUpdate = {
                parent_level: parentLevel,
                level_type_id: levelTypeId,
                approval_type: approvalType,
                user_ids: approverUserIds
            };
            break;
        case 'role':
            formGroupUpdate = {
                parent_level: parentLevel,
                level_type_id: levelTypeId,
                approval_type: approvalType,
                role_id: roleId
            };
            break;
        case 'hod':
            formGroupUpdate = {
                parent_level: parentLevel,
                level_type_id: levelTypeId,
                approval_type: "anyone",
            };
            break;
        default:
          formGroupUpdate = {
            parent_level: parentLevel,
            level_type_id: levelTypeId,
            approval_type: approvalType,
          };
            break;
      }
      return formGroupUpdate
    }

    resToForm(response: any): any {
        return {

            id : response.id,
            ParentLevel : response.parent_level,
            LevelType : response.level_type?.id,
            ApprovalType : response.approval_type,
            ApproverUsers : response.users?.id,
            Roles : response.role?.id
        };
    }
    
    resToDetails(response: any): DocumentWorkflowDetailsDTO {
        return {
            id: response.id,
            type: response.type,
            document_type_id: response.document_type_id,
            status: response.status,
            workflow_levels: convertResponseToDocumentWorkflowLevelDTO(response.workflow_levels, this.lang),
           
        };
    }

}