import { Routes } from '@angular/router';
import { documentRegisterRoutes } from './document-register/document-register-routes';
import { documentChangeRequestRoutes } from './change-request/change-request-routes';

export const documentRoutes: Routes = [
  {
    path: 'register',
    children: [...documentRegisterRoutes],
  },
  {
    path: 'change-request',
    children: [...documentChangeRequestRoutes],
  },
];
