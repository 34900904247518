import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { FormSection } from '../../../../shared/models/form.config';
import { actionPlanFormConfig } from '../../../../shared/config/action-plan-form.config';
import { mapToUsers } from '../../../../core/modals/form.modal';

@Injectable({ providedIn: 'root' })
export class ComplianceActionPlanFormConfig {
  formSections: FormSection[] = actionPlanFormConfig;
  private lang: string;
  constructor(private userPreference: CurrentUserPreferenceStore) {
    this.lang = this.userPreference.languageKey;
  }

  updateOptions(
    fields: FormSection[],
    severityLevels: any[],
    actionPlanStatuses: any[],
    responsibleUsers: any
  ): void {
    fields.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.id === 'severityLevel') {
          field.options = severityLevels.map((severityLevel) => ({
            id: severityLevel.id,
            title: severityLevel[`title_${this.lang}`],
          }));
        }

        if (field.id === 'actionPlanStatus') {
          field.options = actionPlanStatuses.map((status) => ({
            id: status.id,
            title: status[`title_${this.lang}`],
          }));
        }

        if (field.id === 'responsibleUser') {
          field.options = mapToUsers(responsibleUsers, this.lang);
        }
      });
    });
  }
}
