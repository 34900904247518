import { Injectable } from '@angular/core';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  TableQuickSortItem,
} from '../../../../core/modals/table.modal';
import { FormSection } from '../../../../shared/models/form.config';
import { UiStore } from '../../../../core/store/ui.store';
import { AlertService } from '../../../../core/services/alert.service';
import { SlaContractActionPlanFormConfig } from '../config/sla-contract-action-plan-form.config';
import { SlaContractActionPlanStore } from './sla-contract-action-plan.store';
import { SlaContractActionPlanService } from '../services/sla-contract-action-plan.service';
import { SeverityLevelService } from '../../../settings/masters/services/severity-level.service';
import { UserService } from '../../../organization/users/services/user.service';
import { SlaContractActionPlanFormDTO, convertFormToSlaContractActionPlanFormDTO, convertResponseToForm } from '../dto/sla-contract-action-plan.dto';
import { SlaContractDetailsStore } from './sla-contract-details.store';
import { ActionPlanStatusDTO } from '../../../settings/masters/dto/action-plan-status.dto';
import { ActionPlanStatusService } from '../../../settings/masters/services/action-plan-status.service';
import { SeverityLevelDTO } from '../../../settings/masters/dto/severity-level.dto';
import { UserDTO, UserListDTO } from '../../../organization/users/dto/user.dto';
import { FormBaseStore } from '../../../../core/store/form-base.store';


@Injectable({ providedIn: 'root' })
export class SlaContractActionPlanFormStore extends FormBaseStore<SlaContractActionPlanFormDTO>{
    entityName: string = 'SLA action plan';
    fields: FormSection[] = [];
    quickSortItems: TableQuickSortItem[] = [
        { label: 'Newest to Oldest', key: '', active: false },
        { label: 'Active to Inactive', key: '', active: false },
        { label: 'Alphabetical Order (A to Z)', key: '', active: false },
    ];
    itemId: number | null = null;

    actionPlanStatuses: ActionPlanStatusDTO[] = [];
    severityLevels: SeverityLevelDTO[] = [];
    responsibleUsers: UserListDTO[] = [];

    constructor(
        protected  slaContractDetailsStore: SlaContractDetailsStore,
        protected  uiStore: UiStore,
        protected  slaContractActionPlanStore: SlaContractActionPlanStore,
        protected  alertService: AlertService,
        private slaContractActionPlanFormConfig: SlaContractActionPlanFormConfig,
        protected  service: SlaContractActionPlanService,
        private actionPlanStatusService: ActionPlanStatusService,
        private severityLevelService: SeverityLevelService,
        private userService: UserService,
    ) {

        super();
        makeObservable(this, {
            title: observable,
            addButtonLabel: observable,
            submitButtonTitle: observable,
            submitButtonLoadingText: observable,
            submitButtonAndNewTitle: observable,
            submitButtonAndNewLoadingTitle: observable,
            cancelButtonText: observable,
            fields: observable,
            quickSortItems: observable,
            itemId: observable,
            resetEntireState: action,
            resetForm: action,
            patchForm: action,
            convertFormToDTO: action,
            convertResponseToForm: action,
        });

        this.fields = this.slaContractActionPlanFormConfig.formSections;
    }



    resetEntireState(): void {
        runInAction(() => {
            this.entityName = 'SLA action plan';
            this.title = 'Add SLA Contract Action Plan';
            this.addButtonLabel = 'Add SLA Contract Action Plan';
            this.submitButtonTitle = 'Save';
            this.submitButtonLoadingText = 'Saving';
            this.submitButtonAndNewTitle = 'Save & New';
            this.submitButtonAndNewLoadingTitle = 'Saving';
            this.cancelButtonText = 'Cancel';
            this.itemId = null;
            this.isItemFetching = false;
            this.isSideFormOpen = true;
            this.resetForm();
        });
    }

    getMasterLists() {
        return forkJoin({
            severityLevels: this.severityLevelService.getSeverityLevels(),
            actionPlanStatuses: this.actionPlanStatusService.list(),
            responsibleUsers: this.userService.list("name", "asc", 1, 500),
        })
        .pipe(
            tap(({ actionPlanStatuses, severityLevels, responsibleUsers}) => {
            runInAction(() => {
                this.severityLevels = severityLevels
                this.actionPlanStatuses = actionPlanStatuses
                this.responsibleUsers = responsibleUsers.items
              });
            }),
            catchError((error) => {
                console.error('Error fetching master lists', error);
                throw error;
            })
        )

    }



    override fetchItem(id: number): void {
        this.isItemFetching = true;
        this.itemId = id;
        this.service.getSlaActionPlan(this.slaContractDetailsStore.details.id, id).subscribe(
          (response: any) => {
            this.updateFormOptions();
            this.resetForm();
            this.patchForm(response);
            this.isItemFetching = false;
          },
          (error: any) => {
            console.error('Error fetching details', error);
            this.isItemFetching = false;
          }
        );
    }



    override loadItems(): void {
        this.slaContractActionPlanStore.loadItems();
    }

    override saveItem(isClose: boolean = false): void {
        this.uiStore.startSaving();

        if (!this.formGroup.valid) {
          this.uiStore.finishSaving();
          return;
        }

        const formData = this.convertFormToDTO();

        const serviceCall = this.itemId
          ? this.service.updateSlaActionPlan(this.slaContractDetailsStore.details.id, this.itemId, formData)
          : this.service.createSlaActionPlan(this.slaContractDetailsStore.details.id, formData);

        serviceCall.subscribe({
            next: (response: any) => {
                runInAction(() => {
                    this.handleSaveSuccess(isClose);
                });
            },
            error: (error: any) => {
                this.uiStore.finishSaving();
                console.error(`Failed to ${this.itemId ? 'update' : 'save'} data:`, error);
            },
        });
    }

    private handleSaveSuccess(isClose: boolean) {
        if (this.itemId) {
            this.alertService.success(`The ${this.entityName} details have been successfully updated.`, 'Update Successful');
        } else {
            this.alertService.success(`A new ${this.entityName} has been successfully created.`, 'Creation Successful');
        }
        this.uiStore.finishSaving();
        this.loadItems();
        this.resetForm();
        if (isClose) {
            this.closeSideForm();
        }
    }

    patchForm(response: any) {
        this.formGroup.patchValue(this.convertResponseToForm(response));
    }

    updateFormOptions() {
        this.getMasterLists().subscribe({
            next: () => {
            this.slaContractActionPlanFormConfig.updateOptions(
                this.fields,
                this.severityLevels,
                this.actionPlanStatuses,
                this.responsibleUsers,
                );
            },
            error: (error) => {
            console.error('Error updating options', error);
            }
        });
    }

    convertFormToDTO(): SlaContractActionPlanFormDTO {
        return convertFormToSlaContractActionPlanFormDTO(this.formGroup);
    }

    convertResponseToForm(response: any): any {
        return convertResponseToForm(response);
    }

}
