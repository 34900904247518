import { Injectable } from '@angular/core';
import {
  FilterCategory,
  QuickFilterItem,
  TableActionConfig,
  TableColumnConfigurable,
} from '../../../../../core/modals/table.modal';
import {
  documentWorkflowListQuickFilters,
  documentWorkflowListTablecolumns,
} from '../../config/document-workflow-list.config';
import { DocumentWorkflowService } from '../../services/document-workflow.service';
import { DocumentWorkflowConversion } from '../../conversion/document-workflow.conversion';
import { DocumentWorkflowDTO } from '../../dto/document-workflow.dto';
import { ListBaseStore } from '../../../../../core/store/list-base.store';

@Injectable({ providedIn: 'root' })
export class DocumentWorkFlowStore extends ListBaseStore<DocumentWorkflowDTO> {
  override sortField: string = 'id';
  override columns: TableColumnConfigurable[];
  override quickFilters: QuickFilterItem[];
  override filterCategories: FilterCategory[] = [];
  override isPagination: boolean;

  actionConfig: TableActionConfig = {
    isEditable: false,
    isDeletable: false,
    isActivatable: false,
  };

  constructor(
    private documentWorkflowService: DocumentWorkflowService,
    private documentWorkflowConv: DocumentWorkflowConversion
  ) {
    super();
  }

  get service() {
    return this.documentWorkflowService;
  }

  override initialize(): void {
    this.quickFilters = documentWorkflowListQuickFilters;
    this.isPagination = false;
    this.isEmptyList = true;
    this.searchQuery = '';
    this.isInitialLoading = true;
    this.columns = documentWorkflowListTablecolumns;
    this.mainConv = this.documentWorkflowConv;
    this.extraParams.push({ key: 'type', value: 'document' });
  }

  getMasterLists() {}

  resetEntireState(): void {
    this.items = [];
    this.searchQuery = '';
    this.sortField = 'id';
    this.sortOrder = 'asc';
  }
}
