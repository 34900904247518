import { Injectable } from '@angular/core';
import {
  ComplinaceRequirementListResponseDTO,
  TransformedComplianceRequirementDTO,
} from '../dto/compliance-requirement.dto';
import {
  FilterCategory,
  QuickFilterItem,
  TableActionConfig,
  TableColumnConfigurable,
  defaultPaginationConfig,
  generatePages,
} from '../../../../core/modals/table.modal';
import { ComplianceRequirementService } from '../services/compliance-requirement.service';
import {
  complianceRequirementListQuickFilters,
  complianceRequirementListTablecolumns,
} from '../config/compliance-requirement-list.config';
import { runInAction } from 'mobx';
import { ListBaseStore } from '../../../../core/store/list-base.store';
import { ComplianceDetailsStore } from './compliance-details.store';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { ComplianceRequirementConversion } from '../conversion/compliance-requirement.conversion';

@Injectable({ providedIn: 'root' })
export class ComplianceRequirementStore extends ListBaseStore<TransformedComplianceRequirementDTO> {
  override sortField: string = 'compliance_clause_no';
  override columns: TableColumnConfigurable[];
  override quickFilters: QuickFilterItem[];
  override filterCategories: FilterCategory[] = [];
  addButtonLabel = 'Add Compliance Requirement';

  actionConfig: TableActionConfig = {
    isEditable: true,
    isDeletable: true,
    isActivatable: false,
  };

  constructor(
    private complianceDetailsStore: ComplianceDetailsStore,
    private complianceRequirementService: ComplianceRequirementService,
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private complianceRequirementConversion: ComplianceRequirementConversion
  ) {
    super();
  }

  override initialize() {
    this.quickFilters = complianceRequirementListQuickFilters;
    this.columns = complianceRequirementListTablecolumns;
    this.mainId = this.complianceDetailsStore.details.id;
  }

  get service() {
    return this.complianceRequirementService;
  }

  override convertResponseToListDTO(
    response: any
  ): TransformedComplianceRequirementDTO[] {
    return this.complianceRequirementConversion.resToTrnComplianceRequirementDTO(
      response
    );
  }

  override getMasterLists(): void {}

  resetEntireState(): void {
    runInAction(() => {
      this.items = [];
      this.sortField = 'compliance_clause_no';
      this.sortOrder = 'asc';
      this.searchQuery = '';
      this.isLoading = false;
      this.isInitialLoading = true;
      this.isEmptyList = true;
      this.mainId = undefined;
      this.paginationConfig = defaultPaginationConfig;
      this.filterCategories = [];
    });
  }
}
