import { Routes } from '@angular/router';
import { meetingRoutes } from './meetings/meeting-routes';

export const reviewMeetingRoutes: Routes = [
  {
    path: 'meetings',
    children: [...meetingRoutes],
  },
  {
    path: 'action-plan-list',
    loadComponent: () =>
      import(
        './meeting-action-plans/components/meeting-action-plan-list/meeting-action-plan-list.component'
      ).then((m) => m.MeetingActionPlanListComponent),
  },
];
