<div class="title-bar mb-3">
    <div class="row">
        <div class="col-md-5 d-flex align-items-center">
        </div>
        <div class="col-md-7 d-flex justify-content-end">

            <button class="btn btn-primary btn-theme ms-1" type="button">
                Submit
            </button>
        </div>
    </div>
  </div>

