import { Injectable } from '@angular/core';
import {
  defaultActionConfig,
  defaultPaginationConfig,
  FilterCategory,
  PaginationConfig,
  QuickFilterItem,
  TableActionConfig,
  TableColumnConfigurable,
} from '../../../../core/modals/table.modal';
import { ListBaseStore } from '../../../../core/store/list-base.store';

import { MeetingActionPlanListDTO } from '../dto/meeting-action-plan.dto';
import { MeetingActionPlanService } from '../services/meeting-action-plan.service';
import { MeetingDetailsStore } from './meeting-details.store';
import { MeetingActionPlanConversion } from '../conversion/meeting-action-plan.conversion';
import { meetingActionPlanListQuickFilters, meetingActionPlanListTableColumns } from '../config/meeting-action-plan-list.config';

@Injectable({ providedIn: 'root' })
export class MeetingActionPlanStore extends ListBaseStore<MeetingActionPlanListDTO> {
  override sortField: string = '';
  override columns: TableColumnConfigurable[];
  override quickFilters: QuickFilterItem[];
  override filterCategories: FilterCategory[] = [];
  actionConfig: TableActionConfig;

  constructor(
    private meetingActionPlanService: MeetingActionPlanService,
    private meetingDetailsStore: MeetingDetailsStore,
    private meetingActionPlanConv: MeetingActionPlanConversion
  ) {
    super();
  }

  get service() {
    return this.meetingActionPlanService;
  }

  override initialize() {
    this.sortField = 'title';
    this.sortOrder = 'asc';
    this.actionConfig = defaultActionConfig;
    this.quickFilters = meetingActionPlanListQuickFilters;
    this.columns = meetingActionPlanListTableColumns;
    this.paginationConfig = defaultPaginationConfig;
    this.mainId = this.meetingDetailsStore.details.id;
    this.mainConv = this.meetingActionPlanConv;
  }

  override getMasterLists(): void {}

  resetEntireState(): void {
    this.sortField = '';
    this.sortOrder = '';
    this.searchQuery = '';
    this.isLoading = false;
    this.isInitialLoading = true;
    this.isEmptyList = true;
    this.mainId = undefined;
    this.mainConv = undefined;
    this.actionConfig = {} as TableActionConfig;
    this.paginationConfig = {} as PaginationConfig;
    this.filterCategories = [];
    this.items = [];
  }
}
