import { Injectable } from '@angular/core';
import { AssociatedItemBaseStore } from '../../../../core/store/associated-item-base.store';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { AssociatedItemConversion } from '../../../../shared/conversion/associated-item.conversion';
import { ComplianceRequirementAssociatedItemService } from '../services/compliance-requirement-associated-item.service';

@Injectable({ providedIn: 'root' })
export class ComplianceRequirementDetailsAssociatedItemStore extends AssociatedItemBaseStore {
    protected override service: any;

    constructor(
        currentUserPreferenceStore: CurrentUserPreferenceStore,
        private complianceRequirementAssociatedItemService: ComplianceRequirementAssociatedItemService,
        private associatedItemConversion: AssociatedItemConversion
    ) {
        super(currentUserPreferenceStore, associatedItemConversion);
    }

    setMainItemId(itemId: any): void {
        this.mainItemId = itemId;
    }

    setService(): void {
        this.service = this.complianceRequirementAssociatedItemService;
    }
}
