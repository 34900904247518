<header class="page-header">
  <ul class="right-links">
    <li class="notify">
      <div class="dropdown">
        <button class="btn dropdown-toggle p-0  drop-arrow-remove" type="button" id="dropdownMenuButton1"
          data-bs-toggle="dropdown" aria-expanded="false">
          <span class="notification-count">10</span>
          <img alt="images" src="assets/images/icons/icon-notification.svg" class="me-0 w-auto" />
        </button>
      </div>
    </li>
    <!-- <li class="profile me-3">
      <div class="dropdown">
        <button class="btn dropdown-toggle p-0 drop-arrow-remove" type="button" id="dropdownMenuButton1"
          data-bs-toggle="dropdown" aria-expanded="false" (click)="logout()">
          <img alt="images" src="assets/images/user-images/sample-user.jpg" class="me-2 pro-img" />
          <span class="profile-name">Ameer sha</span>
        </button>
      </div>
    </li> -->
    <li class="profile me-3">
      <div class="dropdown ">
        <button class="btn dropdown-toggle p-0 " type="button" id="dropdownMenuButton1"
          data-bs-toggle="dropdown" aria-expanded="false">
          <img alt="images" src="assets/images/user-images/sample-user.jpg" class="me-2 pro-img" />
          <span class="profile-name">Ameer sha</span>
        </button>

  <div  class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton1">
   <div class="p-4 pb-1 text-center bt-bordered">
        <img  alt="images" src="assets/images/user-images/profile-user-dropdown.svg " class="mx-auto d-block profile-image" />
        <h5>Ameer Shah</h5>
      <p class="text-center p-mail-id">ameershah(at)jethur.com</p>
      </div>
        <ul>
          <li>
              <a class="dropdown-item" href="#"><img src="assets/images/icons/icon-view-profile.svg" />View Profile</a>
          </li>
          <li>
              <a class="dropdown-item " href="#"><img src="assets/images/icons/icon-company-profile.svg" />Company Profile</a>
          </li>
          <li class="bt-bordered">
              <a class="dropdown-item" href="#"><img src="assets/images/icons/icon-compliance-history.svg" />Compliance History</a>
          </li>
          <li>
              <a class="dropdown-item " href="#"><img src="assets/images/icons/icon-team.svg" />Team</a>
          </li>
          <li>
              <a class="dropdown-item " href="#"><img src="assets/images/icons/icon-invite-colleagues.svg" />Invite Colleagues</a>
          </li>
          <li class="bt-bordered">
              <a class="dropdown-item" href="#"><img src="assets/images/icons/icon-shortcuts.svg" />Shortcuts</a>
          </li>
          <li>
            <a class="dropdown-item " href="#"><img src="assets/images/icons/icon-catelog.svg" />Catelog</a>
        </li>
        <li>
            <a class="dropdown-item " href="#"><img src="assets/images/icons/icon-support.svg" />Support</a>
        </li>
        <li class="bt-bordered">
          <a class="dropdown-item " href="#"><img src="assets/images/icons/icon-api.svg" />API</a>
      </li>

      <li (click)="logout()">
        <a class="dropdown-item " href="javascript:void(0)"><img src="assets/images/icons/icon-logout.svg" />Logout</a>
    </li>
      </ul>
    </div>
      </div>
    </li>
  </ul>
</header>
