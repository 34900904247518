<app-details-modal
    [title]="'Add Level'"
    [showModal]="documentWorkflowLevelDetailsStore.isSideFormOpen"
    (closeModalRequest)="documentWorkflowLevelDetailsStore.closeSideForm()">
    <div class="row">
      <div class="col-md-8">
        <app-side-form
          [submitButtonTitle]="documentWorkflowLevelDetailsStore.submitButtonTitle"
          [submitButtonLoadingText]="documentWorkflowLevelDetailsStore.submitButtonLoadingText"
          [submitButtonAndNewTitle]="documentWorkflowLevelDetailsStore.submitButtonAndNewTitle"
          [submitButtonAndNewLoadingTitle]="documentWorkflowLevelDetailsStore.submitButtonAndNewLoadingTitle"
          [cancelButtonTitle]="documentWorkflowLevelDetailsStore.cancelButtonText"
          [formGroup]="documentWorkflowLevelDetailsStore.formGroup"
          [formSections]="documentWorkflowLevelDetailsStore.fields"
          [buttonPosition]="'bottom'"
          [isLoading]="documentWorkflowLevelDetailsStore.isItemFetching"
          (formSubmitRequest)="documentWorkflowLevelDetailsStore.saveItem($event)"
          (closeRequest)="documentWorkflowLevelDetailsStore.closeSideForm()">
    </app-side-form>
      </div>
    </div>
  
  </app-details-modal>