import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { FormSection } from '../../../../shared/models/form.config';
import { mapToUsers } from '../../../../core/modals/form.modal';

@Injectable({ providedIn: 'root' })
export class DocumentWorkflowLevelFormConfig {
  formSections: FormSection[] = [
    {
        key: '',
        title: '',
        fields: [
            
            {
                id: 'parentLevel',
                label: 'Level',
                formControlName: 'ParentLevel',
                type: 'dropdown',
                size: 12,
                placeholder: 'Parent Level',
                validators: [],
            },
            {
                id: 'levelType',
                label: 'Level Type',
                formControlName: 'LevelType',
                type: 'radiobutton',
                placeholder: 'Select Level',
                size: 12,
                isInfoIcon: false,
                validators: ['required'],
            },
            {
                id: 'approvalType',
                label: 'Approval Type',
                formControlName: 'ApprovalType',
                type: 'radiobutton',
                placeholder: 'Select approval type',
                options: [
                { id: 'all', title: 'All' },
                { id: 'anyone', title: 'Anyone' },
                ],
                size: 12,
                validators: ['required'],
            },
            {
                id: 'approverUser',
                label: 'Approver',
                formControlName: 'ApproverUser',
                type: 'autocomplete-static',
                placeholder: 'Select User',
                isInfoIcon: true,
                size: 12,
                validators: [],
            },
            {
                id: 'approverUsers',
                label: 'Approvers',
                formControlName: 'ApproverUsers',
                type: 'autocomplete-static',
                placeholder: 'Select Users',
                isMulitple: true,
                isInfoIcon: true,
                size: 12,
                validators: [],
            },
            {
                id: 'approverRole',
                label: 'Role',
                formControlName: 'Roles',
                type: 'autocomplete-static',
                placeholder: 'Select Role',
                isInfoIcon: true,
                size: 12,
                validators: [],
            },
        ],
    }
  ];
  constructor(
    private userPreference: CurrentUserPreferenceStore,
  ) {}

  updateOptions(
    fields: FormSection[],
    workflowLevels : any[],
    workFlowLevelTypes : any[],
    users: any[],
    roles: any[],
  ): void {
    const languageKey: string = this.userPreference.languageKey || 'en';    
    fields.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.id === 'parentLevel') {
            field.options = workflowLevels.map((level) => ({
              id: level.level,
              title: `Level ${level.level}`,
            }));
        }
        if (field.id === 'levelType') {
            field.options = workFlowLevelTypes.map((levelType) => ({
              id: levelType.id,
              title: levelType[`title_${languageKey}`],
            }));
        }
        if (field.id === 'approverUser') {
            field.options = mapToUsers(users, languageKey);
        }
        if (field.id === 'approverUsers') {
            field.options = mapToUsers(users, languageKey);
        }
        if (field.id === 'approverRole') {
          field.options = roles.map((role) => ({
            id: role.id,
            title: role[`title_${languageKey}`],
          }));
        }
       
      });
    });
  }
}
