import { Component } from '@angular/core';

@Component({
  selector: 'app-sla-contract-assessment',
  standalone: true,
  imports: [],
  templateUrl: './sla-contract-assessment.component.html',
  styleUrl: './sla-contract-assessment.component.scss'
})
export class SlaContractAssessmentComponent {

}
