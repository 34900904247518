import { AppSettingsStore } from './app-settings.store';
import { CurrentUserPreferenceStore } from './current-user-preference.store';

export function AppInitializeStores(
  appSettingsStore: AppSettingsStore,
  userPreferenceStore: CurrentUserPreferenceStore
): () => Promise<void> {
  return async () => {
    await Promise.all([
      appSettingsStore.initialize(),
      userPreferenceStore.initialize(),
    ]);
  };
}
