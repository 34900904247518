<div class="row">
    <div class="col-md-12">
      <app-breadcrumbs [breadcrumbs]="documentWorkflowLevelDetailsStore.breadcrumbs"></app-breadcrumbs>
    </div>
    @if(documentWorkflowLevelDetailsStore.isInitialLoading){
      <app-main-loader></app-main-loader>
    }@else if (documentWorkflowLevelDetailsStore.isEmptyList) {
      <div class="col-md-12">
        <app-no-data-page
          [title]="'Document Workflow'"
          [description]="'No workflow levels are currently configured for this document type.'"
          [isAddButton]="true"
          [addButtonLabel]="'Add Workflow Levels'"
          (addRequest)="documentWorkflowLevelDetailsStore.openSideForm()">
        </app-no-data-page>
      </div>
  
    }@else  {
      <div class="col-md-12">
        <app-list-page-header
          [isAddNewEnabled]="true"
          [isSearchEnabled]="false"
          [addButtonLabel]="documentWorkflowLevelDetailsStore.addButtonLabel"
          (addRequest)="documentWorkflowLevelDetailsStore.openSideForm()">
        </app-list-page-header>
      </div>
      <div class="col-sm-12">
        <ul class="tmline review-flow-tmline">
          @for(workflowLevel of documentWorkflowLevelDetailsStore.workflowLevels; track workflowLevel){
          <li class="tmline-inverted">
            <div class="tmline-badge"></div>
            <div class="tmline-panel">
              <div class="row">
                <div class="col-sm-3 d-flex align-items-center">
                  <h5 class="mb-0">Level {{workflowLevel.level}}</h5>
                </div>
                <div class="col-sm-4 d-flex align-items-center">
                  @if(workflowLevel.workflow_level_type?.type === "individual") {
                      @for(participant of workflowLevel.participants; track participant) {
                        <app-user-image
                          [imageUrl]="participant.profile_image_path"
                          [initial]="participant.initial"
                          [color]="participant.color"
                          [type]="'small'">
                        </app-user-image>
                        <div class="ms-2">
                          <span class="user-name fw-500">{{participant.first_name}} {{participant.last_name}}</span>
                        </div>
                      }
  
                  } @else if (workflowLevel.workflow_level_type?.type === "role") {
                      @for(participant of workflowLevel.participants; track participant) {
                        <div class="col-sm-4 ">
                          <span class="label-text">{{workflowLevel.workflow_level_type?.title}} :</span>
                        <p class="p-text mb-0">{{participant.title}}</p>
                        </div>
                      }
                  } @else if (workflowLevel.workflow_level_type?.type === "hod") {
                      <div class="col-sm-4 ">
                        <span class="label-text">{{workflowLevel.workflow_level_type?.title}}</span>
                      </div>
  
                  } @else if (workflowLevel.workflow_level_type?.type === "multiple-users") {
  
                    @for(participant of workflowLevel.participants; track participant; let index = $index) {
                      @if (index < 2) {
                        <app-user-image
                          [imageUrl]="participant.profile_image_path"
                          [initial]="participant.initial"
                          [color]="participant.color"
                          [type]="'small'">
                        </app-user-image>
                      }
                    }
                    <a  class="a-link ps-2  dropdown-menu-start cursor-pointer"  data-bs-toggle="dropdown" >View All</a>
                    <ul class="dropdown-menu">
                      @for(participant of workflowLevel.participants; track participant) {
                        <li>
                          <a class="dropdown-item d-flex align-items-center" href="#">
                            <app-user-image
                              [imageUrl]="participant.profile_image_path"
                              [initial]="participant.initial"
                              [color]="participant.color"
                              [type]="'small'">
                            </app-user-image>
                            {{participant.first_name}} {{participant.last_name}}
                          </a>
                        </li>
                      }
                    </ul>
                  }
  
                </div>
                <div class="col-sm-3">
                  @if (workflowLevel.workflow_level_type?.type === "multiple-users" || workflowLevel.workflow_level_type?.type === "role") {
                    <span class="label-text">Approval Type:</span>
                    <p class="p-text mb-0">{{workflowLevel?.approval_type}}</p>
                  }
                </div>
                <div  class="col-sm-1 d-flex align-items-center justify-content-end">
                  <button class="btn btn-link p-0 position-relative" (click)="documentWorkflowLevelDetailsStore.addLevelInBeteween(workflowLevel)" aria-label="Delete">
                    <i class="fas fa-plus" style="color: #165D31 ; cursor: pointer; position: absolute; right: 1rem; top: 50%; transform: translateY(-50%);"></i>
                  </button>
                </div>
                <div  class="col-sm-1 d-flex align-items-center justify-content-end">
                  <button class="btn btn-link p-0 position-relative" (click)="documentWorkflowLevelActionStore.deleteItem(workflowLevel.id)" aria-label="Delete">
                    <i class="fas fa-trash-alt" style="color: black; cursor: pointer; position: absolute; right: 1rem; top: 50%; transform: translateY(-50%);"></i>
                  </button>
                </div>
              </div>
            </div>
          </li>
        }
  
        </ul>
      </div>
    }
  
  </div>

  <app-workflow-level-form></app-workflow-level-form>
  
  