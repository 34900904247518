import { QuickFilterItem, TableColumnConfigurable } from '../../../../core/modals/table.modal';
import { DataCellSize, DataCellType } from '../../../../shared/dto/table.dto';

export const documentWorkflowListQuickFilters: QuickFilterItem[] = [
  {
    label: 'All Items',
    key: '',
    isActive: true,
  },
 
];


export const documentWorkflowListTablecolumns: TableColumnConfigurable[] = [
  {
    type: DataCellType.Text,
    field: 'document_type',
    header: 'Document Type',
    sortable: true,
    size: DataCellSize.Category,
    isEnable: true,
    isRequired: false,
  },

  
  {
    type: DataCellType.Text,
    field: 'status',
    header: 'Status',
    isPrimaryLevel: true,
    sortable: true,
    size: DataCellSize.Category,
    isEnable: true,
    isRequired: true,
  },
 
];
