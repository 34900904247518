import { Routes } from '@angular/router';

export const auidtUniverseRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import(
        './components/audit-universe-page/audit-universe-page.component'
      ).then((m) => m.AuditUniversePageComponent),
  },
];
