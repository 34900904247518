<div class="title-bar dashboard-title-bar">
  <!-- (click)="logout()" -->
  <div class="row">
    <div class="col-md-7" >
      <h2>Welcome, Abdullah</h2>
      <p>Jethur - Strong Roots , Solid Growth!</p>
    </div>
    <div class="col-md-5 d-flex justify-content-end ">

      <div class="more-dropdown me-2">
      <button type="button" class="btn btn-secondary btn-grey d-flex align-items-center">                    <img class="me-1" src="assets/images/icons/icon-calendar.svg" alt="icon-calendar"> April 10, 2024 </button>
</div>
      <div class="dropdown more-dropdown btn-grey me-2">
        <button type="button" data-bs-toggle="dropdown" class="btn btn-secondary dropdown-toggle btn-grey"> More </button>
        <ul aria-labelledby="more-dropdown-button" class="dropdown-menu">
            <li>
                <a class="dropdown-item" href="#">
                    <img class="me-1" src="assets/images/icons/icon-template.svg" alt="Template">Template
                </a>
            </li>
            <li>
                <a class="dropdown-item" href="#">
                    <img class="me-1" src="assets/images/icons/icon-import.svg" alt="Import">Import
                </a>
            </li>
            <li>
                <a class="dropdown-item" href="#">
                    <img class="me-1" src="assets/images/icons/icon-upload.svg" alt="Export">Export
                </a>
            </li>
            <li>
                <a class="dropdown-item" href="#">
                    <img class="me-1" src="assets/images/icons/icon-archive.svg" alt="Archive">Archive
                </a>
            </li>
            <li>
                <a class="dropdown-item" href="#">
                    <img class="me-1" src="assets/images/icons/icon-delete.svg" alt="Delete">Delete
                </a>
            </li>
            <li>
                <a class="dropdown-item" href="#">
                    <img class="me-1" src="assets/images/icons/icon-fresh.svg" alt="Refresh">Refresh
                </a>
            </li>
        </ul>
    </div>
    </div>
  </div>
</div>

<div class="main-dashboard">
  <div class="row">
    <div class="col-md-3">
      <div class="db-widget-green">

        <h5>Risk</h5>

        <h4 class="mt-4">80%</h4>
        <p>Policy Compliance Rate </p>

        <hr class="mt-5 mb-5">
       

        <h4 class="mt-4">80%</h4>
        <p>Policy Compliance Rate </p>

        <span class="status-success"><img class="me-1" src="assets/images/dashboard-images/icon-down-arrow-green.svg" alt="icon">20% Compare to last quarter</span>

      </div>

    </div>

    <div class="col-md-6">
      <div class="db-widget-orange">

<div class="row">
  <div class="col-md-12 mb-4">
    <h5>Audit</h5>

  </div>

  <div class="col-md-4"> <h4>80%</h4>
    <p>Audit Finding<br> Resolution Rate  </p></div>
    <div class="col-md-4"> <h4>80%</h4>
      <p>Audit Schedule<br> Adherence </p></div>
      <div class="col-md-4"> <h4>80%</h4>
        <p>Audit Recommendations <br>Implementation Rate   </p></div>
      


</div>





       

       

     


      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="db-widget-orange db-widget-grey">

            <div class="row">
              <div class="col-md-12 mb-4">
                <h5>Incident</h5>
            
              </div>
            
              <div class="col-md-12 d-flex align-items-center mb-3"> <h4 class="me-3 mb-0">80%</h4>
                <p class="mb-0">Incident <br>Closure Rate   </p></div>
                <div class="col-md-12">
                  <p class="mb-0" >Incident Frequency Rate <strong>5 per month</strong></p>
                </div>
               
            
            
            </div>
            
            
            
            
            
                   
            
                   
            
                 
            
            
                  </div>
            
        </div>
        <div class="col-md-6">
          <div class="db-widget-orange db-widget-light-green">

            <div class="row">
              <div class="col-md-12 mb-4">
                <h5>Control</h5>
            
              </div>
            
              <div class="col-md-12 d-flex align-items-center mb-3"> <h4 class="me-3 mb-0">85%</h4>
               </div>
                <div class="col-md-12">
                  <p  class="mb-0">Control Effectiveness <strong>Rate</strong> </p>
                </div>
               
            
            
            </div>
            
            
            
            
            
                   
            
                   
            
                 
            
            
                  </div>
            
        </div>
      </div>

    </div>
    <div class="col-md-3">
      <div class="db-widget-green db-widget-peach">

        <h5>Compliance  </h5>

        <h4 class="mt-4">95%</h4>
        <p>Compliance Requirement <br> Fulfillment Rate  </p>

        <hr class="mt-5 mb-5">
       

        <h4 class="mt-4">150</h4>
        <p>Policy Compliance Rate </p>

        <span class="status-danger"><img class="me-1" src="assets/images/dashboard-images/icon-down-arrow-red.svg" alt="icon">16% Compare to last quarter</span>

      </div>

    </div>

  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="module-filter">

        <span class="filter-tag active">
          Compliance
        </span> <span class="filter-tag">
          Risk
        </span> <span class="filter-tag">
          Incident
        </span> <span class="filter-tag">
          Documents
        </span> <span class="filter-tag">
          Task Summery
        </span> <span class="filter-tag">
          Total Matrix
        </span> <span class="filter-tag">
          Implementation Consideration
        </span> <span class="filter-tag">
          Financials
        </span> 


      </div>

    </div>
  </div>

  <div class="row module-section">
    <div class="col-md-12 d-flex">

      <h4>Compliance</h4> <a class="a-link mt-1 ms-2" href="#">Go to compliance dashboard</a>

    </div>

    <div class="col-md-12">
      <div class="module-box">

<div class="row">
  <div class="col-md-3">
    <div class="module-details-box">
<h5>Compliance Register Overview</h5>

<div class="score-box-sm w-100 total-score-box-sm mb-4" ><div class="label-text mb-2">Total complains registered</div><div class="d-flex align-items-center"><h6>60  </h6><span class="status-danger ms-2"><img class="me-1" src="assets/images/dashboard-images/icon-up-arrow-red.svg" alt="icon">16% Compared to last 6 months</span></div> </div>

<div class="d-flex w-100 ">
  <div class="score-box-sm w-50 d-block mb-3  me-2 d-flex align-items-start" >
    <img class="me-2 mt-1" src="assets/images/dashboard-images/icon-compliant.svg" width="20px" alt="Complaint">
    
    
    <div>
    <div class="label-text">Compliant</div><h6>58</h6></div></div>
    <div class="score-box-sm w-50 d-block mb-3  ms-2 d-flex align-items-start" >
      <img class="me-2 mt-1" src="assets/images/dashboard-images/icon-not-compliant.svg" width="20px" alt="Complaint">
      
      
      <div>
      <div class="label-text">Not Copliant</div><h6>16</h6></div></div>
      

</div>
<div class="d-flex w-100 ">
  <div class="score-box-sm w-50 d-block   me-2 d-flex align-items-start" style="min-height: 85px;">
    <img class="me-2 mt-1" src="assets/images/dashboard-images/icon-partialy-compliant.svg" width="20px" alt="Complaint">
    
    
    <div>
    <div class="label-text"> Partially Compliant</div><h6>58</h6></div></div>
    <div class="score-box-sm w-50 d-block   ms-2 d-flex align-items-start" style="min-height: 85px;" >
      <img class="me-2 mt-1" src="assets/images/dashboard-images/icon-others.svg" width="20px" alt="Complaint">
      
      
      <div>
      <div class="label-text">Others</div><h6>15</h6></div></div>
      

</div>

    </div>

  </div>

  <div class="col-md-9">
    <div class="module-content-box">
      <div class="row">
        <div class="col-md-9"><h5>Integrated Compliance Analysis
        </h5></div>
        <div class="col-md-3"><div class="form-group theme-select"><select id="exampleFormControlSelect1" class="form-control"><option>All Compliance</option><option>2</option></select></div></div>


      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <img class="me-1 img-fluid" src="assets/images/dashboard-images/chart-compliance-analysys.svg" alt="chart">
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
     

    </div>
  </div>

  <!-- module end -->



           <!-- module Start -->
  <div class="row module-section">
    <div class="col-md-12 d-flex">

      <h4>Risk</h4> <a class="a-link mt-1 ms-2" href="#">Go to risk dashboard</a>

    </div>

    <div class="col-md-12">
      <div class="module-box">

<div class="row">
  <div class="col-md-3">
    <div class="module-details-box">
<h5>Risk Overview</h5>

<div class="score-box-sm w-100 total-score-box-sm mb-4" ><div class="label-text mb-2">Total Risk</div><div class="d-flex align-items-center"><h6>60  </h6><span class="status-success ms-2"><img src="assets/images/dashboard-images/icon-down-arrow-green.svg" alt="icon" class="me-1"> 20% Compare to last quarter</span></div> </div>

<div class="d-flex w-100 ">
  <div class="score-box-sm w-50 d-block mb-3  me-2 d-flex align-items-start" >
    <img class="me-2 mt-1" src="assets/images/dashboard-images/icon-trend-up.svg" width="20" alt="Complaint">
    
    
    <div>
    <div class="label-text"><span class="status-dot-danger me-0"></span> High</div><h6 class="score-ps-2" >20</h6></div></div>
    <div class="score-box-sm w-50 d-block mb-3  ms-2 d-flex align-items-start" >
      <img class="me-2 mt-1" src="assets/images/dashboard-images/icon-trend-medium.svg" width="16" alt="Complaint">
      
      
      <div>
      <div class="label-text"> <span class="status-dot-warning me-0"></span> Medium</div><h6 class="score-ps-2">12</h6></div></div>
      

</div>
<div class="d-flex w-100 ">
  <div class="score-box-sm w-50 d-block   me-2 d-flex align-items-start" >
    <img class="me-2 mt-1" src="assets/images/dashboard-images/icon-trend-down.svg" width="20" alt="Complaint">
    
    
    <div>
    <div class="label-text"><span class="status-dot-success me-0"></span> Low</div><h6 class="score-ps-2">58</h6></div></div>
    
      

</div>

    </div>

  </div>

  <div class="col-md-6">
    <div class="module-content-box">
      <div class="row">
        <div class="col-md-12"><h5>Risk Heatmap
        </h5></div>


      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <img class="me-1 img-fluid" src="assets/images/dashboard-images/chart-heatmap.svg" alt="chart">
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="module-content-box">
      <div class="row">
        <div class="col-md-12"><h5>Top 5 Risks
        </h5></div>


      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <table class="table top-list-table">
            <tbody>
              <tr>
                <td>1</td>
                <td>Unauthorized Access to Customer Data</td>
                <td><span class="status-danger">High</span></td>
              </tr>
              <tr>
                <td>2</td>
                <td>Regulatory Compliance Failures</td>
                <td><span class="status-warning">Medium</span></td>
              </tr>
              <tr>
                <td>3</td>
                <td>Operational Interruptions and Downtime </td>
                <td><span class="status-success">Low</span></td>
              </tr>
              <tr>
                <td>4</td>
                <td>Financial Misconduct and Fraud</td>
                <td><span class="status-danger">High</span></td>
              </tr>
              <tr>
                <td>5</td>
                <td>Cybersecurity Threats and Attacks</td>
                <td><span class="status-danger">High</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
     

    </div>
  </div>
    <!-- module End -->
            <!-- module Start -->
  <div class="row module-section">
    <div class="col-md-12 d-flex">

      <h4>Incident</h4> <a class="a-link mt-1 ms-2" href="#">Go to Incident dashboard</a>

    </div>

    <div class="col-md-12">
      <div class="module-box">

<div class="row">
  <div class="col-md-3">
    <div class="module-details-box">
<h5>Incident Overview</h5>

<div class="score-box-sm w-100 total-score-box-sm mb-4" ><div class="label-text mb-2">Total Incident</div><div class="d-flex align-items-center"><h6>60  </h6><span class="status-success ms-2"><img  src="assets/images/dashboard-images/icon-down-arrow-green.svg" alt="icon" class="me-1"> 20% Compare to last quarter</span></div> </div>


<div class="d-flex w-100 ">
  <div class="score-box-sm w-50 d-block mb-3  me-2 d-flex align-items-start" >
    <img class="me-2 mt-1" src="assets/images/dashboard-images/icon-incident-open.svg" width="20px" alt="Complaint">
    
    
    <div>
    <div class="label-text">Open </div><h6>20</h6></div></div>
    <div class="score-box-sm w-50 d-block mb-3  ms-2 d-flex align-items-start" >
      <img class="me-2 mt-1" src="assets/images/dashboard-images/icon-incident-investigating.svg" width="20px" alt="Complaint">
      
      
      <div>
      <div class="label-text">Investigating</div><h6>12</h6></div></div>
      

</div>
<div class="d-flex w-100 ">
  <div class="score-box-sm w-50 d-block   me-2 d-flex align-items-start">
    <img class="me-2 mt-1" src="assets/images/dashboard-images/icon-incident-closed.svg" width="20px" alt="Complaint">
    
    
    <div>
    <div class="label-text"> Closed</div><h6>63</h6></div></div>

      

</div>

    </div>

  </div>

  <div class="col-md-6">
    <div class="module-content-box">
      <div class="row">
        <div class="col-md-9"><h5>Incident occurrence
        </h5></div>
        <div class="col-md-3"><div class="form-group theme-select"><select id="exampleFormControlSelect1" class="form-control"><option>By Category</option><option>2</option></select></div></div>



      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <img class="me-1 img-fluid" src="assets/images/dashboard-images/db-chart-incident.svg" alt="chart">
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="module-content-box">
      <div class="row">
        <div class="col-md-12"><h5>Top 5 Incidents
        </h5></div>


      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <table class="table top-list-table">
            <tbody>
              <tr>
                <td>1</td>
                <td>Unauthorized Access to Customer Data</td>
                <td><span class="status-danger">High</span></td>
              </tr>
              <tr>
                <td>2</td>
                <td>Unauthorized Access to Customer Data</td>
                <td><span class="status-warning">Medium</span></td>
              </tr>
              <tr>
                <td>3</td>
                <td>Unauthorized Access to Customer Data</td>
                <td><span class="status-success">Low</span></td>
              </tr>
              <tr>
                <td>4</td>
                <td>Unauthorized Access to Customer Data</td>
                <td><span class="status-danger">High</span></td>
              </tr>
              <tr>
                <td>5</td>
                <td>Unauthorized Access to Customer Data</td>
                <td><span class="status-danger">High</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
     

    </div>
  </div>
    <!-- module End -->
       <!-- module Start -->
  <div class="row module-section">
    <div class="col-md-12 d-flex">

      <h4>Documents </h4> <a class="a-link mt-1 ms-2" href="#">Go to Documents dashboard</a>

    </div>

    <div class="col-md-12">
      <div class="module-box">

<div class="row">
  <div class="col-md-3">
    <div class="module-details-box">
<h5>Documents Overview</h5>

<div class="score-box-sm w-100 total-score-box-sm mb-4" ><div class="label-text mb-2">Total Documents</div><div class="d-flex align-items-center"><h6>60  </h6><span class="status-success ms-2"><img  src="assets/images/dashboard-images/icon-down-arrow-green.svg" alt="icon" class="me-1"> 20% Compare to last quarter</span></div> </div>


<div class="d-flex w-100 ">
  <div class="score-box-sm w-50 d-block mb-3  me-2 d-flex align-items-start" >
    <img class="me-2 mt-1" src="assets/images/dashboard-images/icon-document-policies.svg" width="20" alt="Complaint">
    
    
    <div>
    <div class="label-text">Policies </div><h6>20</h6></div></div>
    <div class="score-box-sm w-50 d-block mb-3  ms-2 d-flex align-items-start" >
      <img class="me-2 mt-1" src="assets/images/dashboard-images/icon-document-procedures.svg" width="18" alt="Complaint">
      
      
      <div>
      <div class="label-text">Procedures</div><h6>12</h6></div></div>
      

</div>
<div class="d-flex w-100 ">
  <div class="score-box-sm w-50 d-block   me-2 d-flex align-items-start">
    <img class="me-2 mt-1" src="assets/images/dashboard-images/icon-document-other.svg" width="20" alt="Complaint">
    
    
    <div>
    <div class="label-text"> Other</div><h6>63</h6></div></div>

      

</div>

    </div>

  </div>

  <div class="col-md-6">
    <div class="module-content-box">
      <div class="row">
        <div class="col-md-9"><h5>Document Categories 
        </h5></div>
        <div class="col-md-3"><div class="form-group theme-select"><select id="exampleFormControlSelect1" class="form-control"><option>June</option><option>2</option></select></div></div>



      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <img class="me-1 img-fluid d-block mx-auto" src="assets/images/dashboard-images/db-document-chart.svg" alt="chart">
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="module-content-box">
      <div class="row">
        <div class="col-md-12"><h5>Recent Documents
        </h5></div>


      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <table class="table top-list-table">
            <tbody>
              <tr>
                <td>1</td>
                <td>ISO/IEC 27001 Audit Report</td>
                <td><span class="status-danger">Drafted</span></td>
              </tr>
              <tr>
                <td>2</td>
                <td>GDPR Compliance Assessment</td>
                <td><span class="status-warning">Medium</span></td>
              </tr>
              <tr>
                <td>3</td>
                <td>Annual Financial Report 2023</td>
                <td><span class="status-success">In&nbsp;progress</span></td>
              </tr>
              <tr>
                <td>4</td>
                <td>Cybersecurity Risk Analysis</td>
                <td><span class="status-success">Completed</span></td>
              </tr>
              <tr>
                <td>5</td>
                <td>Internal Control Procedures Update</td>
                <td><span class="status-success">Drafted</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
     

    </div>
  </div>
    <!-- module End -->
            <!-- module Start -->
  <div class="row module-section">
    <div class="col-md-12 d-flex">

      <h4>Review Meetings </h4> <a class="a-link mt-1 ms-2" href="#">Go to Meetings dashboard</a>

    </div>

    <div class="col-md-12">
      <div class="module-box">

<div class="row">
  <div class="col-md-3">
    <div class="module-details-box">
<h5>Meetings Overview</h5>

<div class="score-box-sm w-100 total-score-box-sm mb-4" ><div class="label-text mb-2">Total Review Meetings</div><div class="d-flex align-items-center"><h6>60  </h6><span class="status-success ms-2"><img  src="assets/images/dashboard-images/icon-down-arrow-green.svg" alt="icon" class="me-1"> 20% Compare to last quarter</span></div> </div>


<div class="d-flex w-100 ">
  <div class="score-box-sm w-50 d-block mb-3  me-2 d-flex align-items-start" >
    <img class="me-2 mt-1" src="assets/images/dashboard-images/icon-meeting-upcoming.svg" width="20" alt="Complaint">
    
    
    <div>
    <div class="label-text">Upcoming  </div><h6>20</h6></div></div>
    <div class="score-box-sm w-50 d-block mb-3  ms-2 d-flex align-items-start" >
      <img class="me-2 mt-1" src="assets/images/dashboard-images/icon-meeting-ongoing.svg" width="20" alt="Complaint">
      
      
      <div>
      <div class="label-text">Ongoing</div><h6>12</h6></div></div>
      

</div>
<div class="d-flex w-100 ">
  <div class="score-box-sm w-50 d-block   me-2 d-flex align-items-start">
    <img class="me-2 mt-1" src="assets/images/dashboard-images/icon-meeting-completed.svg" width="20" alt="Complaint">
    
    
    <div>
    <div class="label-text"> Completed </div><h6>63</h6></div></div>

      

</div>

    </div>

  </div>

  <div class="col-md-6">
    <div class="module-content-box">
      <div class="row">
        <div class="col-md-9"><h5>Review Meetings
        </h5></div>
        <div class="col-md-3"><div class="form-group theme-select"><select id="exampleFormControlSelect1" class="form-control"><option>June</option><option>2</option></select></div></div>



      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <img class="me-1 img-fluid d-block mx-auto" src="assets/images/dashboard-images/db-meeting-chart.svg" alt="chart">
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="module-content-box">
      <div class="row">
        <div class="col-md-12"><h5>Recent Meetings
        </h5></div>


      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <table class="table top-list-table">
            <tbody>
              <tr>
                <td>1</td>
                <td>
                  <div class="users-heads pe-2 dropdown"><img src="assets/images/user-images/user-1.png" width="33" height="33"><label  class="user-initials user-initials-sm text-white"> MS </label><img src="assets/images/user-images/user-1.png" width="33" height="33"></div>
                  <p class="mb-0 fw-bold">Quarterly Financial Review</p>
                  April 10, 2024, 10:00 AM -
12:00 PM, Confer...
                  </td>
                
              </tr>
              <tr>
                <td>2</td>
                <td>
                  <div class="users-heads pe-2 dropdown"><img src="assets/images/user-images/user-1.png" width="33" height="33"><label  class="user-initials user-initials-sm text-white"> MS </label><img src="assets/images/user-images/user-1.png" width="33" height="33"></div>
                  <p class="mb-0 fw-bold">Quarterly Financial Review</p>
                  April 10, 2024, 10:00 AM -
12:00 PM, Confer...
                  </td>              </tr>
              <tr>
                <td>3</td>
                <td>
                  <div class="users-heads pe-2 dropdown"><img src="assets/images/user-images/user-1.png" width="33" height="33"><label  class="user-initials user-initials-sm text-white"> MS </label><img src="assets/images/user-images/user-1.png" width="33" height="33"></div>
                  <p class="mb-0 fw-bold">Quarterly Financial Review</p>
                  April 10, 2024, 10:00 AM -
12:00 PM, Confer...
                  </td>              </tr>
            
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
     

    </div>
  </div>
    <!-- module End -->
                 <!-- module Start -->
  <div class="row module-section">
    <div class="col-md-12 d-flex">

      <h4>Action plan </h4> <a class="a-link mt-1 ms-2" href="#">Go to Meetings dashboard</a>

    </div>

    <div class="col-md-12">
      <div class="module-box">

<div class="row">
  <div class="col-md-3">
    <div class="module-details-box">
<h5>Task Overview</h5>

<div class="score-box-sm w-100 total-score-box-sm mb-4" ><div class="label-text mb-2">Total Task</div><div class="d-flex align-items-center"><h6>60  </h6><span class="status-success ms-2"><img  src="assets/images/dashboard-images/icon-down-arrow-green.svg" alt="icon" class="me-1"> 20% Compare to last quarter</span></div> </div>


<div class="d-flex w-100 ">
  <div class="score-box-sm w-50 d-block mb-3  me-2 d-flex align-items-start" >
    <img class="me-2 mt-1" src="assets/images/dashboard-images/icon-meeting-upcoming.svg" width="20" alt="Complaint">
    
    
    <div>
    <div class="label-text">Upcoming  </div><h6>20</h6></div></div>
    <div class="score-box-sm w-50 d-block mb-3  ms-2 d-flex align-items-start" >
      <img class="me-2 mt-1" src="assets/images/dashboard-images/icon-meeting-ongoing.svg" width="20" alt="Complaint">
      
      
      <div>
      <div class="label-text">Ongoing</div><h6>12</h6></div></div>
      

</div>
<div class="d-flex w-100 ">
  <div class="score-box-sm w-50 d-block   me-2 d-flex align-items-start">
    <img class="me-2 mt-1" src="assets/images/dashboard-images/icon-meeting-completed.svg" width="20" alt="Complaint">
    
    
    <div>
    <div class="label-text"> Completed </div><h6>63</h6></div></div>

      

</div>

    </div>

  </div>

  <div class="col-md-6">
    <div class="module-content-box">
      <div class="row">
        <div class="col-md-9"><h5>Task
        </h5></div>
        <div class="col-md-3"><div class="form-group theme-select"><select id="exampleFormControlSelect1" class="form-control"><option>June</option><option>2</option></select></div></div>



      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <img class="me-1 img-fluid d-block mx-auto" src="assets/images/dashboard-images/db-meeting-chart.svg" alt="chart">
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="module-content-box">
      <div class="row">
        <div class="col-md-12"><h5>Recent Tasks
        </h5></div>


      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <table class="table top-list-table">
            <tbody>
              <tr>
                <td>1</td>
                <td>ISO/IEC 27001 Audit Report</td>
                <td><span class="status-danger">Pending</span></td>
              </tr>
              <tr>
                <td>2</td>
                <td>GDPR Compliance Assessment</td>
                <td><span class="status-warning">In&nbsp;Progress</span></td>
              </tr>
              <tr>
                <td>3</td>
                <td>Annual Financial Report 2023</td>
                <td><span class="status-success">In&nbsp;progress</span></td>
              </tr>
              <tr>
                <td>4</td>
                <td>Cybersecurity Risk Analysis</td>
                <td><span class="status-success">Completed</span></td>
              </tr>
              <tr>
                <td>5</td>
                <td>Internal Control Procedures Update</td>
                <td><span class="status-success">Drafted</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
     

    </div>
  </div>
    <!-- module End -->



</div>
<div class="row">

  <div class="col-md-12"><button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#review-flow-setting-popup">
    Review Flow settings
  </button></div>
  <div class="col-md-4">
    <div class="widget-block widget-grey-bg">
      <div class="col-md-12"><h4>Heatmap  Details</h4></div>

      <div class="col-md-12 text-center">
        <div>
          <img
            src="assets/images/empty/empty-heatmap-img.svg"
            class="img-fluid d-block mx-auto mb-3"
          />

          <div style="margin-top: -100px;">
          <h5 class="font-lg">No Risk Heatmap Yet</h5>
          <p>
            You can see risk heatmap here, once they <br>are added.          </p>

        </div>
      </div>

      </div>


      <div class="col-md-12">
        <div class="users-heads pe-2 dropdown"><img src="assets/images/user-images/user-1.png" width="33" height="33"><label  class="user-initials user-initials-sm text-white"> MS </label><img src="assets/images/user-images/user-1.png" width="33" height="33"><a  class="a-link ps-2  dropdown-menu-start cursor-pointer"  data-bs-toggle="dropdown" >+2 Others</a>
        
          <ul class="dropdown-menu">
            <li><a class="dropdown-item d-flex align-items-center" href="#"><img class="me-2 ms-0" src="assets/images/user-images/user-1.png" width="24" height="24"> Muhammed Shihabudheen, UI Designer</a></li>
            <li><a class="dropdown-item d-flex align-items-center" href="#"><img class="me-2 ms-0" src="assets/images/user-images/user-1.png" width="24" height="24"> Muhammed Jabir, UI Designer</a></li>

            <li><a class="dropdown-item d-flex align-items-center" href="#"><label  class="user-initials user-initials-sm text-white ms-0 me-2"> MS </label> Muhammed Jabir, UI Designer</a></li>

          </ul></div>
      </div>

      <div class="col-md-12">
        <div class="users-heads pe-2 dropdown"><img src="assets/images/icons/icon-pdf.svg" width="33" height="33"><img src="assets/images/file-icons/icon-jpeg.svg" width="33" height="33"><img src="assets/images/file-icons/icon-jpeg.svg" width="33" height="33"><a  class="a-link ps-2  dropdown-menu-start cursor-pointer"  data-bs-toggle="dropdown" >+2 Files</a>
        
          <ul class="dropdown-menu">
            <li><a class="dropdown-item d-flex align-items-center" href="#"><img class="me-2" src="assets/images/file-icons/icon-pdf.svg" width="24" height="24"> Information.pdf</a></li>
            <li><a class="dropdown-item d-flex align-items-center" href="#"><img class="me-2" src="assets/images/file-icons/icon-jpeg.svg" width="24" height="24"> Anex.jpg</a></li>

            <li><a class="dropdown-item d-flex align-items-center" href="#"><img class="me-2" src="assets/images/file-icons/icon-txt.svg" width="24" height="24"> Codelab.txt</a></li>

          </ul></div>
      </div>









    </div>
  </div>
</div>


<!-- Review Flow Popup -->
<div
  class="modal fade theme-popup"
  id="review-flow-setting-popup"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Reviews Flows Settings</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12 d-flex justify-content-between">
            <h5>Review Flow Settings</h5>  <button  type="button" class="btn btn-primary btn-theme"><img src="assets/images/icons/icon-plus.svg" alt="undefined"> Add Level  </button>
          </div>
          <div class="col-sm-12">
            <ul class="tmline review-flow-tmline">
              <li class="tmline-inverted">
                <div class="tmline-badge"></div>
                <div class="tmline-panel">
                  <div class="row">
                    <div class="col-sm-3 d-flex align-items-center">
                      <h5 class="mb-0">Level 1</h5>
                    </div>
                    <div class="col-sm-3 ">
                      <span class="label-text">Role:</span>
                    <p class="p-text mb-0">Comparison Manager</p>
                    </div>
                    <div class="col-sm-4 d-flex align-items-center">
                   
                    </div>
                    <div
                      class="col-sm-2 d-flex align-items-center justify-content-end"
                    >
                    <button  type="button" data-bs-toggle="dropdown" class="action-button" id="action4"><img  src="assets/images/icons/icon-more.svg"></button>
                    </div>
                  </div>
                </div>
              </li>
              <li class="tmline-inverted">
                <div class="tmline-badge"></div>
                <div class="tmline-panel">
                  <div class="row">
                    <div class="col-sm-3 d-flex align-items-center">
                      <h5 class="mb-0">Level 2</h5>
                    </div>
                    <div class="col-sm-3 d-flex align-items-center">
                      <div
                        class="user-widget user-widget-md d-flex align-items-center"
                      >
                        <img
                          src="assets/images/user-images/user-1.png"
                          width="33"
                          height="33"
                        />
                        <div class="ms-2">
                          <span class="user-name fw-500">John Smith</span>
                          <p>Compliances Manager</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 ">
                      <span class="label-text">Approval Type:</span>
                    <p class="p-text mb-0">Approved by anyone</p>
                    </div>
                    <div
                      class="col-sm-2 d-flex align-items-center justify-content-end"
                    >
                    <button  type="button" data-bs-toggle="dropdown" class="action-button" id="action4"><img  src="assets/images/icons/icon-more.svg"></button>
                    </div>
                  </div>
                </div>
              </li>
              <li class="tmline-inverted">
                <div class="tmline-badge red-badge"></div>
                <div class="tmline-panel">
                  <div class="row">
                    <div class="col-sm-3 d-flex align-items-center">
                      <h5 class="mb-0">Level 3</h5>
                    </div>
                    <div class="col-sm-3 d-flex align-items-center">
                      <div
                        class="user-widget user-widget-md d-flex align-items-center"
                      >
                        <img
                          src="assets/images/user-images/user-1.png"
                          width="33"
                          height="33"
                        />
                        <div class="ms-2">
                          <span class="user-name fw-500">John Smith</span>
                          <p>Compliances Manager</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 d-flex align-items-center">
                      <!-- <p class="mb-0 fw-400">10 AM, June 1, 2024</p> -->
                    </div>
                    <div
                      class="col-sm-2 d-flex align-items-center justify-content-end"
                    >
                    <button  type="button" data-bs-toggle="dropdown" class="action-button" id="action4"><img  src="assets/images/icons/icon-more.svg"></button>
                    </div>
                  </div>
                </div>
              </li>
              <li class="tmline-inverted">
                <div class="tmline-badge"></div>
                <div class="tmline-panel">
                  <div class="row">
                    <div class="col-sm-3 d-flex align-items-center">
                      <h5 class="mb-0">Level 4</h5>
                    </div>
                    <div class="col-sm-3  align-items-center">
                      <span class="label-text">Reviewer:</span>
                      <div class="users-heads pe-2 dropdown"><img src="assets/images/user-images/user-1.png" width="33" height="33"><label  class="user-initials user-initials-sm text-white"> MS </label><img src="assets/images/user-images/user-1.png" width="33" height="33"><a  class="a-link ps-2  dropdown-menu-start cursor-pointer"  data-bs-toggle="dropdown" >+2 Others</a>
        
                        <ul class="dropdown-menu">
                          <li><a class="dropdown-item d-flex align-items-center" href="#"><img class="me-2 ms-0" src="assets/images/user-images/user-1.png" width="24" height="24"> Muhammed Shihabudheen, UI Designer</a></li>
                          <li><a class="dropdown-item d-flex align-items-center" href="#"><img class="me-2 ms-0" src="assets/images/user-images/user-1.png" width="24" height="24"> Muhammed Jabir, UI Designer</a></li>
              
                          <li><a class="dropdown-item d-flex align-items-center" href="#"><label  class="user-initials user-initials-sm text-white ms-0 me-2"> MS </label> Muhammed Jabir, UI Designer</a></li>
              
                        </ul></div>
                    </div>
                    <div class="col-sm-4 d-flex align-items-center">
                      <!-- <p class="mb-0 fw-400">10 AM, June 1, 2024</p> -->
                    </div>
                    <div
                      class="col-sm-2 d-flex align-items-center justify-content-end"
                    >
                    <button  type="button" data-bs-toggle="dropdown" class="action-button" id="action4"><img  src="assets/images/icons/icon-more.svg"></button>

                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
