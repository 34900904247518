import { Injectable } from '@angular/core';
import { TableColumnConfigurable } from '../../../../core/modals/table.modal';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';



@Injectable({ providedIn: 'root' })
export class SlaContractActionPlanListConfig {
    languageKey: string = 'en';
    public columns: TableColumnConfigurable[] = [];

    constructor(
        private userPreference: CurrentUserPreferenceStore,
    ) {
        this.languageKey = this.userPreference.languageKey;
        this.columns = this.createColumns();
    }

    public quickFilters(): any {
        return [
        {
            label: 'All Items',
            key: '',
            isActive: true,
        },
        {
            label: 'Fully Compliant Items',
            key: 'fully_compliant',
            isActive: false,
        },
        {
            label: 'Pending Review',
            key: 'pending_review',
            isActive: false,
        },
        ];
    }

    private createColumns(
    ): TableColumnConfigurable[] {
        const columns: TableColumnConfigurable[] = [
            
            {
                field: 'title',
                header: 'Title',
                sortable: true,
                size: 15,
                isEnable: true,
                isRequired: true,
            },
            {
                field: 'description',
                header: 'Description',
                sortable: true,
                limit:80,
                size: 15,
                isEnable: true,
                isRequired: true,
            },
            {
                field: 'severity_level_title_' + this.languageKey,
                header: 'Priority',
                sortable: true,
                size: 10,
                isEnable: true,
                isRequired: true,
            },
            {
                field: 'responsible_user_first_name_' + this.languageKey,
                header: 'Responsible User',
                sortable: true,
                size: 15,
                isEnable: true,
                isRequired: true,
            },
            {
                field: 'target_date',
                header: 'Target Date',
                sortable: true,
                size: 15,
                isEnable: true,
                isRequired: true,
            },
           
            {
                field: 'percentage',
                header: '% Completion',
                sortable: true,
                size: 15,
                isEnable: true,
                isRequired: true,
            },
            
            {
                field: 'action_plan_status_title_'+ this.languageKey,
                header: 'Status',
                sortable: true,
                size: 15,
                isEnable: true,
                isRequired: true,
            },
       
        ];

        return columns;
    }
}