import { TableColumnConfigurable } from '../../../../core/modals/table.modal';
import { DataCellSize, DataCellType } from '../../../../shared/dto/table.dto';

export const complianceRequirementListQuickFilters: any[] = [
  {
    label: 'All Items',
    key: '',
    isActive: true,
  },
  {
    label: 'High Severity',
    key: 'severe',
    isActive: false,
  },
  {
    label: 'Non Compliant',
    key: 'non_compliant',
    isActive: false,
  },
  {
    label: 'Due and Non Compliant',
    key: 'non_compliant_due',
    isActive: false,
  },
  {
    label: 'Risk Associated',
    key: 'risk_associated',
    isActive: false,
  },
];

export const complianceRequirementListTablecolumns: TableColumnConfigurable[] =
  [
    {
      type: DataCellType.Text,
      field: 'clause_no',
      header: 'Clause No',
      sortable: true,
      size: 100,
      isEnable: true,
      isRequired: true,
      isRawSpan: true,
    },
    {
      type: DataCellType.Text,
      field: 'clause_title',
      header: 'Clause',
      sortable: true,
      size: DataCellSize.Title,
      isEnable: true,
      isRequired: true,
      isRawSpan: true,
    },
    {
      type: DataCellType.Text,
      field: 'requirement',
      header: 'Requirement',
      sortable: true,
      size: DataCellSize.Para,
      isEnable: true,
      isRequired: true,
      isRawSpan: false,
    },
    {
      type: DataCellType.Text,
      field: 'compliance_category_title',
      header: 'Category',
      sortable: true,
      size: DataCellSize.Category,
      isEnable: true,
      isRequired: true,
      isRawSpan: false,
    },
    {
      type: DataCellType.ColorLabel,
      field: 'severity_level',
      header: 'Severity Level',
      sortable: true,
      size: DataCellSize.colorLabel,
      isEnable: true,
      isRequired: true,
      isRawSpan: false,
    },
    {
      type: DataCellType.User,
      field: 'responsible_user',
      header: 'Responsible User',
      sortable: true,
      size: DataCellSize.User,
      isEnable: true,
      isRequired: true,
      isRawSpan: false,
    },
    {
      type: DataCellType.Datetime,
      field: 'deadline',
      header: 'Deadline',
      sortable: true,
      size: DataCellSize.Date,
      isEnable: true,
      isRequired: true,
      isRawSpan: false,
    },
    {
      type: DataCellType.ColorLabel,
      field: 'compliance_status',
      header: 'Compliance Status',
      sortable: true,
      size: DataCellSize.colorLabel,
      isEnable: true,
      isRequired: true,
      isRawSpan: false,
    },
  ];
