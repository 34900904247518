import { Routes } from '@angular/router';
import { ComplianceDetailsRequirementsComponent } from './components/compliance-details/requirement/compliance-details-requirements/compliance-details-requirements.component';
import { ComplianceDetailsActionPlansComponent } from './components/compliance-details/action-plan/compliance-details-action-plans/compliance-details-action-plans.component';
import { ComplianceDetailsPdfPreviewComponent } from './components/compliance-details/pdf-preview/compliance-details-pdf-preview/compliance-details-pdf-preview.component';
import { ComplianceDetailsAssociatedItemsComponent } from './components/compliance-details/associated-item/compliance-details-associated-items/compliance-details-associated-items.component';

export const complianceRegisterRoutes: Routes = [
    {
        path: '',
        loadComponent: () =>
        import('./components/compliance-list/compliance-list.component').then(
            (m) => m.ComplianceListComponent
        ),
    },
    {
        path: ':id',
        loadComponent: () =>
            import(
                './components/compliance-details/compliance-details-page/compliance-details-page.component'
            ).then((m) => m.ComplianceDetailsPageComponent),
            children: [
                {
                  path: 'overview',
                  loadComponent: () =>
                    import(
                        './components/compliance-details/overview/compliance-details-overview/compliance-details-overview.component'
                    ).then((m) => m.ComplianceDetailsOverviewComponent),
                },
                {
                    path: 'pdf-preview',
                    component: ComplianceDetailsPdfPreviewComponent,
                },
                {
                    path: 'associated-items',
                    component: ComplianceDetailsAssociatedItemsComponent,
                },
                {
                    path: 'requirements',
                    component: ComplianceDetailsRequirementsComponent,
                },
                {
                    path: 'action-plans',
                    component: ComplianceDetailsActionPlansComponent,
                },
                {
                  path: '**',
                  redirectTo: 'overview',
                },
            ],

    },

];
