import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import {
  ComplianceRequirementDTO,
  ComplianceRequirementDetailsDTO,
  ComplianceRequirementFormDTO,
  TransformedComplianceRequirementDTO,
} from '../dto/compliance-requirement.dto';
import { convertResponseToComplianceClauseDetailsDTO } from '../../../settings/compliance/dto/compliance-clause.dto';
import { convertResponseToComplianceCategoryDetailsDTO } from '../../../settings/compliance/dto/compliance-category.dto';
import { convertResponseToSeverityLevelDetailsDTO } from '../../../settings/masters/dto/severity-level.dto';
import { convertResponseToUserMinimalDTO } from '../../../organization/users/dto/user.dto';
import { convertResponseToComplianceStatusDetailsDTO } from '../dto/compliance-status.dto';
import { UserConversion } from '../../../organization/users/conversion/user.conversion';
import { TableConversion } from '../../../../shared/conversion/table.conversion';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ComplianceRequirementConversion {
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private tableConv: TableConversion,
    private userConv: UserConversion
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  // private transformRequirement(
  //   requirement: any,
  //   clause_no: string | null,
  //   clause_title: string | null,
  //   rowspan: number | null
  // ): TransformedComplianceRequirementDTO {
  //   return {
  //     id: requirement.id,
  //     clause_no,
  //     clause_title,
  //     rowspan,
  //     requirement: requirement.title,
  //     compliance_category_title:
  //       requirement[`compliance_requirement_category_title_${this.lang}`],
  //     severity_level: this.tableConv.resToColordItem(
  //       requirement,
  //       'severity_level'
  //     ),
  //     responsible_user: this.userConv.resToUserMinimalDTO(
  //       requirement,
  //       'responsible_user'
  //     ),
  //     deadline: requirement.deadline,
  //     compliance_status: this.tableConv.resToColordItem(
  //       requirement,
  //       'compliance_status'
  //     ),
  //   };
  // }

  resToTrnComplianceRequirementDTO(
    data: ComplianceRequirementDTO[]
  ): TransformedComplianceRequirementDTO[] {
    const result: TransformedComplianceRequirementDTO[] = [];

    if (!data) {
      return result;
    }

    data.forEach((clause) => {
      const { clause_no, clause_title, requirements } = clause;
      const rowspan = requirements.length;

      requirements.forEach((requirement: any, index: number) => {
        if (index === 0) {
          result.push({
            id: requirement.id,
            clause_no: clause_no,
            clause_title: clause_title,
            requirement: requirement.title,
            rowspan,
            compliance_category_title: requirement[`compliance_requirement_category_title_${this.lang}`],
            severity_level:  this.tableConv.resToColordItem(requirement, 'severity_level'),
            responsible_user:  this.userConv.resToUserMinimalDTO(requirement, 'responsible_user'),
            deadline: requirement.deadline,
            compliance_status: this.tableConv.resToColordItem(requirement, 'compliance_status')
          });
        } else {
          result.push({
            id: requirement.id,
            requirement: requirement.title,
            compliance_category_title: requirement[`compliance_requirement_category_title_${this.lang}`],
            severity_level:  this.tableConv.resToColordItem(requirement, 'severity_level'),
            responsible_user:   this.userConv.resToUserMinimalDTO(requirement, 'responsible_user'),
            deadline: requirement.deadline,
            compliance_status: this.tableConv.resToColordItem(requirement, 'compliance_status')
          });
        }
       
      });

    });

    return result;
  }

  resToComplianceRequirementDetailsDTO(
    response: any
  ): ComplianceRequirementDetailsDTO {
    return {
      id: response.id,
      title: response.title,
      compliance_clause: convertResponseToComplianceClauseDetailsDTO(
        response.compliance_clause
      ),
      compliance_requirement_category:
        convertResponseToComplianceCategoryDetailsDTO(
          response.compliance_requirement_category,
          this.lang
        ),
      severity_level: convertResponseToSeverityLevelDetailsDTO(
        response.severity_level,
        this.lang
      ),
      responsible_user: response.responsible_user
        ? convertResponseToUserMinimalDTO(response.responsible_user, this.lang)
        : null,
      compliance_status: convertResponseToComplianceStatusDetailsDTO(
        response.compliance_status,
        this.lang
      ),
      deadline: response.deadline,
      created_at: response.created_at,
      updated_at: response.updated_at ? response.updated_at : null,
      created_by: convertResponseToUserMinimalDTO(
        response.created_by,
        this.lang
      ),
      updated_by: response.updated_by
        ? convertResponseToUserMinimalDTO(response.updated_by, this.lang)
        : null,
    };
  }

  formToComplianceRequirementFormDTO(
    formGroup: FormGroup
  ): ComplianceRequirementFormDTO {
    return {
      id: formGroup.get('id')?.value,
      compliance_clause_no: formGroup.get('ClauseNumber')?.value,
      compliance_clause_title: formGroup.get('ClauseTitle')?.value,
      title: formGroup.get('Title')?.value,
      compliance_requirement_category_id: formGroup.get('RequirementCategory')
        ?.value,
      severity_level_id: parseInt(formGroup.get('SeverityLevel')?.value, 10),
      responsible_user_id: formGroup.get('ResponsibleUser')?.value,
      deadline: formGroup.get('ComplianceDeadline')?.value,
      compliance_status_id: formGroup.get('ComplianceStatus')?.value,
      strategic_objective_ids: formGroup.get('StrategicObjectives')?.value,
      control_ids: formGroup.get('Controls')?.value,
    };
  }

  resToComplianceRequirementForm(response: any): any {
    return {
      id: response.id,
      ClauseNumber: response.compliance_clause.clause_no,
      ClauseTitle: response.compliance_clause.title,
      Title: response.title,
      RequirementCategory: response.compliance_requirement_category?.id,
      SeverityLevel: response.severity_level?.id,
      ResponsibleUser: response.responsible_user?.id,
      ComplianceDeadline: response.deadline,
      ComplianceStatus: response.compliance_status?.id,
      StrategicObjectives: response.strategic_objectives.map(
        (strategic_objective: any) => strategic_objective.strategic_objective_id
      ),
      Controls: response.controls.map((control: any) => control.control_id),
    };
  }
}
