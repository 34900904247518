<app-details-modal
  [title]="'Meeting Action Plan Details'"
  [showModal]="meetingActionPlanDetailsStore.showModal"
  (closeModalRequest)="meetingActionPlanDetailsStore.closeModal()">
  @if(meetingActionPlanDetailsStore.isInitialLoading ){
    <app-main-loader [height]="150" [width]="150"></app-main-loader>
  }@else if(meetingActionPlanDetailsStore.subItem){
    <app-details-box [title]="'Overview'">
      <div class="row">
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'Meeting'"
            [value]="meetingDetailsStore.details.title">
          </app-label-display>
        </div>
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'Meeting Agenda'"
            [value]="meetingActionPlanDetailsStore.subItem.meeting_minutes?.agenda?.title">
          </app-label-display>
        </div>
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'MOM'"
            [value]="meetingActionPlanDetailsStore.subItem.meeting_minutes?.minutes">
          </app-label-display>
        </div>
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'Action Plan'"
            [value]="meetingActionPlanDetailsStore.subItem.title">
          </app-label-display>
        </div>
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'Severity Level'"
            [value]="meetingActionPlanDetailsStore.subItem.severity_level?.title">
          </app-label-display>
        </div>
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'Responsible User'"
            [type]="'user'"
            [user]="meetingActionPlanDetailsStore.subItem.responsible_user">
          </app-label-display>
        </div>
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'Target Date'"
            [type]="'datetime'"
            [value]="meetingActionPlanDetailsStore.subItem.target_date">
          </app-label-display>
        </div>
        <div class="col-md-12 col-sm-12">
          <app-label-display
            [label]="'Description'"
            [value]="meetingActionPlanDetailsStore.subItem.description">
          </app-label-display>
        </div>
      </div>
    </app-details-box>


    <app-status-update
      [isInitialLoading]="meetingActionPlanDetailsStore.isInitialLoading "
      [latestDetails]="meetingActionPlanDetailsStore.statusDetailsLatest"
      [isShowStatusForm]="meetingActionPlanDetailsStore.isShowStatusForm"
      [isShowHistory]="meetingActionPlanDetailsStore.isShowHistory"
      [formGroup]="meetingActionPlanDetailsStore.formGroup"
      [formSections]="meetingActionPlanDetailsStore.formSections"
      [isFormDataLoading]="meetingActionPlanDetailsStore.isFetching"
      (toggleStatusFormRequest)="meetingActionPlanDetailsStore.toggleStatusForm()"
      (toggleStatusHistoryRequest)="meetingActionPlanDetailsStore.toggleHistory()"
      (closeStatusFormRequest)="meetingActionPlanDetailsStore.closeStatusForm()"
      (formSubmitRequest)="meetingActionPlanDetailsStore.updateStatus()"
      [isHistoryLoading]="meetingActionPlanDetailsStore.isLoading"
      [historyColumns]="meetingActionPlanDetailsStore.coloums"
      [history]="meetingActionPlanDetailsStore.history">
    </app-status-update>

  }
</app-details-modal>
