import { Routes } from '@angular/router';

export const userRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/user-list/user-list.component').then(
        (m) => m.UserListComponent
      ),
  },
  {
    path: ':id',
    loadComponent: () =>
      import(
        './components/user-details/user-details-page/user-details-page.component'
      ).then((m) => m.UserDetailsPageComponent),
    children: [
      {
        path: 'overview',
        loadComponent: () =>
          import(
            './components/user-details/overview/user-details-overview/user-details-overview.component'
          ).then((m) => m.UserDetailsOverviewComponent),
      },
      {
        path: 'tasks',
        loadComponent: () =>
          import(
            './components/user-details/tasks/user-details-tasks/user-details-tasks.component'
          ).then((m) => m.UserDetailsTasksComponent),
      },
      {
        path: 'notifications',
        loadComponent: () =>
          import(
            './components/user-details/user-details-notifications/user-details-notifications.component'
          ).then((m) => m.UserDetailsNotificationsComponent),
      },
      {
        path: 'settings',
        loadComponent: () =>
          import(
            './components/user-details/user-details-settings/user-details-settings.component'
          ).then((m) => m.UserDetailsSettingsComponent),
      },
      {
        path: '**',
        redirectTo: 'overview',
      },
    ],
  },
];
