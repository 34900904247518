<div id="tab3">
    <div class="row d-flex">
        <div class="sla-contarct-pdf-left">
            <div class="full-width-toggle">

            </div>
            <div class="row pdf-bg-grey">
                <div class="col-md-8 d-flex align-items-center">

                    <h4 class="font-lg ">CMD/Nov20/2024/mj.pdf</h4>

                </div>
                <div class="col-md-4 d-flex justify-content-end">
                    <button type="button" class="action-button me-2" id="action1"><img
                            src="assets/images/icons/icon-download.svg"></button>

                    <button type="button" class="action-button me-2" id="action1"><img
                            src="assets/images/icons/icon-print.svg"></button>
                    <button type="button" class="action-button" id="action1"><img
                            src="assets/images/icons/icon-more.svg"></button>
                </div>
                <div class="col-md-12 mt-4">
                    <div class="pdf-container"><iframe src="https://docs.google.com/viewer?url=http://www.pdf995.com/samples/pdf.pdf&embedded=true"> 
                    </iframe>  </div>
                                  </div>
            </div>
        </div>
        <div class="sla-contarct-pdf-right ">

            <div >

                <div class="widget-block widget-grey-bg">
                    <div class="row">
                        <div class="col-md-12">
                            <h4>Assessment Summary</h4>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-4 d-flex align-items-center">
                                    <img src="assets/images/chart/round-progress-chart.svg"
                                        class="mx-auto d-block img-fluid" />
                                </div>
                                <div class="col-md-8">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 mb-3">
                                            <label class="label-text d-flex align-items-start">

                                                <span class="status-dot-success mt-2"></span>
                                                <div>Complaint

                                                    <div class="p-text d-flex align-items-center">
                                                        <h3 class="body-number-lg">10 </h3>
                                                    </div>
                                                </div>
                                            </label>

                                        </div>
                                        <div class="col-md-6 col-sm-12 mb-3">
                                            <label class="label-text d-flex align-items-start">

                                                <span class="status-dot-warning mt-2"></span>
                                                <div>Partially Compliant

                                                    <div class="p-text d-flex align-items-center">
                                                        <h3 class="body-number-lg">17 </h3>
                                                    </div>
                                                </div>
                                            </label>

                                        </div>
                                        <div class="col-md-6 col-sm-12 mb-0">
                                            <label class="label-text d-flex align-items-start">

                                                <span class="status-dot-danger mt-2"></span>
                                                <div>Non-Complaint:

                                                    <div class="p-text d-flex align-items-center">
                                                        <h3 class="body-number-lg">03</h3>
                                                    </div>
                                                </div>
                                            </label>

                                        </div>
                                        <div class="col-md-6 col-sm-12 mb-0">
                                            <label class="label-text d-flex align-items-start">

                                                <span class="status-dot-default mt-2"></span>
                                                <div>Not Applicable

                                                    <div class="p-text d-flex align-items-center">
                                                        <h3 class="body-number-lg">03 </h3>
                                                    </div>
                                                </div>
                                            </label>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="justify-content-between align-items-center d-flex mt-3">
                    <h4 class="font-lg mb-0">Assessment</h4> <button class="btn btn-primary btn-theme" type="button" data-bs-toggle="modal"
                    data-bs-target="#add-status-popup">
                        <img src="assets/images/icons/icon-plus.svg" /> Add Question
                    </button>
                </div>


                <div class="widget-block widget-grey-bg  mt-3">
                    <div class="row">
                        <div class="col-md-12 d-flex justify-content-center align-items-center text-center"
                            style="min-height: 400px;">
                            <div> <img src="assets/images/icons/icon-assessment-xl.svg"
                                    class="img-fluid d-block mx-auto" />
                                <h5 class="font-lg">Start Your assessment now</h5>
                                <p>Add new questions and evaluate the<br> cloud hosting document</p>
                                <a class="a-link" href="#">+ Add Question</a>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="accordion theme-accordion mt-3" id="accordionExample11">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#control1" aria-expanded="true" aria-controls="collapseOne">
                                Service Commitment (3/6)
                            </button>
                        </h2>
                        <div id="control1" class="accordion-collapse collapse" aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div class="d-flex justify-content-between align-items-center mb-4 mt-4">
                                    <h5 class="font-lg mb-0">Question 1 </h5><button type="button" id="action1"
                                        class="action-button me-2"><img
                                            src="assets/images/icons/icon-more.svg"></button>
                                </div>
                                <p>How does the Service Provider ensure adherence to industry standards and data
                                    protection laws?</p>

                                <div class="head-back-line  mb-3 mt-4">
                                    <h5 class="font-lg ">Answer</h5>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-sm-12">
                                        <label class="label-text">Status </label>
                                        <p class="p-text">

                                            <span class="status-success">Compliant</span>
                                        </p>
                                    </div>
                                    <div class="col-md-12 col-sm-12">
                                        <label class="label-text">Comments </label>
                                        <p class="p-text">

                                            Understanding the definition of uptime is crucial for assessing
                                            compliance with the uptime guarantee.  </p>
                                    </div>
                                    <div class="col-md-12 col-sm-12">
                                        <label class="label-text">Evidents </label>
                                        <div class="d-flex file-box-sm">
                                            <img src="assets/images/icons/icon-pdf-list.svg" />
                                            <div class="doc-details ps-2">
                                                <p class="mb-0">Cloud agreement.pdf</p>
                                                <small>105KB</small>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-md-12 mt-3"> <a class="a-link cursor-pointer">Hide Answer</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#risk1" aria-expanded="true" aria-controls="collapseOne">
                                Compliance (4/6)
                            </button>
                        </h2>
                        <div id="risk1" class="accordion-collapse collapse" aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div class="d-flex justify-content-between align-items-center mb-3 mt-4">
                                    <h5 class="font-lg mb-0">Question 1 </h5><button type="button" id="action1"
                                        class="action-button me-2"><img
                                            src="assets/images/icons/icon-more.svg"></button>
                                </div>
                                <span class="status-success">Compliant</span>

                                <p>How does the Service Provider ensure adherence to industry standards and data
                                    protection laws?</p>
                                <a class="a-link cursor-pointer">View more</a>
                                <hr class="grey-hr mse--20" />


                                <div class="d-flex justify-content-between align-items-center mb-3 mt-4">
                                    <h5 class="font-lg mb-0">Question 2 </h5><button type="button" id="action1"
                                        class="action-button me-2"><img
                                            src="assets/images/icons/icon-more.svg"></button>
                                </div>
                                <p>How does the Service Provider ensure adherence to industry standards and data
                                    protection laws?</p>
                                <div class="head-back-line  mb-3 mt-2">
                                    <h5 class="font-lg ">Answer</h5>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-sm-12">
                                        <label class="label-text">Status </label>
                                        <p class="p-text">

                                            <span class="status-success">Compliant</span>
                                        </p>
                                    </div>
                                    <div class="col-md-12 col-sm-12">
                                        <label class="label-text">Comments </label>
                                        <p class="p-text">

                                            Understanding the definition of uptime is crucial for assessing
                                            compliance with the uptime guarantee.  </p>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <label class="label-text">Evidents </label>
                                        <div class="d-flex file-box-sm">
                                            <img src="assets/images/icons/icon-pdf-list.svg" />
                                            <div class="doc-details ps-2">
                                                <p class="mb-0">Cloud agreement.pdf</p>
                                                <small>105KB</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <label class="label-text">Evidents </label>
                                        <div class="d-flex file-box-sm">
                                            <img src="assets/images/icons/icon-pdf-list.svg" />
                                            <div class="doc-details ps-2">
                                                <p class="mb-0">Cloud agreement.pdf</p>
                                                <small>105KB</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12 mt-3">
                                        <label class="label-text">Action Plan </label>
                                        <div class="mt-3">
                                            <div class="table-responsive">
                                                <table class="table table-bordered theme-table mt-0 mb-0">
                                                    <thead>
                                                        <tr>

                                                            <th scope="col">
                                                                Title
                                                                <a class="table-filter"><img
                                                                        src="assets/images/icons/column-filter.svg" /></a>
                                                            </th>

                                                            <th scope="col" style="min-width: 120px;">
                                                                Priority
                                                                <a class="table-filter"><img
                                                                        src="assets/images/icons/column-filter.svg" /></a>
                                                            </th>
                                                            <th scope="col" style="min-width: 140px;">
                                                                Responsibility
                                                                <a class="table-filter"><img
                                                                        src="assets/images/icons/column-filter.svg" /></a>
                                                            </th>
                                                            <th scope="col" style="min-width: 120px">
                                                                Target date
                                                                <a class="table-filter"><img
                                                                        src="assets/images/icons/column-filter.svg" /></a>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Conduct
                                                                Vulnerability...</td>
                                                            <td><span class="status-danger">High</span></td>

                                                            <td>
                                                                <div class="user-widget d-flex">
                                                                    <img src="assets/images/user-images/user-1.png" />
                                                                    <span class="user-name ms-2">John Smith</span>
                                                                </div>
                                                            </td>
                                                            <td>08/09/2024</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12 mt-3">
                                        <label class="label-text">Potential Identified Risk </label>
                                        <div class="mt-3">
                                            <div class="table-responsive">
                                                <table class="table table-bordered theme-table mt-0 mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">
                                                                Title
                                                                <a class="table-filter"><img
                                                                        src="assets/images/icons/column-filter.svg" /></a>
                                                            </th>


                                                            <th scope="col" style="min-width: 140px;">
                                                                Category
                                                                <a class="table-filter"><img
                                                                        src="assets/images/icons/column-filter.svg" /></a>
                                                            </th>
                                                            <th scope="col" style="min-width: 120px">
                                                                Description
                                                                <a class="table-filter"><img
                                                                        src="assets/images/icons/column-filter.svg" /></a>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Inadequate Encryption standards</td>


                                                            <td>IT Security</td>

                                                            <td>
                                                                Current encryption protocols doesn’t meet industry
                                                                standards
                                                            </td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 mt-3"> <a class="a-link cursor-pointer">Hide Answer</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade theme-popup add-question-popup" id="add-status-popup" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Add Questions</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                <div class="col-sm-6">
                    <div class="form-group theme-select mb-3">
                      <label for="exampleFormControlSelect1"
                        >Checklist</label
                      >
                      <select class="form-control" id="exampleFormControlSelect1">
                        <option>SLA</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group theme-select mb-3">
                      <label for="exampleFormControlSelect1"
                        >Group</label
                      >
                      <select class="form-control" id="exampleFormControlSelect1">
                        <option>SLA</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </div>
                  </div>
                <div class="col-md-12">
                    <h5 class="font-lg mt-3" >All Question</h5>

                </div>

                <div class="col-md-12">
                    <div class="table-responsive">
                        <table class="table table-bordered theme-table mt-0 mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox"
                                                value="" id="flexCheckDefault">
                                            
                                        </div>
                                    </th>


                                    <th scope="col" style="min-width: 70px;">
                                        No
                                        <a class="table-filter"><img
                                                src="assets/images/icons/column-filter.svg" /></a>
                                    </th>
                                    <th scope="col" style="min-width: 200px">
                                        Group
                                        <a class="table-filter"><img
                                                src="assets/images/icons/column-filter.svg" /></a>
                                    </th>
                                    <th scope="col" style="min-width: 120px">
                                        Questions
                                        <a class="table-filter"><img
                                                src="assets/images/icons/column-filter.svg" /></a>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="align-top"><div class="form-check">
                                        <input class="form-check-input" type="checkbox"
                                            value="" id="flexCheckDefault">
                                        
                                    </div></td>


                                    <td class="align-top">1.1</td>

                                    <td class="align-top">
                                       Access Controls
                                    </td>
                                    <td class="align-top">
                                        <div class="form-check mb-3">
                                            <input class="form-check-input" type="checkbox"
                                                value="" id="flexCheckDefault">
                                                How does the Service Provider define "uptime" for the purposes of the 99.9% monthly uptime guarantee?
                                        </div>
                                        <div class="form-check mb-3" >
                                            <input class="form-check-input" type="checkbox"
                                                value="" id="flexCheckDefault">
                                                How does the Service Provider define "uptime" for the purposes of the 99.9% monthly uptime guarantee?
                                        </div>


                                    </td>

                                </tr>
                                <tr>
                                    <td class="align-top"><div class="form-check">
                                        <input class="form-check-input" type="checkbox"
                                            value="" id="flexCheckDefault">
                                        
                                    </div></td>


                                    <td class="align-top">2.1</td>

                                    <td class="align-top">
                                       Access Controls
                                    </td>
                                    <td class="align-top">
                                        <div class="form-check mb-3">
                                            <input class="form-check-input" type="checkbox"
                                                value="" id="flexCheckDefault">
                                                How does the Service Provider define "uptime" for the purposes of the 99.9% monthly uptime guarantee?
                                        </div>
                                        <div class="form-check mb-3" >
                                            <input class="form-check-input" type="checkbox"
                                                value="" id="flexCheckDefault">
                                                How does the Service Provider define "uptime" for the purposes of the 99.9% monthly uptime guarantee?
                                        </div>


                                    </td>

                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                
                
                
                </div>
              
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-theme-outline me-2" data-bs-dismiss="modal">
                    Cancel
                </button>
                <button type="button" class="btn btn-primary btn-theme">Add</button>
            </div>
        </div>
    </div>
</div>