import { Component, OnDestroy, OnInit } from '@angular/core';
import { MainLoaderComponent } from '../../../../../../shared/components/loaders/main-loader/main-loader.component';
import { BasicTableComponent } from '../../../../../../shared/components/tables/basic-table/basic-table.component';
import { ListPageHeaderComponent } from '../../../../../../shared/components/layout/list-page-header/list-page-header.component';
import { RouterModule } from '@angular/router';
import { DocumentWorkFlowStore } from '../../../store/document-workflow/document-workflow.store';
import { UiStore } from '../../../../../../core/store/ui.store';

@Component({
  selector: 'app-document-types',
  standalone: true,
  imports: [
    RouterModule,
    ListPageHeaderComponent,
    BasicTableComponent,
    MainLoaderComponent,
  ],
  templateUrl: './document-types.component.html',
  styleUrl: './document-types.component.scss'
})
export class DocumentTypesComponent implements OnInit, OnDestroy {
  constructor(
    public documentWorkFlowStore: DocumentWorkFlowStore,
    public uiStore: UiStore
  ) {}
  ngOnInit(): void {
    this.documentWorkFlowStore.initialize();
    this.documentWorkFlowStore.loadItems();
  }
  ngOnDestroy(): void {
    this.documentWorkFlowStore.resetEntireState();
  }

}
