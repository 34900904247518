import { Routes } from '@angular/router';

export const riskAppetiteStatementRoutes: Routes = [
    {
        path: '',
        loadComponent: () =>
        import('./components/risk-appetite-statement-list/risk-appetite-statement-list.component').then(
            (m) => m.RiskAppetiteStatementListComponent
        ),
    },
    

];
