import { Component, OnDestroy, OnInit } from '@angular/core';
import { AssociatedItemComponent } from '../../../../../../../shared/components/layout/details/associated-item/associated-item.component';
import { BasicTableComponent } from '../../../../../../../shared/components/tables/basic-table/basic-table.component';
import { ListPageHeaderComponent } from '../../../../../../../shared/components/layout/list-page-header/list-page-header.component';
import { AccordionItemComponent } from '../../../../../../../shared/components/accordions/accordion-item/accordion-item.component';
import { ComplianceDetailsAssociatedItemStore } from '../../../../store/compliance-details-associated-items.store';
import { MainLoaderComponent } from '../../../../../../../shared/components/loaders/main-loader/main-loader.component';

@Component({
  selector: 'app-compliance-details-associated-items',
  standalone: true,
  imports: [
    AccordionItemComponent,
    ListPageHeaderComponent,
    BasicTableComponent,
    AssociatedItemComponent,
    MainLoaderComponent,
  ],
  templateUrl: './compliance-details-associated-items.component.html',
  styleUrl: './compliance-details-associated-items.component.scss',
})
export class ComplianceDetailsAssociatedItemsComponent
  implements OnInit, OnDestroy
{
  constructor(
    public complianceDetailsAssociatedItemStore: ComplianceDetailsAssociatedItemStore
  ) {}

  ngOnInit(): void {
    this.complianceDetailsAssociatedItemStore.setMainItemId();
    this.complianceDetailsAssociatedItemStore.setService();

    this.complianceDetailsAssociatedItemStore.initializeData(true, true, true);
  }

  ngOnDestroy(): void {
    this.complianceDetailsAssociatedItemStore.resetEntireState();
  }
}
