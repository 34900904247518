import { Injectable } from '@angular/core';
import { SLAContractActionPlanListDTO } from '../dto/sla-contract-action-plan.dto';
import { FilterCategory, PaginationConfig, QuickFilterItem, TableActionConfig, TableColumnConfigurable, defaultActionConfig, defaultPaginationConfig, generatePages } from '../../../../core/modals/table.modal';
import { SLAContractActionPlanService } from '../services/sla-contract-action-plan.service';
import { SLAContractActionPlanListQuickFilters, SLAContractActionPlanListTablecolumns } from '../config/sla-contract-action-plan-list.config';
import { SLAContractDetailsStore } from './sla-contract-details.store';
import { makeObservable, observable, runInAction } from 'mobx';
import { ListBaseStore } from '../../../../core/store/list-base.store';
import { SLAContractActionPlanConversion } from '../conversion/sla-contract-action-plan.conversion';

@Injectable({ providedIn: 'root' })
export class SLAContractActionPlanStore extends ListBaseStore<SLAContractActionPlanListDTO>{
    
    override sortField: string = '';
    override columns: TableColumnConfigurable[];
    override quickFilters: QuickFilterItem[];
    override filterCategories: FilterCategory[] = [];
    actionConfig: TableActionConfig;


    constructor(
        private slaContractActionPlanService: SLAContractActionPlanService,
        private slaContractDetailsStore: SLAContractDetailsStore,
        private slaContractActionPlanConversion: SLAContractActionPlanConversion,
    ) {
        super();
        makeObservable(this, {
            filterCategories: observable,
        });        
    }

    override initialize() {
        this.sortField = 'title';
        this.sortOrder = 'asc';
        this.actionConfig = defaultActionConfig;
        this.paginationConfig = defaultPaginationConfig;
        this.quickFilters = SLAContractActionPlanListQuickFilters;
        this.columns = SLAContractActionPlanListTablecolumns;
        this.mainId = this.slaContractDetailsStore.details.id;
        this.mainConv = this.slaContractActionPlanConversion;
    }

    get service() {
        return this.slaContractActionPlanService;
    }
    override getMasterLists(): void {}

    

    resetEntireState(): void {
        this.sortField = '';
        this.sortOrder = '';
        this.searchQuery = '';
        this.isLoading = false;
        this.isInitialLoading = true;
        this.isEmptyList = true;
        this.mainId = undefined;
        this.mainConv = undefined;
        this.actionConfig = {} as TableActionConfig;
        this.paginationConfig = {} as PaginationConfig;
        this.filterCategories = [];
        this.items = [];

    }

}