import { FormGroup } from "@angular/forms";
import { UserMinimalDTO, convertResponseToUserMinimalDTO } from "../../../organization/users/dto/user.dto";
import { SeverityLevelDetailsDTO, convertResponseToSeverityLevelDetailsDTO } from "../../../settings/masters/dto/severity-level.dto";
import { ActionPlanStatusDetailsDTO, convertResponseToActionPlanStatuDetailsDTO } from "../../../settings/masters/dto/action-plan-status.dto";

export interface SlaContractActionPlanDTO {
    id: number;
    title: string;
    description: string;
    severityLevelTitleEn: string;
    severityLevelTitleAr: string;
    severityLevelColorCode: string;
    targetDate: string;
    responsibleUserFirstNameEn: string;
    responsibleUserFirstNameAr: string;
    responsibleUserLastNameEn: string;
    responsibleUserLastNameAr: string;
    actionPlanTitleEn: string;
    actionPlanTitleAr: string;
    actionPlanColorCode: string;
}

export interface SlaContractActionPlanFormDTO {
    id?: number;
    sla_check_question_id?:number
    title: string;
    description: string;
    severity_level_id: number;
    responsible_user_id: number;
    target_date: string;
    action_plan_status_id: number;
    percentage?: number;
}


export interface SlaContractActionPlanDetailsDTO {
    id: number;
    title: string;
    description: string;
    severity_level: SeverityLevelDetailsDTO | null;
    responsible_user: UserMinimalDTO | null;
    action_plan_status: ActionPlanStatusDetailsDTO | null;
    percentage: number;
    target_date: string;
    created_by: UserMinimalDTO;
    created_at: string;
    updated_at: string | null;
    updated_by: UserMinimalDTO | null;
}

export function convertFormToSlaContractActionPlanFormDTO(
    formGroup: FormGroup
    ): SlaContractActionPlanFormDTO {
    return {
        id: formGroup.get('id')?.value,
        title: formGroup.get('Title')?.value,
        description: formGroup.get('Description')?.value,
        severity_level_id: parseInt(formGroup.get('SeverityLevel')?.value, 10),
        responsible_user_id: formGroup.get('ResponsibleUser')?.value,
        target_date: formGroup.get('TargetDate')?.value,
        action_plan_status_id: formGroup.get('ActionPlanStatus')?.value,
        percentage: formGroup.get('Percentage')?.value,
    };
}

export function convertResponseToForm(response: any): any {
    return {
        id: response.id,
        Title: response.title,
        Description: response.description,
        SlaContractRequirement: response.compliance_requirement?.id,
        SeverityLevel: response.severity_level?.id,
        ResponsibleUser: response.responsible_user?.id,
        RequirementCategory: response.compliance_requirement_category?.id,
        TargetDate: response.target_date,
        ActionPlanStatus: response.action_plan_status?.id,
        Percentage: response.percentage,
    };
}

export function convertResponseToSlaContractActionPlanDetailsDTO(
    response: any,
    lang: string
  ): SlaContractActionPlanDetailsDTO {
    return {
        id: response.id,
        title: response.title,
        description: response.description,

        severity_level: convertResponseToSeverityLevelDetailsDTO(
            response.severity_level,
            lang
        ),
        responsible_user: response.responsible_user
            ? convertResponseToUserMinimalDTO(response.responsible_user, lang)
        : null,

        action_plan_status: convertResponseToActionPlanStatuDetailsDTO(
            response.action_plan_status,
            lang
        ),
        target_date: response.target_date,
        percentage: response.percentage,
        created_at: response.created_at,
        updated_at: response.updated_at ? response.updated_at : null,
        created_by: convertResponseToUserMinimalDTO(response.created_by, lang),
        updated_by: response.updated_by
            ? convertResponseToUserMinimalDTO(response.updated_by, lang)
            : null,
    };
}
