import { Routes } from '@angular/router';

export const auditsRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/audit-list/audit-list.component').then(
        (m) => m.AuditListComponent
      ),
  },
  {
    path: ':id',
    loadComponent: () =>
      import(
        './components/audit-details/audit-details-page/audit-details-page.component'
      ).then((m) => m.AuditDetailsPageComponent),
    children: [
      {
        path: 'overview',
        loadComponent: () =>
          import(
            './components/audit-details/overview/audit-details-overview.component'
          ).then((m) => m.AuditDetailsOverviewComponent),
      },
      {
        path: 'documents',
        loadComponent: () =>
          import(
            './components/audit-details/documents/audit-details-documents.component'
          ).then((m) => m.AuditDetailsDocumentsComponent),
      },
      {
        path: 'meetings',
        loadComponent: () =>
          import(
            './components/audit-details/meetings/audit-details-meetings.component'
          ).then((m) => m.AuditDetailsMeetingsComponent),
      },
      {
        path: 'audit-schedules',
        loadComponent: () =>
          import(
            './components/audit-details/audit-schedules/audit-details-audit-schedules.component'
          ).then((m) => m.AuditDetailsAuditSchedulesComponent),
      },
      {
        path: 'checklist',
        loadComponent: () =>
          import(
            './components/audit-details/checklist/audit-details-checklist.component'
          ).then((m) => m.AuditDetailsChecklistComponent),
      },
      {
        path: 'draft-report',
        loadComponent: () =>
          import(
            './components/audit-details/draft-report/audit-details-draft-report.component'
          ).then((m) => m.AuditDetailsDraftReportComponent),
      },
      {
        path: 'final-report',
        loadComponent: () =>
          import(
            './components/audit-details/final-report/audit-details-final-report.component'
          ).then((m) => m.AuditDetailsFinalReportComponent),
      },
      {
        path: 'audit-findings',
        loadComponent: () =>
          import(
            './components/audit-details/audit-findings/audit-details-audit-findings.component'
          ).then((m) => m.AuditDetailsAuditFindingsComponent),
      },
    ],
  },
];
