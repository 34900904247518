import { Component, OnDestroy, OnInit } from '@angular/core';
import { DocumentWorkflowLevelDetailsStore } from '../../../store/document-workflow/document-workflow-level.store';
import { SideFormComponent } from '../../../../../../shared/components/forms/side-form/side-form.component';
import { DetailsModalComponent } from '../../../../../../shared/components/modals/details-modal/details-modal.component';

@Component({
  selector: 'app-workflow-level-form',
  standalone: true,
  imports: [
    SideFormComponent,
    DetailsModalComponent,
  ],
  templateUrl: './workflow-level-form.component.html',
  styleUrl: './workflow-level-form.component.scss'
})
export class WorkflowLevelFormComponent implements OnInit, OnDestroy {
  constructor(
    public documentWorkflowLevelDetailsStore: DocumentWorkflowLevelDetailsStore,

  ) {
  }

  ngOnInit(): void {
    this.documentWorkflowLevelDetailsStore.initialize();
  }


  ngOnDestroy(): void {
    this.documentWorkflowLevelDetailsStore.resetEntireState()
  }
}
