<div class="access-control-page">
    @if(documentWorkFlowStore.isInitialLoading){
      <app-main-loader></app-main-loader>
    }@else {
    <app-list-page-header
      [title]="'Document Types - Workflow'"
      [isSearchEnabled]="true"
      (searchRequest)="documentWorkFlowStore.setSearchQuery($event)">
    </app-list-page-header>
    <div class="col-md-12">
      <app-basic-table
        [data]="documentWorkFlowStore.items"
        [columns]="documentWorkFlowStore.columns"
        [sortField]="documentWorkFlowStore.sortField"
        [sortOrder]="documentWorkFlowStore.sortOrder"
        [isLoading]="documentWorkFlowStore.isLoading"
        [isPaginationEnabled]="false"
        [rowUrl]="'/settings/document/review-flow-settings/:id/workflow-levels'"
        [paginationConfig]="documentWorkFlowStore.paginationConfig"
        (sortRequest)="documentWorkFlowStore.setSort($event)">
      </app-basic-table>
    </div>
    }
  </div>
  