import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { routes } from './app/app.routes';
import { provideRouter } from '@angular/router';
import { HttpLoaderFactory } from './app/core/i18n/translate-loader.factory';
import { authInterceptor } from './app/core/interceptors/auth.interceptor';
import { errorHandlerInterceptor } from './app/core/interceptors/error.interceptor';
import { provideToastr } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppSettingsStore } from './app/core/store/app-settings.store';
import { CurrentUserPreferenceStore } from './app/core/store/current-user-preference.store';
import { AppInitializeStores } from './app/core/store/app-initializer.store';

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes),
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializeStores,
      deps: [AppSettingsStore, CurrentUserPreferenceStore],
      multi: true,
    },
    provideAnimations(),
    provideToastr({
      timeOut: 6000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      closeButton: true,
    }),
    provideHttpClient(
      withInterceptors([authInterceptor, errorHandlerInterceptor])
    ),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      })
    ),
  ],
});
