import { Injectable } from '@angular/core';
import {
  
  ComplianceStatusUpdateDetailsDTO,
} from '../dto/compliance-status.dto';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IStatusService } from '../../../../core/interfaces/IStatusService.interface';
import { environment } from '../../../../../environments/environment';
import { ComplianceActionPlanStatusUpdateDTO, ComplianceActionPlanStatusUpdateFormDTO } from '../dto/compliance-action-plan-status-update.dto';

@Injectable({
  providedIn: 'root',
})
export class ComplianceActionPlanStatusUpdateService implements IStatusService {
  apiUrl = environment.apiUrl + '/compliances';

  constructor(protected http: HttpClient) {}

  getLatestStatus(complianceId: number, actionPlanId: number): Observable<any> {
    const url = `${this.apiUrl}/${complianceId}/action-plans/${actionPlanId}/status-updates/latest`;
    return this.http.get<any>(url);
  }

  listStatusUpdatesHistory(
    complianceId: number,
    actionPlanId: number
  ): Observable<ComplianceActionPlanStatusUpdateDTO[]> {
    let params = new HttpParams();
    const url = `${this.apiUrl}/${complianceId}/action-plans/${actionPlanId}/status-updates`;
    return this.http.get<ComplianceActionPlanStatusUpdateDTO[]>(
        url,
      { params }
    );
  }

  createStatusUpdate(
    complianceId: number,
    item: ComplianceActionPlanStatusUpdateFormDTO,
    actionPlanId: number
  ): Observable<ComplianceActionPlanStatusUpdateFormDTO> {

    const url = `${this.apiUrl}/${complianceId}/action-plans/${actionPlanId}/status-updates`;
    return this.http.post<ComplianceActionPlanStatusUpdateFormDTO>(
      url,
      item
    );
  }

  getStatusUpdateByHistoryId(
    complianceId: number,
    updateId: number
  ): Observable<ComplianceStatusUpdateDetailsDTO> {
    throw new Error('Method not required.');
  }

  updateStatusUpdateByHistoryId(
    complianceId: number,
    historyId: number,
    item: ComplianceActionPlanStatusUpdateFormDTO,
    actionPlanId: number
  ): Observable<ComplianceActionPlanStatusUpdateFormDTO> {

    const url = `${this.apiUrl}/${complianceId}/action-plans/${actionPlanId}/status-updates/${historyId}`;
    return this.http.put<ComplianceActionPlanStatusUpdateFormDTO>(
        url,
        item
    );
  }
}
