import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUserSessionStore } from '../store/current-user-session.store';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(
    private currentUserSessionStore: CurrentUserSessionStore,
    private router: Router
  ) {}

  canActivate(): boolean {
    if (this.currentUserSessionStore.isLoggedIn()) {
      return true;
    } else {
      this.router.navigate(['/auth/login']);
      return false;
    }
  }
}
