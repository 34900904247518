import { Component, OnDestroy, OnInit } from '@angular/core';
import { ComplianceDetailsStore } from '../../../../store/compliance-details.store';

@Component({
  selector: 'app-compliance-details-pdf-preview',
  standalone: true,
  imports: [],
  templateUrl: './compliance-details-pdf-preview.component.html',
  styleUrl: './compliance-details-pdf-preview.component.scss',
})
export class ComplianceDetailsPdfPreviewComponent implements OnInit, OnDestroy {

  width: string = '100%';
  height: string = '100%';

  constructor(
    public complianceDetailsStore: ComplianceDetailsStore,
  ) {}

  ngOnInit(): void {
      this.complianceDetailsStore.loadFilePreview();
  }

  ngOnDestroy(): void {}
}
