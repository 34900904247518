import { Injectable } from '@angular/core';
import { IStatusService } from '../../../../core/interfaces/IStatusService.interface';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { ComplianceStatusUpdateFormDTO } from '../dto/compliance-status-update.dto';

@Injectable({
  providedIn: 'root',
})
export class ComplianceRequirementStatusService implements IStatusService {
  apiUrl = environment.apiUrl + '/compliances';

  constructor(protected http: HttpClient) {}

  getLatestStatus(
    complianceId: number,
    requirementId: number
  ): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/${complianceId}/requirements/${requirementId}/status-updates/latest`
    );
  }

  listStatusUpdatesHistory(
    complianceId: number,
    requirementId: number
  ): Observable<any[]> {
    let params = new HttpParams();

    return this.http.get<any[]>(
      `${this.apiUrl}/${complianceId}/requirements/${requirementId}/status-updates`,
      { params }
    );
  }

  getStatusUpdateByHistoryId(
    mainId: number,
    historyID: number
  ): Observable<any> {
    throw new Error('Method not implemented.');
  }

  createStatusUpdate(
    complianceId: number,
    item: any,
    requirementId: number
  ): Observable<any> {
    return this.http.post<ComplianceStatusUpdateFormDTO>(
      `${this.apiUrl}/${complianceId}/requirements/${requirementId}/status-updates`,
      item
    );
  }

  updateStatusUpdateByHistoryId(
    complianceId: number,
    historyId: number,
    item: any,
    requirementId: number
  ): Observable<ComplianceStatusUpdateFormDTO> {
    return this.http.put<ComplianceStatusUpdateFormDTO>(
      `${this.apiUrl}/${complianceId}/requirements/${requirementId}/status-updates/${historyId}`,
      item
    );
  }
}
