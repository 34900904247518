import { Injectable } from '@angular/core';
import { DocumentWorkflowService } from '../../services/document-workflow.service';
import { action, makeObservable, observable, runInAction } from 'mobx';
import {
  convertResponseToDocumentWorkflowLevelDTO,
  DocumentWorkflowDetailsDTO,
  DocumentWorkflowLevelDTO,
  DocumentWorkflowLevelFormDTO,
} from '../../dto/document-workflow-level.dto';
import { WorkFlowLevelTypeService } from '../../../general-settings/services/workflow-level-types.service';
import { catchError, forkJoin, tap } from 'rxjs';
import { WorkFlowLevelTypeDTO } from '../../../general-settings/dto/workflow-level-types.dto';
import { DocumentWorkflowLevelConversion } from '../../conversion/document-workflow-level.conversion';
import { UiStore } from '../../../../../core/store/ui.store';
import { FormSection } from '../../../../../shared/models/form.config';
import { TableQuickSortItem } from '../../../../../core/modals/table.modal';
import { RoleService } from '../../../general-settings/services/role.service';
import { RoleDTO } from '../../../general-settings/dto/role.dto';
import { UserService } from '../../../../organization/users/services/user.service';
import { UserListDTO } from '../../../../organization/users/dto/user.dto';
import { AlertService } from '../../../../../core/services/alert.service';
import { CurrentUserPreferenceStore } from '../../../../../core/store/current-user-preference.store';
import { DocumentWorkflowLevelFormConfig } from '../../config/document-workflow-level-from.config';
import { FormBaseStore } from '../../../../../core/store/form-base.store';
import { BreadcrumbItem } from '../../../../../core/modals/details.modal';

@Injectable({ providedIn: 'root' })
export class DocumentWorkflowLevelDetailsStore extends FormBaseStore<DocumentWorkflowLevelFormDTO> {
  entityName: string = '';
  fields: FormSection[] = [];
  quickSortItems: TableQuickSortItem[] = [];
  itemId: number | null = null;

  isInitialLoading: boolean = true;
  workFlowDetails = {} as DocumentWorkflowDetailsDTO;
  workflowLevels: DocumentWorkflowLevelDTO[];
  isEmptyList: boolean = true;

  workFlowLevelTypes: WorkFlowLevelTypeDTO[] = [];
  roles: RoleDTO[] = [];
  users: UserListDTO[] = [];

  breadcrumbs: BreadcrumbItem[] = [
    {
      label: 'Document Types',
      url: '/settings/document/review-flow-settings',
    },
    { label: 'Review & Approval Workflow Configuration', url: '' },
  ];

  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    protected service: DocumentWorkflowService,
    private workFlowLevelTypeService: WorkFlowLevelTypeService,
    private roleService: RoleService,
    private documentWorkflowLevelConv: DocumentWorkflowLevelConversion,
    protected uiStore: UiStore,
    private userService: UserService,
    protected alertService: AlertService,
    private documentWorkflowLevelFormConfig: DocumentWorkflowLevelFormConfig,
    private userPreference: CurrentUserPreferenceStore
  ) {
    super();

    this.lang = this.userPreference.languageKey;
    makeObservable(this, {
      workFlowLevelTypes: observable,
      roles: observable,
      users: observable,
      workFlowDetails: observable,
      isEmptyList: observable,
      updateFormOptions: action,
      resetEntireState: action,
      fetchDetails: action,
      addLevelInBeteween: action,
      resetForm: action,
    });
  }

  override initialize(): void {
    this.entityName = 'Level';
    this.fields = this.documentWorkflowLevelFormConfig.formSections;
    this.quickSortItems = [
      { label: 'Newest to Oldest', key: '', active: false },
      { label: 'Active to Inactive', key: '', active: false },
      { label: 'Alphabetical Order (A to Z)', key: '', active: false },
    ];
  }

  updateFormOptions() {
    this.updateOptionsWhileModalOpen().subscribe({
      next: () => {
        this.documentWorkflowLevelFormConfig.updateOptions(
          this.fields,
          this.workFlowDetails.workflow_levels,
          this.workFlowLevelTypes,
          this.users,
          this.roles
        );
      },
      error: (error) => {
        console.error('Error updating options', error);
      },
    });
  }

  updateOptionsWhileModalOpen() {
    return forkJoin({
      workFlowLevelTypes: this.workFlowLevelTypeService.list(),
      roles: this.roleService.list(),
      users: this.userService.list('first_name_'+this.currentUserPreferenceStore.languageKey, 'asc', 1, 500),
    }).pipe(
      tap(({ workFlowLevelTypes, roles, users }) => {
        runInAction(() => {
          this.workFlowLevelTypes = workFlowLevelTypes;
          this.roles = roles;
          this.users = users.items;
        });
      }),
      catchError((error) => {
        console.error('Error fetching master lists', error);
        throw error;
      })
    );
  }

  override loadItems(): void {
    this.fetchDetails(this.workFlowDetails.id);
  }

  fetchDetails(id: number): void {
    this.isItemFetching = true;
    this.service.getWorkflowLevels(id).subscribe({
      next: (response: any) => {
        runInAction(() => {
          this.workFlowDetails = response;
          this.mainId = id;
          this.workflowLevels = convertResponseToDocumentWorkflowLevelDTO(
            this.workFlowDetails.workflow_levels,
            this.lang
          );
          if (this.workflowLevels.length > 0) {
            this.isEmptyList = false;
          }
          this.isItemFetching = false;
          this.isInitialLoading = false;
        });
      },
      error: (error: any) => {
        console.error('Failed to fetch details:', error);
        runInAction(() => {
          this.isItemFetching = false;
          this.isInitialLoading = false;
        });
      },
    });
  }

  convertFormToDTO(): DocumentWorkflowLevelFormDTO {
    return this.documentWorkflowLevelConv.formGroupToForm(
      this.formGroup,
      this.workFlowLevelTypes,
      this.workflowLevels
    );
  }

  convertResponseToForm(response: any): any {
    return this.documentWorkflowLevelConv.resToForm(response);
  }

  addLevelInBeteween(currentLevel: any): void {
    this.openSideForm();
    this.formGroup.patchValue({
      ParentLevel: currentLevel.level,
    });
  }

  resetEntireState(): void {
    runInAction(() => {
      this.entityName = 'Compliance Register';
      this.itemId = null;
      this.mainId = null;
      this.isItemFetching = false;
      this.isSideFormOpen = false;
      this.fields = [];
      this.quickSortItems = [];
      this.workFlowLevelTypes = [];
      this.roles = [];
      this.users = [];
      this.workFlowDetails = {} as DocumentWorkflowDetailsDTO;
      this.workflowLevels = [];
      this.resetForm();
    });
  }
}
