import { Routes } from "@angular/router";
import { IncidentRegisterComponent } from "./incident-register/components/incident-register/incident-register.component";
import { IncidentDetailsPageComponent } from "./incident-register/components/incident-details/incident-details-page/incident-details-page.component";
import { ActionPlanListComponent } from "./action-plans/components/action-plan-list/action-plan-list.component";
import { incidentRegisterRoutes } from "./incident-register/incident-register-routes";

export const incidentRoutes: Routes = [
  {
    path: 'register',
    children: [...incidentRegisterRoutes],
  },
  {
    path: 'action-plan',
    component: ActionPlanListComponent
  },
  {
    path: '',
    redirectTo: 'register',
    pathMatch: 'full',
  },
]