<div class="col-md-12">
  <app-breadcrumbs [breadcrumbs]="roleDetailsStore.breadcrumbs"></app-breadcrumbs>
</div>

<div class="row">
  <div class="col-md-12">
    <app-accordion-item
      [title]="'Module Permissions'"
      [itemId]="'modulePermissionID'"
      [open]="true">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table table-bordered theme-table mb-0 mt-0">
            <thead>
              <tr>
                <th scope="col" style="min-width: 300px">
                  Module
                  <a class="table-filter"
                    ><img src="assets/images/icons/column-filter.svg"
                  /></a>
                </th>
                <th scope="col">
                  Sub Module
                  <a class="table-filter"
                    ><img src="assets/images/icons/column-filter.svg"
                  /></a>
                </th>
                @for (rolePermissionType of roleDetailsStore.rolePermissionTypes; track rolePermissionType)
                {
                <th style="width: 100px">
                  {{ rolePermissionType.title }}
                </th>
                }
              </tr>
            </thead>
            <tbody>
              @for (rolePermission of roleDetailsStore.rolePermissions; track rolePermission.id) {
                @for (subModule of rolePermission.sub_modules; track subModule.id; let idx = $index) {
              <tr>
                @if(idx == 0){
                  <td class="align-top" [attr.rowspan]="rolePermission.rowspan">
                    {{ rolePermission.title }}
                  </td>
                }
               
                <td>
                  {{
                    rolePermission.sub_modules.length > 0
                      ? subModule.title
                      : ""
                  }}
                </td>
                @for (rolePermissionType of subModule.permissions; track rolePermissionType) {
                <td>
                  <div class="toggle-btn">
                    <input
                      type="checkbox"
                      [(ngModel)]="rolePermissionType.is_selected"
                      [checked]="rolePermissionType.is_selected"
                      (change)="
                        onPermissionChange(
                          rolePermissionType,
                          subModule.permissions
                        )
                      "
                    />
                  </div>
                </td>
                }
              </tr>
              } 
            }
            </tbody>

          </table>
        </div>
      </div>
      <div class="col-md-12">
        <div class="d-flex justify-content-end mt-4">
          <app-button
            [button]="'secondary'"
            [label]="'Cancel'"
            (clickRequest)="onCancel()">
          </app-button>

          <app-button
            [type]="'edit'"
            [label]="'Update'"
            [isLoading]="roleDetailsStore.isLoading"
            [loadingLabel]="roleDetailsStore.loadingLabel"
            (clickRequest)="roleDetailsStore.savePermission()">
          </app-button>
        </div>
      </div>
    </app-accordion-item>

    <!-- <app-accordion-item [title]="'Department Level Access'" [itemId]="'departmentLevelAccessID'" [open]="true">
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table table-bordered theme-table mb-0 mt-0">
                        <thead>
                            <tr>

                                <th scope="col" style="min-width: 300px">
                                  Module
                                    <a class="table-filter"><img src="assets/images/icons/column-filter.svg" /></a>
                                </th>
                                <th scope="col">
                                  Sub Module
                                    <a class="table-filter"><img src="assets/images/icons/column-filter.svg" /></a>
                                </th>

                                <th style="width: 100px">
                                  Manage
                                    <a class="table-filter"><img src="assets/images/icons/column-filter.svg" /></a>
                                </th>
                                <th style="width: 100px">
                                  Modify
                                    <a class="table-filter"><img src="assets/images/icons/column-filter.svg" /></a>
                                </th>
                                <th style="width: 100px">
                                  View
                                    <a class="table-filter"><img src="assets/images/icons/column-filter.svg" /></a>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                                <td class="align-top" rowspan="4">Compliance Management</td>
                                <td>Complainants Register</td>
                                <td>
                                  <div class="toggle-btn"><input  type="checkbox"></div>
                                </td>
                                <td>
                                  <div class="toggle-btn"><input  type="checkbox"></div>
                                </td>
                                <td>
                                  <div class="toggle-btn"><input  type="checkbox"></div>
                                </td>
                            </tr>
                            <tr>

                              <td>SLA & Contract</td>
                              <td>
                                <div class="toggle-btn"><input  type="checkbox"></div>
                              </td>
                              <td>
                                <div class="toggle-btn"><input  type="checkbox"></div>
                              </td>
                              <td>
                                <div class="toggle-btn"><input  type="checkbox"></div>
                              </td>
                          </tr>
                          <tr>

                            <td>Process</td>
                            <td>
                              <div class="toggle-btn"><input  type="checkbox"></div>
                            </td>
                            <td>
                              <div class="toggle-btn"><input  type="checkbox"></div>
                            </td>
                            <td>
                              <div class="toggle-btn"><input  type="checkbox"></div>
                            </td>
                        </tr>
                        <tr>

                          <td>Control</td>
                          <td>
                            <div class="toggle-btn"><input  type="checkbox"></div>
                          </td>
                          <td>
                            <div class="toggle-btn"><input  type="checkbox"></div>
                          </td>
                          <td>
                            <div class="toggle-btn"><input  type="checkbox"></div>
                          </td>
                      </tr>
                      <tr>

                        <td class="align-top" rowspan="7">Risk Management</td>
                        <td>Risk Matrix</td>
                        <td>
                          <div class="toggle-btn"><input  type="checkbox"></div>
                        </td>
                        <td>
                          <div class="toggle-btn"><input  type="checkbox"></div>
                        </td>
                        <td>
                          <div class="toggle-btn"><input  type="checkbox"></div>
                        </td>
                    </tr>
                    <tr>

                      <td>Risk Appetites Stataement</td>
                      <td>
                        <div class="toggle-btn"><input  type="checkbox"></div>
                      </td>
                      <td>
                        <div class="toggle-btn"><input  type="checkbox"></div>
                      </td>
                      <td>
                        <div class="toggle-btn"><input  type="checkbox"></div>
                      </td>
                  </tr>
                  <tr>

                    <td>KRI’S</td>
                    <td>
                      <div class="toggle-btn"><input  type="checkbox"></div>
                    </td>
                    <td>
                      <div class="toggle-btn"><input  type="checkbox"></div>
                    </td>
                    <td>
                      <div class="toggle-btn"><input  type="checkbox"></div>
                    </td>
                </tr>
                <tr>

                  <td>Risk Register</td>
                  <td>
                    <div class="toggle-btn"><input  type="checkbox"></div>
                  </td>
                  <td>
                    <div class="toggle-btn"><input  type="checkbox"></div>
                  </td>
                  <td>
                    <div class="toggle-btn"><input  type="checkbox"></div>
                  </td>
              </tr>
              <tr>

                <td>Action Plan</td>
                <td>
                  <div class="toggle-btn"><input  type="checkbox"></div>
                </td>
                <td>
                  <div class="toggle-btn"><input  type="checkbox"></div>
                </td>
                <td>
                  <div class="toggle-btn"><input  type="checkbox"></div>
                </td>
            </tr>
            <tr>

              <td>Risk Heatmap</td>
              <td>
                <div class="toggle-btn"><input  type="checkbox"></div>
              </td>
              <td>
                <div class="toggle-btn"><input  type="checkbox"></div>
              </td>
              <td>
                <div class="toggle-btn"><input  type="checkbox"></div>
              </td>
          </tr>

          <tr>

            <td>Reports</td>
            <td>
              <div class="toggle-btn"><input  type="checkbox"></div>
            </td>
            <td>
              <div class="toggle-btn"><input  type="checkbox"></div>
            </td>
            <td>
              <div class="toggle-btn"><input  type="checkbox" checked></div>
            </td>
        </tr>



                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-12">
              <div class="d-flex justify-content-end mt-4">
                <button type="button" class="btn btn-secondary btn-theme-outline me-2" data-bs-dismiss="modal">
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary btn-theme">
                  Update
                </button>
              </div>
            </div>
        </app-accordion-item> -->
  </div>
</div>
