<div id="tab4">
    <div class="row mt-4">
        <app-list-page-header

            [isAddNewEnabled]="true"
            [addButtonLabel]="slaContractActionPlanFormStore.addButtonLabel"
            (addRequest)="slaContractActionPlanFormStore.openSideForm()"
            (searchRequest)="slaContractActionPlanStore.setSearchQuery($event)">
        </app-list-page-header>
    </div>

    <div class="row">
        <div class="col-md-12">
            <app-basic-table
                [data]="slaContractActionPlanStore.items"
                [columns]="slaContractActionPlanStore.getEnabledColumns()"
                [actionConfig]="slaContractActionPlanStore.actionConfig"
                [isPaginationEnabled]="true"
                [paginationConfig]="slaContractActionPlanStore.paginationConfig"
                [sortField]="slaContractActionPlanStore.sortField"
                [sortOrder]="slaContractActionPlanStore.sortOrder"
                [rowUrl]="'/compliance/sla-contracts/:id/action-plans'"
                (pageChangeRequest)="slaContractActionPlanStore.loadItems()"
                (sortRequest)="slaContractActionPlanStore.setSort($event)"
                (editRequest)="slaContractActionPlanFormStore.openSideForm($event)">
            </app-basic-table>
        </div>
    </div>


    <app-side-form-modal [isSideFormOpen]="slaContractActionPlanFormStore.isSideFormOpen">
        <app-side-form
            [title]="slaContractActionPlanFormStore.title"
            [submitButtonTitle]="slaContractActionPlanFormStore.submitButtonTitle"
            [submitButtonLoadingText]="slaContractActionPlanFormStore.submitButtonLoadingText"
            [submitButtonAndNewTitle]="slaContractActionPlanFormStore.submitButtonAndNewTitle"
            [submitButtonAndNewLoadingTitle]="slaContractActionPlanFormStore.submitButtonAndNewLoadingTitle"
            [cancelButtonTitle]="slaContractActionPlanFormStore.cancelButtonText"
            [formGroup]="slaContractActionPlanFormStore.formGroup"
            [formSections]="slaContractActionPlanFormStore.fields"
            [isLoading]="slaContractActionPlanFormStore.isItemFetching"
            (formSubmitRequest)="slaContractActionPlanFormStore.saveItem($event)"
            (closeRequest)="slaContractActionPlanFormStore.closeSideForm()"
        >
        </app-side-form>
    </app-side-form-modal>
</div>