import { Routes } from '@angular/router';
import { userRoutes } from './users/user-routes';
import { strategicProfileRoutes } from './strategic-profile/strategic-profile.routes';
import { processRoutes } from './process/process-routes';
import { controlRoutes } from './controls/control-routes';

export const organizationRoutes: Routes = [
  {
    path: '',
    redirectTo: '/organization/strategic-profile',
    pathMatch: 'full',
  },
  {
    path: 'strategic-profile',
    children: [...strategicProfileRoutes],
  },
  {
    path: 'users',
    children: [...userRoutes],
  },
  {
    path: 'process',
    children: [...processRoutes],
  },
  {
    path: 'controls',
    children: [...controlRoutes],
  },
  {
    path: 'organization-policies',
    loadComponent: () =>
      import('./policies/components/policy-list/policy-list.component'
      ).then((m) => m.PolicyListComponent),
  },
  {
    path: 'organization-policies/:id',
    loadComponent: () =>
      import('./policies/components/policy-details/policy-details.component'
      ).then((m) => m.PolicyDetailsComponent),
  },
  // {
  //   path: 'process',
  //   loadComponent: () =>
  //     import(
  //       './process/components/process-register/process-register.component'
  //     ).then((m) => m.ProcessRegisterComponent),
  // },
  // {
  //   path: 'process/:id',
  //   component: ProcessRegisterDetailsPageComponent,
  //   children: [
  //     {
  //       path: 'overview',
  //       component: ProcessRegisterDetailsOverviewComponent,
  //     },
  //     {
  //       path: 'controls',
  //       component: ProcessRegisterDetailsControlsComponent,
  //     },
  //     {
  //       path: 'associated-items',
  //       component: ProcessRegisterDetailsAssociatedItemsComponent,
  //     },
  //     {
  //       path: '',
  //       redirectTo: 'overview',
  //       pathMatch: 'full', // Redirect to 'overview' by default if no child route is specified
  //     },
  //   ],
  // },
  // {
  //   path: 'controls',
  //   loadComponent: () =>
  //     import('./controls/components/control-list/control-list.component').then(
  //       (m) => m.ControlListComponent
  //     ),
  // },
  // {
  //   path: 'controls/:id',
  //   component: ControlRegisterDetailsPageComponent,
  //   children: [
  //     {
  //       path: 'overview',
  //       component: ControlRegisterDetailsOverviewComponent,
  //     },
  //     {
  //       path: 'controls-tests',
  //       component: ControlRegisterDetailsControlsTestsComponent,
  //     },
  //     {
  //       path: 'associated-items',
  //       component: ControlRegisterDetailsAssociatedItemsComponent,
  //     },
  //     {
  //       path: 'action-plans',
  //       component: ControlRegisterDetailsActionPlansComponent,
  //     },
  //     {
  //       path: '',
  //       redirectTo: 'overview',
  //       pathMatch: 'full', // Redirect to 'overview' by default if no child route is specified
  //     },
  //   ],
  // },
];
