export interface ComplianceClauseDTO {
  id: number;
  title: string;
  clause_no: string;
}

export interface ComplianceClauseDetailsDTO {
  id: number;
  clause_no: string;
  title: string;
}

export function convertResponseToComplianceClauseDetailsDTO(
  response: any
): ComplianceClauseDetailsDTO | null {
  if (!response) {
    return null;
  }
  return {
    id: response.id,
    clause_no: response.clause_no,
    title: response.title,
  };
}
