<div id="tab5">
  @if(complianceActionPlanStore.isInitialLoading){
    <app-main-loader [width]="150" [height]="150"></app-main-loader>
  }@else {
    <div class="row mt-3">
      <app-list-page-header
        [isAddNewEnabled]="true"
        [isColumsConfigurable]="true"
        [coloums]="complianceActionPlanStore.columns"
        [addButtonLabel]="complianceActionPlanFormStore.addButtonLabel"
        (addRequest)="complianceActionPlanFormStore.openSideForm()"
        (searchRequest)="complianceActionPlanStore.setSearchQuery($event)"
        (toggleRequest)="complianceActionPlanStore.toggleColumn($event)">
      </app-list-page-header>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-basic-table
          [data]="complianceActionPlanStore.items"
          [columns]="complianceActionPlanStore.getEnabledColumns()"
          [actionConfig]="complianceActionPlanStore.actionConfig"
          [isPaginationEnabled]="true"
          [paginationConfig]="complianceActionPlanStore.paginationConfig"
          [sortField]="complianceActionPlanStore.sortField"
          [sortOrder]="complianceActionPlanStore.sortOrder"
          (pageChangeRequest)="complianceActionPlanStore.loadItems()"
          (sortRequest)="complianceActionPlanStore.setSort($event)"
          (editRequest)="complianceActionPlanFormStore.openSideForm($event)"
          [isDetailsModal]="true"
          (openDetailsRequest)="complianceActionPlanDetailsStore.openModal($event)">
        </app-basic-table>
      </div>
    </div>

    <app-side-form-modal
      [isSideFormOpen]="complianceActionPlanFormStore.isSideFormOpen">
      <app-side-form
        [title]="complianceActionPlanFormStore.title"
        [submitButtonTitle]="complianceActionPlanFormStore.submitButtonTitle"
        [submitButtonLoadingText]="complianceActionPlanFormStore.submitButtonLoadingText"
        [submitButtonAndNewTitle]="complianceActionPlanFormStore.submitButtonAndNewTitle"
        [submitButtonAndNewLoadingTitle]="complianceActionPlanFormStore.submitButtonAndNewLoadingTitle"
        [cancelButtonTitle]="complianceActionPlanFormStore.cancelButtonText"
        [formGroup]="complianceActionPlanFormStore.formGroup"
        [formSections]="complianceActionPlanFormStore.fields"
        [isLoading]="complianceActionPlanFormStore.isItemFetching"
        (formSubmitRequest)="complianceActionPlanFormStore.saveItem($event)"
        (closeRequest)="complianceActionPlanFormStore.closeSideForm()">
      </app-side-form>
    </app-side-form-modal>
  }
</div>

<app-compliance-details-action-plan-details-modal></app-compliance-details-action-plan-details-modal>
