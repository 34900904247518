import { Injectable } from '@angular/core';
import { RoleService } from '../../services/role.service';
import { RoleDTO } from '../../dto/role.dto';
import { makeAutoObservable, runInAction } from 'mobx';
import { IMasterCRUDListBaseStore } from '../../../../../core/store/master-crud-base.store';
import { TableColumn, TableActionConfig } from '../../../../../core/modals/table.modal';

@Injectable({ providedIn: 'root' })
export class RoleStore implements IMasterCRUDListBaseStore {
    //List
    items: RoleDTO[] = [];
    sortField: string = 'id';
    sortOrder: string = 'asc';
    searchQuery: string = '';

    actionConfig: TableActionConfig = {
        isEditable: true,
        isDeletable: true,
        isActivatable: true
    };

    columns: TableColumn[] = [
        {
            field: 'title_en',
            header: 'common.fields.title',
            sortable: true,
            size: 75,
            isEnable: true,
        },
        {
            field: 'status',
            header: 'common.fields.status',
            sortable: true,
            size: 15,
            isEnable: true,
        },
    ];

    constructor(
        private roleService: RoleService

    ) {
        makeAutoObservable(this);
    }

    setSort(event: { field: string; order: string }) {
        this.sortField = event.field;
        this.sortOrder = event.order;
        this.loadItems();
    }

    setSearchQuery(query: string) {
        this.searchQuery = query;
        this.loadItems();
    }

    loadItems() {
        this.roleService
        .list(this.sortField, this.sortOrder, this.searchQuery)
        .subscribe({
            next: (data) => {
                runInAction(() => {
                    this.items = data;
                });
            },
            error: (error) => {
                console.error('Failed to load roles:', error);
            },
        });
    }

    resetEntireState(): void {
        this.searchQuery = '';
        this.sortField = 'title_en';
        this.sortOrder = 'asc';
    }




}
