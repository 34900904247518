import { Injectable } from '@angular/core';
import { SlaContractActionPlanDTO } from '../dto/sla-contract-action-plan.dto';
import { FilterCategory, QuickFilterItem, TableActionConfig, TableColumnConfigurable, generatePages } from '../../../../core/modals/table.modal';
import { SlaContractActionPlanService } from '../services/sla-contract-action-plan.service';
import { SlaContractActionPlanListConfig } from '../config/sla-contract-action-plan-list.config';
import { SlaContractDetailsStore } from './sla-contract-details.store';
import { runInAction } from 'mobx';
import { ListBaseStore } from '../../../../core/store/list-base.store';

@Injectable({ providedIn: 'root' })
export class SlaContractActionPlanStore extends ListBaseStore<SlaContractActionPlanDTO>{
    override sortField: string = 'title';
    override columns: TableColumnConfigurable[];
    override quickFilters: QuickFilterItem[];
    override filterCategories: FilterCategory[] = [];

    actionConfig: TableActionConfig;

    constructor(
        private slaContractActionPlanService: SlaContractActionPlanService,
        private slaContractActionPlanListConfig: SlaContractActionPlanListConfig,
        private slaContractDetailsStore: SlaContractDetailsStore,
    ) {
        super()
        this.columns = this.slaContractActionPlanListConfig.columns;
        this.quickFilters = this.slaContractActionPlanListConfig.quickFilters();

       
        this.actionConfig = {
            isEditable: true,
            isDeletable: true,
            isActivatable: false,
        };
        
    }

    get service() {
        return this.slaContractActionPlanService;
    }
    override getMasterLists(): void {}

    override loadItems(): void {
        this.isLoading = true;
        const activeFilter = this.quickFilters.find((filter) => filter.isActive);
        const activeFilterKey = activeFilter ? activeFilter.key : undefined;
        const dynamicFilters = this.getAllSelectedIds();
        this.slaContractActionPlanService
        .getSlaActionPlanList(
            this.slaContractDetailsStore.details.id,
            this.sortField,
            this.sortOrder,
            this.paginationConfig.currentPage,
            this.paginationConfig.pageSize,
            this.searchQuery,
            activeFilterKey,
            dynamicFilters
        )
        .subscribe({
            next: (data: any) => {
                runInAction(() => {
                    this.items = data.items;
                    this.paginationConfig.totalPages = data.pagination.total_pages;
                    this.paginationConfig.totalCount = data.pagination.total_count;
                    this.paginationConfig.pages = generatePages(
                        this.paginationConfig.currentPage,
                        this.paginationConfig.totalPages
                    );
                });
                this.isLoading = false;
            },
            error: (error: any) => {
                console.error('Failed to load data:', error);
                this.isLoading = false;
            },
        });
    }

    resetEntireState(): void {
        this.searchQuery = '';
        this.sortField = 'title';
        this.sortOrder = 'asc';
        this.filterCategories = [];
        this.items = [];
        this.quickFilters = this.slaContractActionPlanListConfig.quickFilters();
        this.columns = this.slaContractActionPlanListConfig.columns;
    }

}