import { HttpInterceptorFn } from '@angular/common/http';
import { CurrentUserSessionStore } from '../store/current-user-session.store';
import { inject } from '@angular/core';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const currentUserSessionStore = inject(CurrentUserSessionStore);
  const authToken = currentUserSessionStore.accessToken;
  const authReq = req.clone({
    setHeaders: {
      Authorization: `Bearer ${authToken}`,
    },
  });

  return next(authReq);
};
