import { Injectable } from '@angular/core';
import {
  FilterCategory,
  QuickFilterItem,
  TableActionConfig,
  TableColumnConfigurable,
  createFilterCategory,
} from '../../../../../core/modals/table.modal';
import { catchError, forkJoin, tap } from 'rxjs';
import { runInAction } from 'mobx';
import { MainItemListBaseStore } from '../../../../../core/store/main-item-base.store';
import { SeverityLevelService } from '../../../../settings/masters/services/severity-level.service';
import { ActionPlanService } from '../../services/action-plan.service';
import { ActionPlanList } from '../../config/action-plan-list.config';
import { ActionPlanDTO } from '../../dto/action-plan.dto';

@Injectable({ providedIn: 'root' })
export class IncidentActionPlanStore extends MainItemListBaseStore<ActionPlanDTO> {
  override sortField: string = 'title';
  override columns: TableColumnConfigurable[];
  override quickFilters: QuickFilterItem[];
  override filterCategories: FilterCategory[] = [];

  constructor(
    private incidentActionPlanService: ActionPlanService,
    private actionPlanList: ActionPlanList,
    private severityLevelService: SeverityLevelService,
  ) {
    super();
    this.columns = this.actionPlanList.columns;
    this.quickFilters = [
      {
        label: 'All action plans',
        key: '',
        isActive: true,
      },
      {
        label: 'Recent Action Plans',
        key: 'recent_action_plans',
        isActive: false,
      },
      {
        label: 'Critical Action Plans',
        key: 'critical_action_plans',
        isActive: false,
      },
      {
        label: 'Action Plans',
        key: 'open_action_plans',
        isActive: false,
      },
    ];
  }

  actionConfig: TableActionConfig = {
    isEditable: true,
    isDeletable: true,
    isActivatable: false
};

  get service() {
    return this.incidentActionPlanService;
  }

  getMasterLists() {
    return forkJoin({
      severityLevles: this.severityLevelService.getSeverityLevels(),
    })
      .pipe(
        tap(({ severityLevles}) => {
          runInAction(() => {
            this.filterCategories = [
              createFilterCategory('Sevierity Level', 'severity_level_ids', severityLevles, 'title', 'en'),
            ];
          });
        }),
        catchError((error) => {
          console.error('Error fetching master lists', error);
          throw error;
        })
      )
      .subscribe();
  }

  resetEntireState(): void {
    this.searchQuery = '';
    this.sortField = 'title';
    this.sortOrder = 'asc';
    this.paginationConfig.currentPage = 1;
    this.paginationConfig.pageSize = 10;
  }
}
