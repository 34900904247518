import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
} from '@angular/router';
import { CurrentUserSessionStore } from '../store/current-user-session.store';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(
    private currentUserSessionStore: CurrentUserSessionStore,
    private router: Router
  ) {}

  canActivate(): boolean {
    if (this.currentUserSessionStore.isLoggedIn()) {
      this.router.navigate(['/organization/strategic-profile']);
      return false;
    }
    return true;
  }
}
