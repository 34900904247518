<div id="tab5">
  @if(meetingActionPlanStore.isInitialLoading){
    <app-main-loader [width]="150" [height]="150"></app-main-loader>
  }@else {
    <div class="row mt-3">
      <app-list-page-header
        [isAddNewEnabled]="true"
        [isColumsConfigurable]="true"
        [coloums]="meetingActionPlanStore.columns"
        [addButtonLabel]="meetingActionPlanFormStore.addButtonLabel"
        (addRequest)="meetingActionPlanFormStore.openSideForm()"
        (searchRequest)="meetingActionPlanStore.setSearchQuery($event)"
        (toggleRequest)="meetingActionPlanStore.toggleColumn($event)">
      </app-list-page-header>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-basic-table
          [data]="meetingActionPlanStore.items"
          [columns]="meetingActionPlanStore.getEnabledColumns()"
          [actionConfig]="meetingActionPlanStore.actionConfig"
          [isPaginationEnabled]="true"
          [paginationConfig]="meetingActionPlanStore.paginationConfig"
          [sortField]="meetingActionPlanStore.sortField"
          [sortOrder]="meetingActionPlanStore.sortOrder"
          (pageChangeRequest)="meetingActionPlanStore.loadItems()"
          (sortRequest)="meetingActionPlanStore.setSort($event)"
          (editRequest)="meetingActionPlanFormStore.openSideForm($event)"
          [isDetailsModal]="true"
          (openDetailsRequest)="meetingActionPlanDetailsStore.openModal($event)">
        </app-basic-table>
      </div>
    </div>

    <app-side-form-modal
      [isSideFormOpen]="meetingActionPlanFormStore.isSideFormOpen">
      <app-side-form
        [title]="meetingActionPlanFormStore.title"
        [submitButtonTitle]="meetingActionPlanFormStore.submitButtonTitle"
        [submitButtonLoadingText]="meetingActionPlanFormStore.submitButtonLoadingText"
        [submitButtonAndNewTitle]="meetingActionPlanFormStore.submitButtonAndNewTitle"
        [submitButtonAndNewLoadingTitle]="meetingActionPlanFormStore.submitButtonAndNewLoadingTitle"
        [cancelButtonTitle]="meetingActionPlanFormStore.cancelButtonText"
        [formGroup]="meetingActionPlanFormStore.formGroup"
        [formSections]="meetingActionPlanFormStore.fields"
        [isLoading]="meetingActionPlanFormStore.isItemFetching"
        (formSubmitRequest)="meetingActionPlanFormStore.saveItem($event)"
        (closeRequest)="meetingActionPlanFormStore.closeSideForm()">
      </app-side-form>
    </app-side-form-modal>
  }
</div>

<app-meeting-details-action-plan-details-modal></app-meeting-details-action-plan-details-modal>
