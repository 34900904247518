<div>
  @if(complianceRequirementStore.isInitialLoading){
    <app-main-loader [width]="150" [height]="150"></app-main-loader>
  }@else {
  <div class="row mt-3">
    <app-list-page-header
      [isAddNewEnabled]="true"
      [isMoreButtonEnabled]="false"
      [addButtonLabel]="complianceRequirementStore.addButtonLabel"
      (addRequest)="complianceRequirementFormStore.openSideForm()"
      (searchRequest)="complianceRequirementStore.setSearchQuery($event)">
    </app-list-page-header>
  </div>

  <div class="row">
    <div class="col-md-12">
      <app-basic-table
        [columns]="complianceRequirementStore.getEnabledColumns()"
        [data]="complianceRequirementStore.items"
        [actionConfig]="complianceRequirementStore.actionConfig"
        [sortField]="complianceRequirementStore.sortField"
        [sortOrder]="complianceRequirementStore.sortOrder"
        (sortRequest)="complianceRequirementStore.setSort($event)"
        (editRequest)="complianceRequirementFormStore.openSideForm($event)"
        [isPaginationEnabled]="true"
        [paginationConfig]="complianceRequirementStore.paginationConfig"
        (pageChangeRequest)="complianceRequirementStore.loadItems()"
        [isDetailsModal]="true"
        [isRawSpan]="true"
        (openDetailsRequest)="complianceRequirementDetailsStore.openModal($event)">
      </app-basic-table>
    </div>
  </div>

  <app-side-form-modal [isSideFormOpen]="complianceRequirementFormStore.isSideFormOpen">
    <app-side-form
      [title]="complianceRequirementFormStore.title"
      [submitButtonTitle]="complianceRequirementFormStore.submitButtonTitle"
      [submitButtonLoadingText]="complianceRequirementFormStore.submitButtonLoadingText"
      [submitButtonAndNewTitle]="complianceRequirementFormStore.submitButtonAndNewTitle"
      [submitButtonAndNewLoadingTitle]="complianceRequirementFormStore.submitButtonAndNewLoadingTitle"
      [cancelButtonTitle]="complianceRequirementFormStore.cancelButtonText"
      [formGroup]="complianceRequirementFormStore.formGroup"
      [formSections]="complianceRequirementFormStore.fields"
      [isLoading]="complianceRequirementFormStore.isItemFetching"
      (formSubmitRequest)="complianceRequirementFormStore.saveItem($event)"
      (closeRequest)="complianceRequirementFormStore.closeSideForm()">
    </app-side-form>
  </app-side-form-modal>
  }
</div>
<app-compliance-details-requirement-details-modal></app-compliance-details-requirement-details-modal>
