<body id="wrapper" [class.sidebar-filter]="uiStore.isOpenSideFilter">
    <!-- Sidebar Component -->
    <app-side-menu></app-side-menu>

    <!-- Main content area with header and router outlet for content -->
    <div class="page-main">
        <!-- Header Component -->
        <app-header></app-header>

        <!-- Main Section for routed components -->
        <section class="right-content">
            <router-outlet></router-outlet>
            <!-- Angular router directive -->
        </section>
    </div>
</body>
