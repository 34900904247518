<div>
    @if (auditInsightDraftReportDetailsStore.isIntialLoading) {
    <app-main-loader [width]="150" [height]="150"></app-main-loader>
    } @else {
    <div class="row mt-2">
      <app-audit-insight-draft-report-header></app-audit-insight-draft-report-header>
    </div>

    <div class="row mt-4">
      <div class="col-md-9">
        <!-- START AUDIT DRAFT REPORT DOCUMENT -->
  
        <app-draft-report-page
          [reportDetails]="auditInsightDraftReportDetailsStore.details"
          [isFindingEditButton]="false"
          [isFindingDeleteButton]="false"
          >
        </app-draft-report-page>
  
        <!-- END AUDIT DRAFT REPORT DOCUMENT -->
      </div>
      <div class="col-md-3">
        <!-- START RIGHT SIDE SECTION -->
        <app-audit-insight-draft-report-info></app-audit-insight-draft-report-info>
        <!-- END RIGHT SIDE SECTION -->
      </div>
    </div>
    }
    </div>