import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { BaseService } from '../../../../core/services/base.service';
import { ActionPlanDTO, ActionPlanFormDTO } from '../dto/action-plan.dto';


@Injectable({
  providedIn: 'root',
})
export class ActionPlanService extends BaseService<ActionPlanDTO, ActionPlanFormDTO> {
  protected apiUrl = environment.apiUrl + '/incident-action-plans';

  constructor(http: HttpClient) {
    super(http);
  }
}
