import { Routes } from '@angular/router';

export const controlRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/control-list/control-list.component').then(
        (m) => m.ControlListComponent
      ),
  },
  {
    path: ':id',
    loadComponent: () =>
      import(
        './components/control-details/control-details-page/control-details-page.component'
      ).then((m) => m.ControlDetailsPageComponent),
    children: [
      {
        path: 'overview',
        loadComponent: () =>
          import(
            './components/control-details/overview/control-details-overview/control-details-overview.component'
          ).then((m) => m.ControlDetailsOverviewComponent),
      },
      {
        path: 'controls-tests',
        loadComponent: () =>
          import(
            './components/control-details/control-tests/control-details-control-test/control-details-control-test.component'
          ).then((m) => m.ControlDetailsControlTestComponent),
      },
      {
        path: 'associated-items',
        loadComponent: () =>
          import(
            './components/control-details/associated-items/control-details-associated-items/control-details-associated-items.component'
          ).then((m) => m.ControlDetailsAssociatedItemsComponent),
      },
      {
        path: 'action-plans',
        loadComponent: () =>
          import(
            './components/control-details/action-plans/control-details-action-plans/control-details-action-plans.component'
          ).then((m) => m.ControlDetailsActionPlansComponent),
      }

    ]

  }

];
