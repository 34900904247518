import { Routes } from '@angular/router';

export const authRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/auth-page/auth-page.component').then(
        (m) => m.AuthPageComponent
      ),
    children: [
      {
        path: 'login',
        loadComponent: () =>
          import('./components/login/login-form/login-form.component').then(
            (m) => m.LoginFormComponent
          ),
      },
      {
        path: 'login/verify-otp',
        loadComponent: () =>
          import('./components/login/verify-otp/verify-otp.component').then(
            (m) => m.VerifyOTPComponent
          ),
      },

      {
        path: 'password-recovery',
        loadComponent: () =>
          import(
            './components/password-recovery/get-otp/get-otp.component'
          ).then((m) => m.GetOtpComponent),
      },
      {
        path: 'password-recovery/setup-password',
        loadComponent: () =>
          import(
            './components/password-recovery/setup-password/setup-password.component'
          ).then((m) => m.SetupPasswordComponent),
      },
      {
        path: 'password-recovery/verify-otp',
        loadComponent: () =>
          import(
            './components/password-recovery/verify-otp/verify-otp.component'
          ).then((m) => m.VerifyOtpComponent),
      },
    ],
  },
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full',
  },
];
