import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { UiStore } from '../store/ui.store';
import { Router } from '@angular/router';
import { CurrentUserSessionStore } from '../store/current-user-session.store';

export const errorHandlerInterceptor: HttpInterceptorFn = (req, next) => {
  const uiStore = inject(UiStore);
  const router = inject(Router);
  const currentUserSessionStore = inject(CurrentUserSessionStore);
  return next(req).pipe(
    catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          currentUserSessionStore.logout();
          console.error('Unauthorized request:', err);
          router.navigate(['/auth/login']);
        } else if (err.status === 400) {
          uiStore.formErrors = err.error.errors;
        }
      } else {
        console.error('An error occurred:', err);
      }
      return throwError(() => err);
    })
  );
};
