import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { AssociatedItemServiceInterface } from '../../../../core/interfaces/service/associated-item-service.interface';
import { ComplianceAssociatedItemResponseDTO, IncidentAssociatedItemResponseDTO, ProcessAssociatedItemResponseDTO, RiskAssociatedItemResponseDTO } from '../../../../shared/dto/associated-item.dto';

@Injectable({
  providedIn: 'root',
})
export class ComplianceRequirementAssociatedItemService
  implements AssociatedItemServiceInterface
{
  apiUrl = environment.apiUrl + '/compliance-requirements';

  constructor(private http: HttpClient) {}

    getAssociatedControls(
        requirementId: number,
        currentPage: number = 1,
        pageSize: number = 10,
        searchQuery?: string
    ): Observable<any[]> {
        let params = new HttpParams()
        .set('page', currentPage.toString())
        .set('page_size', pageSize.toString());

        if (searchQuery) {
        params = params.set('keyword', searchQuery);
        }

        const url = `${this.apiUrl}/${requirementId}/controls`;
        return this.http.get<any[]>(url, { params });
    }

    getAssociatedStrategicObjectives (
        requirementId: number,
        currentPage: number = 1,
        pageSize: number = 10,
        searchQuery?: string
    ): Observable<any[]> {
        let params = new HttpParams()
        .set('page', currentPage.toString())
        .set('page_size', pageSize.toString());

        if (searchQuery) {
        params = params.set('keyword', searchQuery);
        }

        const url = `${this.apiUrl}/${requirementId}/strategic-objectives`;
        return this.http.get<any[]>(url, { params });
    }

    getAssociatedRisks(
        requirementId: number,
        currentPage: number = 1,
        pageSize: number = 10,
        searchQuery?: string
    ): Observable<RiskAssociatedItemResponseDTO[]> {
        throw new Error('Method not implemented.');
    }

    getAssociatedIncidents(
        requirementId: number,
        currentPage: number = 1,
        pageSize: number = 10,
        searchQuery?: string
    ): Observable<IncidentAssociatedItemResponseDTO[]> {
        throw new Error('Method not implemented.');
    }



    getAssociatedComplianceRequirements(
        requirementId: number,
        currentPage?: number | undefined,
        pageSize?: number | undefined,
        searchQuery?: string | undefined
    ): Observable<ComplianceAssociatedItemResponseDTO[]> {
        throw new Error('Method not implemented.');
    }

    getAssociatedProcesses(
        requirementId: number,
        currentPage?: number | undefined,
        pageSize?: number | undefined,
        searchQuery?: string | undefined
    ): Observable<ProcessAssociatedItemResponseDTO[]> {
        throw new Error('Method not implemented.');
    }
}
