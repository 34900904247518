import { Injectable } from '@angular/core';
import { action, makeObservable, observable } from 'mobx';
import {
  BreadcrumbItem,
  DetailsTab,
} from '../../../../core/modals/details.modal';
import { DetailsBaseStore } from '../../../../core/store/details-base.store';
import { AuditInsightDraftReportDetailsConfig } from '../config/audit-insight-draft-report-details.config';
import { AuditInsightDraftReportService } from '../services/audit-insight-draft-report.service';
import { AuditDraftReportDetailsDTO, AuditDraftReportDocumentDTO } from '../../../audit/audit/dto/audit-draft-report.dto';
import { AuditDetailsDraftReportConversion } from '../../../audit/audit/conversion/audit-details-draft-report.conversion';

@Injectable({ providedIn: 'root' })
export class AuditInsightDraftReportDetailsStore extends DetailsBaseStore<AuditDraftReportDocumentDTO> {
  tabs: DetailsTab[] = [];
  breadcrumbs: BreadcrumbItem[] = [];
  auditDepartments: string;
  auditDraftReportDetails: AuditDraftReportDetailsDTO;

  constructor(
    private auditDetailsDraftReportConv: AuditDetailsDraftReportConversion,
    private auditInsightDraftReportDetailsConfig: AuditInsightDraftReportDetailsConfig,
    private auditInsightDraftReportService: AuditInsightDraftReportService,

  ) {
    super();
    makeObservable(this, {
      tabs: observable,
      breadcrumbs: observable,
      convertResponseToDTO: action,
    });
  }

  override get service(): any {
    return this.auditInsightDraftReportService;
  }

  override initialize(): void {
    this.tabs = this.auditInsightDraftReportDetailsConfig.auditTabs;
    this.breadcrumbs = this.auditInsightDraftReportDetailsConfig.breadcrumbs;
  }


  convertResponseToDTO(response: any) {
    const draftReportDetails = this.auditDetailsDraftReportConv.resToDraftReportDocument(response);
    this.auditDraftReportDetails = this.auditDetailsDraftReportConv.resToDetails(response);
    const { title } = draftReportDetails;
    this.breadcrumbs[1].label = `${title}`;
    return draftReportDetails;
  }


  override resetEntireChildState(): void {
    this.auditDraftReportDetails = {} as AuditDraftReportDetailsDTO
    this.tabs = [];
    this.breadcrumbs = [];
  }
}
