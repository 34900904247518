import { Component } from '@angular/core';
import { MainLoaderComponent } from '../../../../../../../shared/components/loaders/main-loader/main-loader.component';
import { AuditInsightDraftReportDetailsStore } from '../../../../store/audit-insight-draft-report-details.store';
import { AuditInsightDraftReportHeaderComponent } from '../audit-insight-draft-report-header/audit-insight-draft-report-header.component';
import { DraftReportPageComponent } from '../../../../../../audit/audit/components/audit-details/shared/draft-report/draft-report-page/draft-report-page.component';
import { AuditInsightDraftReportInfoComponent } from '../audit-insight-draft-report-info/audit-insight-draft-report-info.component';

@Component({
  selector: 'app-audit-insight-draft-report',
  standalone: true,
  imports: [
    MainLoaderComponent,
    AuditInsightDraftReportHeaderComponent,
    DraftReportPageComponent,
    AuditInsightDraftReportInfoComponent
  ],
  templateUrl: './audit-insight-draft-report.component.html',
  styleUrl: './audit-insight-draft-report.component.scss'
})
export class AuditInsightDraftReportComponent {
  constructor(
   
    public auditInsightDraftReportDetailsStore: AuditInsightDraftReportDetailsStore
  ) {}
}
