<app-details-modal
  [title]="'Compliance Action Plan Details'"
  [showModal]="complianceActionPlanDetailsStore.showModal"
  (closeModalRequest)="complianceActionPlanDetailsStore.closeModal()">
  @if(complianceActionPlanDetailsStore.isInitialLoading ){
    <app-main-loader [height]="150" [width]="150"></app-main-loader>
  }@else if(complianceActionPlanDetailsStore.subItem){
    <app-details-box [title]="'Overview'">
      <div class="row">
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'Compliance Register'"
            [value]="complianceDetailsStore.details.title">
          </app-label-display>
        </div>
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'Complaince Requirement'"
            [value]="complianceActionPlanDetailsStore.subItem.compliance_requirement?.title">
          </app-label-display>
        </div>
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'Action Plan'"
            [value]="complianceActionPlanDetailsStore.subItem.title">
          </app-label-display>
        </div>
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'Severity Level'"
            [value]="complianceActionPlanDetailsStore.subItem.severity_level?.title">
          </app-label-display>
        </div>
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'Responsible User'"
            [type]="'user'"
            [user]="complianceActionPlanDetailsStore.subItem.responsible_user">
          </app-label-display>
        </div>
        <div class="col-md-4 col-sm-6">
          <app-label-display
            [label]="'Target Date'"
            [type]="'datetime'"
            [value]="complianceActionPlanDetailsStore.subItem.target_date">
          </app-label-display>
        </div>
        <div class="col-md-12 col-sm-12">
          <app-label-display
            [label]="'Description'"
            [value]="complianceActionPlanDetailsStore.subItem.description">
          </app-label-display>
        </div>
      </div>
    </app-details-box>


    <app-status-update
      [isInitialLoading]="complianceActionPlanDetailsStore.isInitialLoading "
      [latestDetails]="complianceActionPlanDetailsStore.statusDetailsLatest"
      [isShowStatusForm]="complianceActionPlanDetailsStore.isShowStatusForm"
      [isShowHistory]="complianceActionPlanDetailsStore.isShowHistory"
      [formGroup]="complianceActionPlanDetailsStore.formGroup"
      [formSections]="complianceActionPlanDetailsStore.formSections"
      [isFormDataLoading]="complianceActionPlanDetailsStore.isFetching"
      (toggleStatusFormRequest)="complianceActionPlanDetailsStore.toggleStatusForm()"
      (toggleStatusHistoryRequest)="complianceActionPlanDetailsStore.toggleHistory()"
      (closeStatusFormRequest)="complianceActionPlanDetailsStore.closeStatusForm()"
      (formSubmitRequest)="complianceActionPlanDetailsStore.updateStatus()"
      [isHistoryLoading]="complianceActionPlanDetailsStore.isLoading"
      [historyColumns]="complianceActionPlanDetailsStore.coloums"
      [history]="complianceActionPlanDetailsStore.history">
    </app-status-update>

  }
</app-details-modal>
