import { Routes } from '@angular/router';

export const documentChangeRequestRoutes: Routes = [
    {
        path: '',
        loadComponent: () =>
            import(
                './components/change-request-list/change-request-list.component'
            ).then((m) => m.ChangeRequestListComponent),
    },
    {
        path: ':id',
        loadComponent: () =>
            import(
                './components/change-request-details/change-request-details-page/change-request-details-page.component'
            ).then((m) => m.ChangeRequestDetailsPageComponent),
    }
]