import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';

import { convertResponseToUserMinimalDTO } from '../../../organization/users/dto/user.dto';


import { FormGroup } from '@angular/forms';
import { DocumentWorkflowDTO } from '../dto/document-workflow.dto';

@Injectable({
  providedIn: 'root',
})
export class DocumentWorkflowConversion
 
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }


  resToList(response: any[]): DocumentWorkflowDTO[] {
    if (!response) {
      return [];
    }
    return response.map((item) => {
      return {
        id: item.id,
        type: item.type,
        status: item.status,
        document_type: item[`document_type_title_${this.lang}`],
        created_at: item.created_at,
        created_by: convertResponseToUserMinimalDTO(item, this.lang, 'created_user_'),
        updated_at: item.updated_at ? item.updated_at : null,
        updated_by: item.updated_by ? convertResponseToUserMinimalDTO(item, this.lang, 'updated_user_'): null,
       
      };
    });
  }


}
