import { Injectable } from '@angular/core';
import { ComplianceActionPlanDetailsDTO } from '../dto/compliance-action-plan.dto';
import { runInAction } from 'mobx';
import { ComplianceActionPlanService } from '../services/compliance-action-plan.service';
import { ComplianceDetailsStore } from './compliance-details.store';
import { FormSection } from '../../../../shared/models/form.config';
import { UiStore } from '../../../../core/store/ui.store';
import { TableColumnConfigurable } from '../../../../core/modals/table.modal';
import { ActionPlanStatusDTO } from '../../../settings/masters/dto/action-plan-status.dto';
import { AlertService } from '../../../../core/services/alert.service';
import { StatusUpdateConversion } from '../../../../shared/conversion/status-update.conversion.dto';
import { ComplianceActionPlanConversion } from '../conversion/compliance-action-plan.conversion';
import { ComplianceActionPlanDetailsStatusConfig } from '../config/compliance-action-plan-status.config';
import { DetailsStatusModalBaseStore } from '../../../../core/store/details-status-modal-base.store';
import { ComplinaceActionPlanStatusUpdateConversion } from '../conversion/compliance-action-plan-status-update.conversion';
import { ComplianceActionPlanStatusUpdateService } from '../services/compliance-action-plan-status-update.service';
import { ActionPlanStatusService } from '../../../settings/masters/services/action-plan-status.service';
import { StatusDetailsLatestDTO } from '../../../../shared/dto/status-update.dto';

@Injectable({ providedIn: 'root' })
export class ComplianceActionPlanDetailsStore extends DetailsStatusModalBaseStore {
  protected override mainId: number | undefined;
  protected override subId: number | undefined;
  
  actionPlan: ComplianceActionPlanDetailsDTO;

  //form
  protected override statusHistoryId: number | undefined;
  override formSections: FormSection[];
  actionPlanStatuses: ActionPlanStatusDTO[];
  public override statusTitle: string = 'action_plan_status';
  //history
  override coloums: TableColumnConfigurable[];

  constructor(
    private complianceDetailsStore: ComplianceDetailsStore,
    private complianceActionPlanDetailsStatusConfig: ComplianceActionPlanDetailsStatusConfig,
    private complinaceActionPlanStatusUpdateConv: ComplinaceActionPlanStatusUpdateConversion,
    private complianceActionPlanService: ComplianceActionPlanService,
    private complianceActionPlanConv: ComplianceActionPlanConversion,

   //for parent class
   statusConv: StatusUpdateConversion, //converting response in to status master format
   complianceActionPlanStatusUpdateService: ComplianceActionPlanStatusUpdateService, //for main service
   actionPlanStatusService: ActionPlanStatusService, // for status service
   uiStore: UiStore,
   alertService: AlertService,
  ) {
    super(uiStore, alertService, statusConv, complianceActionPlanStatusUpdateService, actionPlanStatusService, complianceActionPlanService);
    this.statusFormConfig = complianceActionPlanDetailsStatusConfig;
    this.formSections = complianceActionPlanDetailsStatusConfig.formSections;
    this.coloums = complianceActionPlanDetailsStatusConfig.columns;
  }

  openModal(actionPlan: any) {
    this.mainId = this.complianceDetailsStore.details.id;
    this.subId = actionPlan.id;
    this.fetchSubItemDetails();
    this.fetchLatestStatus();
    this.fetchMasters();
    this.resetForm();
    this.fetchHistory();

    this.showModal = true;

  }



  convertResToSubItemDetailsDTO(response: any) {
    return this.complianceActionPlanConv.resToDetails(
      response
    );
  }

  convertFormToUpdateStatusReq(): any {
    return this.complinaceActionPlanStatusUpdateConv.formToUpdateStatusReq(
      this.formGroup
    );
  }

  convertResToStatusLatestDetailsDTO(response: any): any {
    return this.statusUpdateConversion.resToLatestStatusDetailsDTO(
      response,
      this.statusTitle
    );
  }

  protected override refreshDetails() {
    if (this.mainId) this.complianceDetailsStore.fetchDetails(this.mainId);
  }

  resetEntireState(): void {
    runInAction(() => {
      // Reset generic properties
      this.isInitialLoading = true;
      this.isFetching = false;
      this.showModal = false;
      this.mainId = undefined;
      this.subId = undefined;

      this.subItem = undefined;
      this.statusDetailsLatest = {} as StatusDetailsLatestDTO;

      // Reset form related properties
      this.isShowStatusForm = false;
      this.formGroup.reset();
      this.statuses = [];
      this.formSections = this.complianceActionPlanDetailsStatusConfig.formSections;
      this.statusHistoryId = undefined;

      // Reset history related properties
      this.history = [];
      this.isShowHistory = false;
      this.actionConfig = {
        isEditable: true,
        isDeletable: false,
        isActivatable: false,
      };
      this.coloums = this.complianceActionPlanDetailsStatusConfig.columns;
    });
  }
}
