import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { TableColumnConfigurable } from '../../../../core/modals/table.modal';

@Injectable({ providedIn: 'root' })
export class ActionPlanList {
  languageKey: string = 'en';
  public columns: TableColumnConfigurable[] = [];

  constructor(
    private userPreference: CurrentUserPreferenceStore
  ) {
    this.languageKey = this.userPreference.languageKey;
    this.columns = this.createColumns();
  }

  private createColumns(): TableColumnConfigurable[] {
    const columns: TableColumnConfigurable[] = [
      {
        field: 'reference_number',
        header: 'Reference Number',
        sortable: true,
        size: 10,
        isEnable: true,
        isRequired: true,
      },
      {
        field: 'incident_title',
        header: 'Incident Title',
        sortable: true,
        size: 15,
        isEnable: true,
        isRequired: true,
      },
      {
        field: 'incident_status',
        header: 'Incident Status',
        sortable: true,
        size: 10,
        isEnable: true,
        isRequired: true,
      },
      {
        field: 'title',
        header: 'Action Plan Title',
        sortable: true,
        size: 15,
        isEnable: true,
        isRequired: true,
      },
      {
        field: 'description',
        header: 'Description',
        sortable: true,
        size: 20,
        isEnable: true,
        isRequired: false,
      },
      {
        field: 'severity_level_title_' + this.languageKey,
        header: 'Priority',
        sortable: true,
        size: 10,
        isEnable: true,
        isRequired: false,
      },
      {
        field: 'responsible_user_first_name_' + this.languageKey,
        header: 'Responsible User',
        sortable: true,
        size: 15,
        isEnable: true,
        isRequired: false,
      },
      {
        field: 'target_date',
        header: 'Target Date',
        sortable: true,
        size: 15,
        isEnable: true,
        isRequired: false,
      },
      {
        field: 'percentage',
        header: '% Completion',
        sortable: true,
        size: 10,
        isEnable: true,
        isRequired: false,
      },
      {
        field: 'action_plan_status_title_ar' + this.languageKey,
        header: 'Status',
        sortable: true,
        size: 10,
        isEnable: true,
        isRequired: false,
      },
      {
        field: 'reported_at',
        header: 'Reported Date & Time',
        sortable: true,
        size: 15,
        isEnable: true,
        isRequired: false,
      },
      {
        field: 'current_status_title_' + this.languageKey,
        header: 'Current Status',
        sortable: true,
        size: 10,
        isEnable: true,
        isRequired: false,
      },
    ];

    return columns;
  }
}
