import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { FormSection } from '../../../../shared/models/form.config';
import { SeverityLevelService } from '../../../settings/masters/services/severity-level.service';
import { UserService } from '../../../organization/users/services/user.service';
import { ActionPlanStatusService } from '../../../settings/masters/services/action-plan-status.service';

@Injectable({ providedIn: 'root' })
export class SlaContractActionPlanFormConfig {
  formSections: FormSection[] = [
    {
        key: 'compliance_action_plan',
        title: '',
        fields: [
            {
                id: 'title',
                label: 'Title',
                formControlName: 'Title',
                type: 'text',
                placeholder: 'Enter title',
                size: 12,
                isInfoIcon: true,
                validators: ['required'],
            },
          
            {
                id: 'description',
                label: 'Description',
                formControlName: 'Description',
                type: 'textarea',
                placeholder: 'Enter description',
                size: 12,
                isInfoIcon: true,
                validators: [],
            },
           
            {
                id: 'severityLevel',
                label: 'Severity Level',
                formControlName: 'SeverityLevel',
                type: 'dropdown',
                placeholder: '',
                size: 6,
                isInfoIcon: false,
                service: this.severityLevelService,
                serviceMethod: 'getSeverityLevels',
                validators: ['required'],
            },
            {
                id: 'responsibleUser',
                label: 'Responsible User',
                formControlName: 'ResponsibleUser',
                type: 'autocomplete-static',
                placeholder: 'Select User',
                size: 6,
                isInfoIcon: false,
                service: this.userService,
                serviceMethod: 'list',
                validators: ['required'],
            },
            {
                id: 'percentage',
                label: 'Percentage',
                formControlName: 'Percentage',
                type: 'number',
                placeholder: 'Enter percentage',
                size: 6,
                isInfoIcon: false,
                validators: [],
            },
            {
                id: 'target',
                label: 'Target',
                formControlName: 'TargetDate',
                type: 'datepicker',
                placeholder: 'Please choose target date',
                size: 6,
                isInfoIcon: true,
                validators: ['required'],
            },
            {
                id: 'actionPlanStatus',
                label: 'Action Plan Status',
                formControlName: 'ActionPlanStatus',
                type: 'autocomplete-static',
                placeholder: 'Choose action plan status',
                size: 6,
                isInfoIcon: true,
                service: this.actionPlanStatusService,
                serviceMethod: 'getActionPlanStatuses',
                validators: ['required'],
            },  
        ],
    },
 
  ];
  constructor(
    private userPreference: CurrentUserPreferenceStore,
    private actionPlanStatusService: ActionPlanStatusService,
    private severityLevelService: SeverityLevelService,
    private userService: UserService,
  ) {}

  updateOptions(
    fields: FormSection[],
    severityLevels: any[],
    actionPlanStatuses: any[],
    responsibleUsers: any,
  ): void {
    
    const languageKey: string = this.userPreference.languageKey || 'en';
    fields.forEach((section) => {
      section.fields.forEach((field) => {
            
        
        if (field.id === 'severityLevel') {
          field.options = severityLevels.map((severityLevel) => ({
            id: severityLevel.id,
            title: severityLevel[`title_${languageKey}`],
          }));
        }
       
        if (field.id === 'actionPlanStatus') {
          field.options = actionPlanStatuses.map((status) => ({
            id: status.id,
            title: status[`title_${languageKey}`],
          }));
        }
       
        if (field.id === 'responsibleUser') {
          field.options = responsibleUsers.items.map((responsibleUser: any) => ({
            id: responsibleUser.id,
            title: responsibleUser[`first_name_${languageKey}`],
          }));
        }
      });
    });
  }
}