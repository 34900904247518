import { Component } from '@angular/core';

@Component({
  selector: 'app-sla-contract-associated-items',
  standalone: true,
  imports: [],
  templateUrl: './sla-contract-associated-items.component.html',
  styleUrl: './sla-contract-associated-items.component.scss'
})
export class SlaContractAssociatedItemsComponent {

}
