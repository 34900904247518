import { Routes } from '@angular/router';
import { potentialRiskRoutes } from './potential-risks/potential-risk-routes';
import { kriRoutes } from './kri-register/kri-routes';
import { riskRegisterRoutes } from './risk-register/risk-register-route';
import { riskMitigationRoutes } from './risk-mitigation/risk-mitigation-route';
import { riskAppetiteStatementRoutes } from './risk-appetite-statement/risk-appetite-statement-route';
import { riskMatrixRoutes } from './risk-matrix/risk-matrix-route';
import { riskHeatmapRoutes } from './risk-heatmaps/risk-heatmap.route';

export const riskRoutes: Routes = [
  {
    path: 'risk-matrix',
    children: [...riskMatrixRoutes],
  },
  {
    path: 'risk-appetite-statement',
    children: [...riskAppetiteStatementRoutes],
  },
  {
    path: 'potential-risk',
    children: [...potentialRiskRoutes],
  },
  {
    path: 'kri-register',
    children: [...kriRoutes],
  },
  {
    path: 'risk-register',
    children: [...riskRegisterRoutes],
  },
  {
    path: 'risk-mitigation',
    children: [...riskMitigationRoutes],
  },
  {
    path: 'risk-heatmaps',
    children: [...riskHeatmapRoutes],
  },
];
