<app-filter-sidebar>
    <app-quick-filters
      [items]="incidentActionPlanStore.quickFilters"
      (quickFilterRequest)="incidentActionPlanStore.loadItems()">
    </app-quick-filters>
  
    <app-dynamic-filters
      [items]="incidentActionPlanStore.filterCategories"
      (filterRequest)="incidentActionPlanStore.loadItems()">
    </app-dynamic-filters>
  </app-filter-sidebar>
  
  <app-list-page-header
    [title]="'Action Plans'"
    [coloums]="incidentActionPlanStore.columns"
    [isAddNewEnabled]="true"
    [isColumsConfigurable]="true"
    [isQuickSortEnabled]="true"
    [isMoreButtonEnabled]="true"
    [addButtonLabel]="incidentActionPlanFormStore.addButtonLabel"
    [quickSortItems]="incidentActionPlanFormStore.quickSortItems"
    (addRequest)="incidentActionPlanFormStore.openModal()"
    (searchRequest)="incidentActionPlanStore.setSearchQuery($event)"
    (toggleRequest)="incidentActionPlanStore.toggleColumn($event)"
  >
  </app-list-page-header>
  
  <div class="row">
    <div class="col-md-12">
      <app-basic-table
        [data]="incidentActionPlanStore.items"
        [columns]="incidentActionPlanStore.getEnabledColumns()"
        [actionConfig]="incidentActionPlanStore.actionConfig"
        [isPaginationEnabled]="true"
        [paginationConfig]="incidentActionPlanStore.paginationConfig"
        [sortField]="incidentActionPlanStore.sortField"
        [sortOrder]="incidentActionPlanStore.sortOrder"
        [rowUrl]="'/incident/action-plan'"
        (pageChangeRequest)="incidentActionPlanStore.loadItems()"
        (sortRequest)="incidentActionPlanStore.setSort($event)">
      </app-basic-table>
    </div>
  </div>
  