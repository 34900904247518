import { Routes } from '@angular/router';
import { SlaContractAssociatedItemsComponent } from './components/sla-contract-details/sla-contract-associated-items/sla-contract-associated-items.component';
import { SlaContractAssessmentComponent } from './components/sla-contract-details/sla-contract-assessment/sla-contract-assessment.component';
import { SlaContractDetailsActionPlansComponent } from './components/sla-contract-details/sla-contract-details-action-plans/sla-contract-details-action-plans.component';

export const slaContractRoutes: Routes = [
    {
        path: '',
        loadComponent: () =>
        import('./components/sla-contract-list/sla-contract-list.component').then(
            (m) => m.SlaContractListComponent
        ),
    },
    {
        path: ':id',
        loadComponent: () =>
            import(
                './components/sla-contract-details/sla-contract-details-page/sla-contract-details-page.component'
            ).then((m) => m.SlaContractDetailsPageComponent),
            children: [
                {
                  path: 'overview',
                  loadComponent: () =>
                    import(
                        './components/sla-contract-details/sla-contract-details-overview/sla-contract-details-overview.component'
                    ).then((m) => m.SlaContractDetailsOverviewComponent),
                },
                {
                    path: 'associated-items',
                    component: SlaContractAssociatedItemsComponent,
                },
                {
                    path: 'assessments',
                    component: SlaContractAssessmentComponent,
                },
                {
                    path: 'action-plans',
                    component: SlaContractDetailsActionPlansComponent,
                },
                {
                  path: '**',
                  redirectTo: 'overview',
                },
            ],
           
    },

];
