import { Injectable } from '@angular/core';
import { action, makeObservable, observable, runInAction } from 'mobx';

import { MeetingDetailsStore } from './meeting-details.store';
import { FormBaseStore } from '../../../../core/store/form-base.store';
import { FormSection } from '../../../../shared/models/form.config';
import { TableActionConfig, TableColumnConfigurable, TableQuickSortItem } from '../../../../core/modals/table.modal';
import { UiStore } from '../../../../core/store/ui.store';
import { AlertService } from '../../../../core/services/alert.service';
import { ConfirmService } from '../../../../core/services/confirm.service';
import { MeetingDetailsMomFormDTO, TransformedMeetingDetailsMomDTO } from '../dto/meeting-details-mom.dto';
import { MeetingDetailsMomService } from '../services/meeting-details-mom.service';
import { MeetingDetailsMomFormConfig } from '../config/meeting-details-mom-form.config';
import { MeetingDetailsMomConversion } from '../conversion/meeting-details-mom.conversion';
import { meetingDetailsMomListTablecolumns } from '../config/meeting-details-mom-list.config';
import { catchError, forkJoin, Observable, of, tap, throwError } from 'rxjs';
import { MeetingAgendaService } from '../services/meeting-agenda.service';
import { MeetingAgendaListDTO } from '../dto/meeting-agenda.dto';

@Injectable({ providedIn: 'root' })
export class MeetingDetailsMomStore extends FormBaseStore<MeetingDetailsMomFormDTO>{
    isLoading: boolean = true;
    isInitalLoading: boolean = true;
    meetingMoms: TransformedMeetingDetailsMomDTO[] = [];
    actionConfig: TableActionConfig = {} as TableActionConfig;
    columns: TableColumnConfigurable[] = [];
    meetingAgendas: MeetingAgendaListDTO[];

    entityName: string = '';
    fields: FormSection[] = [];
    quickSortItems: TableQuickSortItem[] = [];
    itemId: number | null = null;


    constructor(
        private meetingDetailsMomService: MeetingDetailsMomService,
        private meetingDetailsStore: MeetingDetailsStore,
        private meetingDetailsMomFormConfig: MeetingDetailsMomFormConfig,
        private conversion: MeetingDetailsMomConversion,
        protected uiStore: UiStore,
        protected alertService: AlertService,
        protected confirmService: ConfirmService,
        private meetingAgendaService: MeetingAgendaService,
    ) {
        super();
        makeObservable(this, {
        isLoading: observable,
        isInitalLoading: observable,
        meetingMoms: observable,
        fetchMeetingMoms: action,
        deleteItem: action,
        });
    }

    override initialize(): void {
        this.entityName = 'Minutes of Meeting';
        this.columns = meetingDetailsMomListTablecolumns;
        this.fields = this.meetingDetailsMomFormConfig.formSections;
        this.mainId = this.meetingDetailsStore.details.id;
        this.actionConfig = {
            isEditable: true,
            isDeletable: true,
            isActivatable: false,
        };
    }

    override loadItems(): void {
        this.fetchMeetingMoms();
    }

   
    get service() {
        return this.meetingDetailsMomService;
    }
  

    fetchMeetingMoms(): void {
        const meetingId = this.meetingDetailsStore.details?.id;
        if (!meetingId) {
            console.error('Meeting details ID is missing');
            return;
        }  
        this.isLoading = true;
        this.service.list(meetingId).subscribe({
        next: (data: any) => {
            runInAction(() => {
            this.meetingMoms = this.conversion.resToTrnMeetingDetailsMomDTO(data);
            this.isInitalLoading = false;
            this.isLoading = false;
            });
        },
        error: (error: any) => {
            console.error('Failed to fetch details:', error);
            runInAction(() => {
            this.isLoading = false;
            });
        },
        });
    }

    updateOptionsWhileModalOpen() : Observable<{ meetingAgendas: MeetingAgendaListDTO[] }>{
        if (this.mainId) {      
            return forkJoin({
                meetingAgendas: this.meetingAgendaService.list(this.mainId),
            }).pipe(
                tap(({ meetingAgendas }) => {
                    runInAction(() => {
                        this.meetingAgendas = meetingAgendas;
                    });
                }),
                catchError((error) => {
                    console.error('Error fetching master lists', error);
                    return throwError(() => error);  
                })
            );
        } else {
            console.warn('mainId is not defined');
            return of({ meetingAgendas: [] });
        }
    }
    
    updateFormOptions() {
        this.updateOptionsWhileModalOpen().subscribe({
            next: () => {
                this.meetingDetailsMomFormConfig.updateOptions(
                    this.fields,
                    this.meetingAgendas, // Ensure this refers to the correct property
                );
            },
            error: (error) => {
                console.error('Error updating options', error);
            },
        });
    }
    

    
    convertFormToDTO(): MeetingDetailsMomFormDTO {
        return this.conversion.formGroupToForm(this.formGroup);
    }

    convertResponseToForm(response: any): any {
        return this.conversion.resToForm(response);
    }

    deleteItem(momId: number): void {
        const meetingId = this.meetingDetailsStore.details.id;
        if (!meetingId || !momId) {
          this.alertService.error('something went wrong', 'error');
          return;
        }
        this.confirmService.confirmDeleteItem().then((isConfirmed) => {
          if (isConfirmed) {
            this.uiStore.startSaving();
            this.service.delete(meetingId, [momId]).subscribe({
              next: (response) => {
                this.uiStore.finishSaving();
                this.loadItems();
                this.alertService.success('deleted', 'deleted');
              },
              error: (error) => {
                this.uiStore.finishSaving();
                console.error('Error deleting KRI', error);
                this.alertService.success('error', 'error');
              },
            });
          }
        });
    }

    resetEntireState(): void {
        runInAction(() => {
        this.isLoading = false;
        this.isInitalLoading = true;
        this.meetingMoms = [];
        });
    }
}
