import { Routes } from '@angular/router';

export const potentialRiskRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import(
        './components/potential-risk-list/potential-risk-list.component'
      ).then((m) => m.PotentialRiskListComponent),
  },
];
