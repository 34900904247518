import { Component, OnDestroy, OnInit } from '@angular/core';
import { SlaContractActionPlanStore } from '../../../store/sla-contract-action-plan.store';
import { SlaContractActionPlanFormStore } from '../../../store/sla-contract-action-plan-form.store';
import { SideFormComponent } from '../../../../../../shared/components/forms/side-form/side-form.component';
import { SideFormModalComponent } from '../../../../../../shared/components/modals/side-form-modal/side-form-modal.component';
import { BasicTableComponent } from '../../../../../../shared/components/tables/basic-table/basic-table.component';
import { ListPageHeaderComponent } from '../../../../../../shared/components/layout/list-page-header/list-page-header.component';

@Component({
  selector: 'app-sla-contract-details-action-plans',
  standalone: true,
  imports: [
    ListPageHeaderComponent,
    BasicTableComponent,
    SideFormModalComponent,
    SideFormComponent,
  ],
  templateUrl: './sla-contract-details-action-plans.component.html',
  styleUrl: './sla-contract-details-action-plans.component.scss'
})
export class SlaContractDetailsActionPlansComponent implements OnInit, OnDestroy {
  constructor(
    public slaContractActionPlanStore: SlaContractActionPlanStore,
    public slaContractActionPlanFormStore: SlaContractActionPlanFormStore
  ) {}

    ngOnInit(): void {
      this.slaContractActionPlanStore.resetEntireState();
      this.slaContractActionPlanStore.loadItems();
      this.slaContractActionPlanFormStore.resetEntireState();
      this.slaContractActionPlanFormStore.closeSideForm();
    }

    ngOnDestroy(): void {
      this.slaContractActionPlanFormStore.resetEntireState();
      this.slaContractActionPlanStore.resetEntireState();

    }
}
