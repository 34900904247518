import { Routes } from '@angular/router';

export const riskHeatmapRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/risk-heatmap-page/risk-heatmap-page.component').then(
        (m) => m.RiskHeatmapPageComponent
      ),
  },
];
