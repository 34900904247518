import { Routes } from '@angular/router';

export const auditPlanRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import(
        './components/audit-plans/audit-plan-list/audit-plan-list.component'
      ).then((m) => m.AuditPlanListComponent),
  },
  {
    path: 'details',
    loadComponent: () =>
      import(
        './components/audit-plans/audit-plan-details/audit-plan-details-page/audit-plan-details-page.component'
      ).then((m) => m.AuditPlanDetailsPageComponent),
    children: [
      {
        path: 'overview',
        loadComponent: () =>
          import(
            './components/audit-plans/audit-plan-details/overview/audit-plan-details-overview/audit-plan-details-overview.component'
          ).then((m) => m.AuditPlanDetailsOverviewComponent),
      },
      {
        path: 'auditable-items',
        loadComponent: () =>
          import(
            './components/audit-plans/audit-plan-details/auditable-items/auditable-item-list/auditable-item-list.component'
          ).then((m) => m.AuditableItemListComponent),
      },
      {
        path: 'checklist',
        loadComponent: () =>
          import(
            './components/audit-plans/audit-plan-details/checklist/audit-plan-details-checklist/audit-plan-details-checklist.component'
          ).then((m) => m.AuditPlanDetailsChecklistComponent),
      },
      {
        path: 'audit-schedules',
        loadComponent: () =>
          import(
            './components/audit-plans/audit-plan-details/schedules/audit-plan-details-schedules/audit-plan-details-schedules.component'
          ).then((m) => m.AuditPlanDetailsSchedulesComponent),
      },
      {
        path: 'stakeholder-engagement',
        loadComponent: () =>
          import(
            './components/audit-plans/audit-plan-details/stakeholder/audit-plan-details-stakeholder/audit-plan-details-stakeholder.component'
          ).then((m) => m.AuditPlanDetailsStakeholderComponent),
      },
      {
        path: 'tools-resoruces-allocation',
        loadComponent: () =>
          import(
            './components/audit-plans/audit-plan-details/resource/audit-plan-details-resource/audit-plan-details-resource.component'
          ).then((m) => m.AuditPlanDetailsResourceComponent),
      },
      {
        path: 'budget-consideration',
        loadComponent: () =>
          import(
            './components/audit-plans/audit-plan-details/budget/audit-paln-details-budget/audit-paln-details-budget.component'
          ).then((m) => m.AuditPalnDetailsBudgetComponent),
      },
      {
        path: 'commencement-letter',
        loadComponent: () =>
          import(
            './components/audit-plans/audit-plan-details/commencement/audit-plan-details-commencement-letter/audit-plan-details-commencement-letter.component'
          ).then((m) => m.AuditPlanDetailsCommencementLetterComponent),
      },
    ],
  },
];
