import { Routes } from '@angular/router';

export const kriRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/kri-register-list/kri-register-list.component').then(
        (m) => m.KriListComponent
      ),
  },
  {
    path: ':id',
    loadComponent: () =>
      import(
        './components/kri-register-details/kri-register-details-page/kri-register-details-page.component'
      ).then((m) => m.KriRegisterDetailsPageComponent),
    children: [
      {
        path: 'overview',
        loadComponent: () =>
          import(
            './components/kri-register-details/overview/kri-register-details-overview/kri-register-details-overview.component'
          ).then((m) => m.KriRegisterDetailsOverviewComponent),
      },
      {
        path: 'reviews',
        loadComponent: () =>
          import(
            './components/kri-register-details/reviews/kri-value-reviews/kri-value-review-list/kri-value-review-list.component'
          ).then((m) => m.KriValueReviewListComponent),
      },
      {
        path: '**',
        redirectTo: 'overview',
      },
    ],
  },
];
