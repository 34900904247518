import { JsonPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UiStore } from '../../store/ui.store';
import { SideMenuStore } from '../../store/side-menu.store';
import { SideMenuItem, SideMenuSubItem } from '../../modals/side-menu.modal';

@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [NgIf, RouterModule, TranslateModule, JsonPipe],
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.scss',
})
export class SideMenuComponent {
  constructor(public uiStore: UiStore, public sideMenuStore: SideMenuStore) {}

  isSideMenuOpen = false;

  toggleSideMenuBar() {
    this.isSideMenuOpen = !this.isSideMenuOpen;
  }

  activateMainMenu(item: SideMenuItem) {
    this.isSideMenuOpen = true;
    this.sideMenuStore.activateMainMenu(item);
    if (item.module == 'settings') {
      this.toggleSideMenuBar();
    }
  }

  activateSubMainMenu(item: SideMenuSubItem) {
    this.isSideMenuOpen = true;
    this.sideMenuStore.activateSubMenu(item);
    this.toggleSideMenuBar();
  }
}
