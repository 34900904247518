import { Routes } from '@angular/router';

export const auditFindingsRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import(
        './components/audit-finding-list/audit-finding-list.component'
      ).then((m) => m.AuditFindingListComponent),
  },
  {
    path: ':id',
    loadComponent: () =>
      import(
        './components/audit-finding-details/audit-finding-details-page/audit-finding-details-page.component'
      ).then((m) => m.AuditFindingDetailsPageComponent),
    children: [
      {
        path: 'overview',
        loadComponent: () =>
          import(
            './components/audit-finding-details/audit-finding-details-overview/audit-finding-details-overview.component'
          ).then((m) => m.AuditFindingDetailsOverviewComponent),
      },
      {
        path: 'rca',
        loadComponent: () =>
          import(
            './components/audit-finding-details/audit-finding-details-rca/audit-finding-details-rca.component'
          ).then((m) => m.AuditFindingDetailsRcaComponent),
      },
      {
        path: 'action-plans',
        loadComponent: () =>
          import(
            './components/audit-finding-details/audit-finding-details-action-plans/audit-finding-details-action-plans.component'
          ).then((m) => m.AuditFindingDetailsActionPlansComponent),
      },
    ],
  },
];
