import { Component, OnDestroy, OnInit } from '@angular/core';
import { ListPageHeaderComponent } from '../../../../../../../shared/components/layout/list-page-header/list-page-header.component';
import { BasicTableComponent } from '../../../../../../../shared/components/tables/basic-table/basic-table.component';
import { ComplianceActionPlanFormStore } from '../../../../store/compliance-action-plan-form.store';
import { SideFormModalComponent } from '../../../../../../../shared/components/modals/side-form-modal/side-form-modal.component';
import { SideFormComponent } from '../../../../../../../shared/components/forms/side-form/side-form.component';
import { ComplianceActionPlanDetailsStore } from '../../../../store/compliance-action-plan-details.store';
import { ComplianceActionPlanStore } from '../../../../store/compliance-action-plan.store';
import { ComplianceDetailsActionPlanDetailsModalComponent } from '../compliance-details-action-plan-details-modal/compliance-details-action-plan-details-modal.component';
import { MainLoaderComponent } from '../../../../../../../shared/components/loaders/main-loader/main-loader.component';

@Component({
  selector: 'app-compliance-details-action-plans',
  standalone: true,
  imports: [
    ListPageHeaderComponent,
    BasicTableComponent,
    SideFormModalComponent,
    SideFormComponent,
    ComplianceDetailsActionPlanDetailsModalComponent,
    MainLoaderComponent,
  ],
  templateUrl: './compliance-details-action-plans.component.html',
  styleUrl: './compliance-details-action-plans.component.scss',
})
export class ComplianceDetailsActionPlansComponent
  implements OnInit, OnDestroy
{
  constructor(
    public complianceActionPlanStore: ComplianceActionPlanStore,
    public complianceActionPlanFormStore: ComplianceActionPlanFormStore,
    public complianceActionPlanDetailsStore: ComplianceActionPlanDetailsStore
  ) {}

  ngOnInit(): void {
    this.complianceActionPlanDetailsStore.closeModal();
    this.complianceActionPlanFormStore.closeSideForm();
    this.complianceActionPlanStore.initialize();
    this.complianceActionPlanStore.loadItems();
    this.complianceActionPlanFormStore.initialize();
  }

  ngOnDestroy(): void {
    this.complianceActionPlanFormStore.resetEntireState();
    this.complianceActionPlanStore.resetEntireState();
    this.complianceActionPlanFormStore.closeSideForm();
  }
}
