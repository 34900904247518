import { Routes } from '@angular/router';

export const strategicProfileRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import(
        './components/strategic-profile-page/strategic-profile-page.component'
      ).then((m) => m.StrategicProfilePageComponent),
  },
];
