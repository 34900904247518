import {
  QuickFilterItem,
  TableColumnConfigurable,
} from '../../../../core/modals/table.modal';
import { actionPlanListConfig } from '../../../../shared/config/action-plan-list.config';
import { DataCellSize, DataCellType } from '../../../../shared/dto/table.dto';

export const complianceActionPlanListQuickFilters: QuickFilterItem[] = [
  {
    label: 'All Items',
    key: '',
    isActive: true,
  },
  {
    label: 'Fully Compliant Items',
    key: 'fully_compliant',
    isActive: false,
  },
  {
    label: 'Pending Review',
    key: 'pending_review',
    isActive: false,
  },
];

export const complianceActionPlanListTableColumns: TableColumnConfigurable[] = [
  ...actionPlanListConfig.slice(0, 2),
  {
    type: DataCellType.Para,
    field: 'requirement',
    header: 'Requirement',
    sortable: true,
    size: DataCellSize.Para,
    isEnable: false,
    isRequired: false,
  },
  ...actionPlanListConfig.slice(2),
];
