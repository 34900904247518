import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QueryParamDTO } from '../../../../shared/dto/table.dto';
import { DocumentWorkflowDetailsDTO, DocumentWorkflowLevelFormDTO } from '../dto/document-workflow-level.dto';

@Injectable({
  providedIn: 'root'
})
export class DocumentWorkflowService {
    protected apiUrl = environment.apiUrl + '/workflows';

    constructor(private http: HttpClient) {}

    list(
      sortField: string = 'id',
      sortOrder: string = 'asc',
      currentPage?: number,
      pageSize?: number,
      searchQuery?: string,
      quickFilterKey?: string,
      dynamicFilters?: { [key: string]: number[] },
      extraQueryParams?: QueryParamDTO[]
    ): Observable<any[]> {
      let params = new HttpParams()
      .set('sort_by', sortField)
      .set('sort_order', sortOrder)

      if (searchQuery) {
        params = params.set('keyword', searchQuery);
      }
      if (extraQueryParams) {
        extraQueryParams.forEach((param) => {
          params = params.set(param.key, param.value);
        });
      }

      return this.http.get<any[]>(this.apiUrl, {
      params,
      });
    }

    getWorkflowLevels(workflowId: number): Observable<DocumentWorkflowDetailsDTO> {
      return this.http.get<DocumentWorkflowDetailsDTO>(
        `${this.apiUrl}/${workflowId}/levels`
      );
    }


    create(workflowId: number, item: DocumentWorkflowLevelFormDTO): Observable<DocumentWorkflowLevelFormDTO> {
      
      return this.http.post<DocumentWorkflowLevelFormDTO>(`${this.apiUrl}/${workflowId}/levels`, item);
    }


    delete(workflowId: number, id: number): Observable<any> {
      return this.http.delete(`${this.apiUrl}/${workflowId}/levels/${id}`);
    }
}
