import { Injectable } from '@angular/core';
import { makeAutoObservable } from 'mobx';
import { IMasterCRUDFormBaseStore } from '../../../../../core/store/master-crud-base.store';
import { TableQuickSortItem } from '../../../../../core/modals/table.modal';

@Injectable({ providedIn: 'root' })
export class IncidentActionPlanFormStore implements IMasterCRUDFormBaseStore {
  constructor() {
    makeAutoObservable(this);
  }
  formTitle: string;
  addButtonLabel: string = 'Add Action Plan';
  showModal: boolean;
  submitButtonTitle: string;
  submitButtonLoadingText: string;
  cancelButtonText: string;
  openModal: () => void;
  closeModal: () => void;
  refreshItems: () => void;

  quickSortItems: TableQuickSortItem[] = [
    { label: 'Newest to Oldest', key: '', active: false },
    { label: 'Active to Inactive', key: '', active: false },
    { label: 'Alphabetical Order (A to Z)', key: '', active: false },
  ];
}
