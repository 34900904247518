<header class="page-header">
  @if(currentUser){
    <ul class="right-links">
      <li class="notify" (click)="notificationStore.togglePanel()">
        <div class="dropdown">
          <button class="btn dropdown-toggle p-0 drop-arrow-remove" type="button">
            @if(notificationStore.newCount > 0){
              <span class="notification-count">{{notificationStore.newCount}}</span>
            }
            <img
              alt="images"
              src="assets/images/icons/icon-notification.svg"
              class="me-0 w-auto"/>
          </button>
        </div>
      </li>
      <li class="profile me-3">
        <div class="dropdown">
          <button
            class="btn dropdown-toggle p-0"
            type="button"
            id="userprof"
            data-bs-toggle="dropdown"
            aria-expanded="false">

            <!-- <img
              alt="images"
              src="assets/images/user-images/sample-user.jpg"
              class="me-2 pro-img"/> -->
           <div class="d-inline-block me-2"> 
              <app-user-image
                [type]="'small'"
                [color]="currentUser.color"
                [initial]="currentUser.initial">
              </app-user-image>
            </div>  
            <span class="profile-name d-inline-block pt-1">{{
              currentUser.first_name + " " + currentUser.last_name
            }}</span>
          </button>

          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="userprof">
            <div class="p-4 pb-1 text-center bt-bordered">

              <div class="d-flex justify-content-center">
              <app-user-image
                [type]="'normal'"
                [color]="currentUser.color"
                [initial]="currentUser.initial">
              </app-user-image>
            </div>
              <!-- <img
                alt="images"
                src="assets/images/user-images/profile-user-dropdown.svg "
                class="mx-auto d-block profile-image"
              /> -->
              <h5>{{ currentUser.first_name + " " + currentUser.last_name }}</h5>
              <p class="text-center p-mail-id">{{ currentUser.email }}</p>
            </div>
            <ul>
              <li>
                <a class="dropdown-item" href="javascript:void(0)">
                  <img src="assets/images/icons/icon-view-profile.svg" />View
                  Profile
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item"
                  [routerLink]="'/organization/strategic-profile'"
                >
                  <img
                    src="assets/images/icons/icon-company-profile.svg"
                  />Company Profile
                </a>
              </li>
              <li>
                <a class="dropdown-item" [routerLink]="'/organization/users'">
                  <img src="assets/images/icons/icon-team.svg" />Team
                </a>
              </li>
              <!-- <li>
                <a class="dropdown-item " href="#">
                  <img src="assets/images/icons/icon-invite-colleagues.svg" />Invite Colleagues
                </a>
            </li> -->
              <!-- <li>
              <a class="dropdown-item " href="#"><img src="assets/images/icons/icon-catelog.svg" />Catelog</a>
          </li> -->
              <li>
                <a
                  class="dropdown-item"
                  href="https://jethur.com/contact"
                  target="_blank"
                >
                  <img src="assets/images/icons/icon-support.svg" />Support
                </a>
              </li>
              <li (click)="logout()">
                <a class="dropdown-item" href="javascript:void(0)">
                  <img src="assets/images/icons/icon-logout.svg" />Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  }

</header>

<app-notification-panel></app-notification-panel>
