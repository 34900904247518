import { Injectable } from '@angular/core';
import { SlaContractActionPlanDTO, SlaContractActionPlanDetailsDTO, SlaContractActionPlanFormDTO } from '../dto/sla-contract-action-plan.dto';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '../../../../core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class SlaContractActionPlanService extends BaseService<SlaContractActionPlanDTO, SlaContractActionPlanDetailsDTO, SlaContractActionPlanFormDTO> {
    protected apiUrl: string; 
  
    constructor(http: HttpClient) {
        super(http);
    }

    getSlaActionPlanList(
        complianceId:number,
        sortField: string = '',
        sortOrder: string = 'asc',
        currentPage: number = 1,
        pageSize: number = 10,
        searchQuery?: string,
        quickFilterKey?: string,
        dynamicFilters?: { [key: string]: number[] }
    ): Observable<SlaContractActionPlanDTO[]> {
        this.apiUrl = environment.apiUrl + `/slas/${complianceId}/action-plans`;
        return this.list(
            sortField,
            sortOrder,
            currentPage,
            pageSize,
            searchQuery,
            quickFilterKey,
            dynamicFilters
        );
        
    }


    createSlaActionPlan(complianceId:number, item: SlaContractActionPlanFormDTO): Observable<SlaContractActionPlanFormDTO> {
        this.apiUrl = environment.apiUrl + `/slas/${complianceId}/action-plans`;
        return this.create(item);
    }
    
    getSlaActionPlan(complianceId:number, id: number): Observable<SlaContractActionPlanDetailsDTO> {
        this.apiUrl = environment.apiUrl + `/slas/${complianceId}/action-plans`;
        return this.get(id);
    }

    updateSlaActionPlan(complianceId:number, id: number, item: SlaContractActionPlanFormDTO): Observable<SlaContractActionPlanFormDTO> {
        this.apiUrl = environment.apiUrl + `/slas/${complianceId}/action-plans`;
        return this.update(id, item);
    }
}