import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { FormSection } from '../../../../shared/models/form.config';
import { ComplianceStatusService } from '../../../settings/compliance/services/compliance-status.service';
import { SeverityLevelService } from '../../../settings/masters/services/severity-level.service';
import { UserService } from '../../../organization/users/services/user.service';
import { ComplianceRequirementCategoryService } from '../../../settings/compliance/services/compliance-requirement-category.service';
import { StrategicObjectiveService } from '../../../organization/strategic-profile/services/strategic-objective.service';
import { ControlService } from '../../../organization/controls/services/control.service';
import { mapToOptions, mapToUsers } from '../../../../core/modals/form.modal';

@Injectable({ providedIn: 'root' })
export class ComplianceRequirementFormConfig {
  formSections: FormSection[] = [
    {
      key: '',
      title: '',
      fields: [
        {
          id: 'clauseNumber',
          label: 'Clause Number',
          formControlName: 'ClauseNumber',
          type: 'text',
          placeholder: 'Enter the clause no',
          size: 3,
          isInfoIcon: true,
          validators: [],
        },
        {
          id: 'clauseTitle',
          label: 'Clause',
          formControlName: 'ClauseTitle',
          type: 'text',
          placeholder: 'Enter the clause',
          size: 9,
          isInfoIcon: true,
          validators: [],
        },
        {
          id: 'title',
          label: 'Requirement',
          formControlName: 'Title',
          type: 'textarea',
          placeholder: 'Provide a detailed requirement',
          size: 12,
          isInfoIcon: true,
          validators: ['required'],
        },
        {
          id: 'requirementCategory',
          label: 'Category',
          formControlName: 'RequirementCategory',
          type: 'autocomplete-static',
          placeholder: 'Select the category',
          size: 6,
          isInfoIcon: false,
          service: this.complianceRequirementCategoryService,
          serviceMethod: 'getComplianceRequirementCategories',
          validators: ['required'],
        },
        {
          id: 'severityLevel',
          label: 'Severity Level',
          formControlName: 'SeverityLevel',
          type: 'autocomplete-static',
          placeholder: 'Select the severity level',
          size: 6,
          isInfoIcon: false,
          service: this.severityLevelService,
          serviceMethod: 'getSeverityLevels',
          validators: ['required'],
        },
        {
          id: 'responsibleUser',
          label: 'Responsible User',
          formControlName: 'ResponsibleUser',
          type: 'autocomplete-static',
          placeholder: 'Select the responsible user',
          size: 12,
          isInfoIcon: false,
          service: this.userService,
          serviceMethod: 'list',
          validators: ['required'],
        },
        {
          id: 'complianceDeadline',
          label: 'Compliance Deadline',
          formControlName: 'ComplianceDeadline',
          type: 'datepicker',
          placeholder: 'Select the compliance deadline',
          size: 6,
          isInfoIcon: true,
          validators: ['required'],
        },
        {
          id: 'complianceStatus',
          label: 'Compliance Status',
          formControlName: 'ComplianceStatus',
          type: 'autocomplete-static',
          placeholder: 'Select the compliance status',
          size: 6,
          isInfoIcon: true,
          service: this.complianceStatusService,
          serviceMethod: 'getComplianceStatuses',
          validators: ['required'],
        },
      ],
    },
    {
      key: 'associated_items',
      title: 'Associated Items',
      fields: [
        {
          id: 'strategicObjective',
          label: 'Strategic Objectives',
          formControlName: 'StrategicObjectives',
          type: 'autocomplete-static',
          placeholder: 'Select the strategic objectives',
          isMulitple: true,
          size: 12,
          isInfoIcon: false,
          service: this.strategicObjectiveService,
          serviceMethod: 'list',
          validators: [],
        },
        {
          id: 'control',
          label: 'Controls',
          formControlName: 'Controls',
          type: 'autocomplete-static',
          placeholder: 'Select the controls',
          isMulitple: true,
          size: 12,
          isInfoIcon: false,
          service: this.controlService,
          serviceMethod: 'list',
          validators: [],
        },
      ],
    },
  ];

  constructor(
    private userPreference: CurrentUserPreferenceStore,
    private complianceStatusService: ComplianceStatusService,
    private severityLevelService: SeverityLevelService,
    private complianceRequirementCategoryService: ComplianceRequirementCategoryService,
    private userService: UserService,
    private strategicObjectiveService: StrategicObjectiveService,
    private controlService: ControlService
  ) {}

  updateOptions(
    fields: FormSection[],
    severityLevels: any[],
    requirementCategories: any[],
    complianceStatuses: any[],
    responsibleUsers: any,
    strategicObjectives: any,
    controls: any
  ): void {
    const languageKey: string = this.userPreference.languageKey || 'en';
    fields.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.id === 'severityLevel') {
          field.options = severityLevels.map((severityLevel) => ({
            id: severityLevel.id,
            title: severityLevel[`title_${languageKey}`],
          }));
        }
        if (field.id === 'requirementCategory') {
          field.options = requirementCategories.map((category) => ({
            id: category.id,
            title: category[`title_${languageKey}`],
          }));
        }
        if (field.id === 'complianceStatus') {
          field.options = complianceStatuses.map((complianceStatus) => ({
            id: complianceStatus.id,
            title: complianceStatus[`title_${languageKey}`],
          }));
        }

        if (field.id === 'responsibleUser') {
          field.options = mapToUsers(responsibleUsers, languageKey);
        }

        if (field.id === 'strategicObjective') {
          field.options = strategicObjectives.map(
            (strategicObjective: any) => ({
              id: strategicObjective.id,
              title: strategicObjective[`title_${languageKey}`],
            })
          );
        }

        if (field.id === 'control') {
          field.options = mapToOptions(controls);
        }
      });
    });
  }
}
