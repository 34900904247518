export interface WorkFlowLevelTypeDTO {
  id: number;
  type: string;
  title_en: string;
  title_ar?: string;
  status?: string;
}

export interface WorkFlowLevelTypeFormDTO {
  id?: number;
  type: string;
  title_en: string;
  title_ar: string;
  status: string;
}

export interface WorkFlowLevelTypeDetailsDTO {
  id: number;
  type: string;
  title: string;
  status: string;
}

export function convertResponseToWorkFlowLevelTypeDetailsDTO(
  response: any,
  lang: string
): WorkFlowLevelTypeDetailsDTO {
  return {
    id: response.id,
    type: response.type,
    title: response[`title_${lang}`],
    status: response.status,
  };
}
