<div id="tab2">
    <div class="row mt-4">
        <div class="col-md-12">
            <div class="accordion theme-accordion" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#control1" aria-expanded="true" aria-controls="collapseOne">
                            Controls (4)
                        </button>
                    </h2>
                    <div id="control1" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <div class="row mt-4">
                                <div class="col-md-12 d-flex justify-content-end">
                                    <div class="me-2">
                                        <input type="email" class="form-control search-box" style="min-width: 300px"
                                            id="exampleFormControlInput1" placeholder="Search here..." />
                                    </div>

                                    <div class="me-2">
                                        <div
                                            class="dropdown more-dropdown dynamic-filter dynamic-filter-dropdown btn-grey">
                                            <button class="btn btn-secondary btn-grey mb-3" type="button"
                                                id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                <img src="assets/images/icons/icon-filter-list.svg" />
                                                Filters
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive">
                                        <table class="table table-bordered theme-table mb-0 mt-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style="width: 150px">
                                                        Control ID
                                                        <a class="table-filter"><img
                                                                src="assets/images/icons/column-filter.svg" /></a>
                                                    </th>
                                                    <th scope="col">
                                                        Control Category
                                                        <a class="table-filter"><img
                                                                src="assets/images/icons/column-filter.svg" /></a>
                                                    </th>

                                                    <th scope="col" style="min-width: 150px">
                                                        Control type
                                                        <a class="table-filter"><img
                                                                src="assets/images/icons/column-filter.svg" /></a>
                                                    </th>
                                                    <th scope="col">
                                                        Control description
                                                        <a class="table-filter"><img
                                                                src="assets/images/icons/column-filter.svg" /></a>
                                                    </th>
                                                    <th scope="col">
                                                        Efficency
                                                        <a class="table-filter"><img
                                                                src="assets/images/icons/column-filter.svg" /></a>
                                                    </th>
                                                    <th scope="col">
                                                        Implementation status
                                                        <a class="table-filter"><img
                                                                src="assets/images/icons/column-filter.svg" /></a>
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>C1</td>
                                                    <td>Access Controls</td>
                                                    <td>
                                                        Technical
                                                    </td>
                                                    <td>Strong authentication mechanisms</td>
                                                    <td><span class="status-success">Efficient</span></td>
                                                    <td><span class="status-warning">In progress</span></td>
                                                </tr>
                                                <tr>
                                                    <td>C2</td>
                                                    <td>Access Controls</td>
                                                    <td>
                                                        Technical
                                                    </td>
                                                    <td>Strong authentication mechanisms</td>
                                                    <td><span class="status-success">Efficient</span></td>
                                                    <td><span class="status-warning">In progress</span></td>
                                                </tr>
                                                <tr>
                                                    <td>C3</td>
                                                    <td>Access Controls</td>
                                                    <td>
                                                        Technical
                                                    </td>
                                                    <td>Strong authentication mechanisms</td>
                                                    <td><span class="status-success">Efficient</span></td>
                                                    <td><span class="status-warning">In progress</span></td>
                                                </tr>
                                                <tr>
                                                    <td>C4</td>
                                                    <td>Access Controls</td>
                                                    <td>
                                                        Technical
                                                    </td>
                                                    <td>Strong authentication mechanisms</td>
                                                    <td><span class="status-success">Efficient</span></td>
                                                    <td><span class="status-warning">In progress</span></td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#risk1"
                            aria-expanded="true" aria-controls="collapseOne">
                            Risk (10)
                        </button>
                    </h2>
                    <div id="risk1" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <div class="row mt-4">
                                <div class="col-md-12 d-flex justify-content-end">
                                    <div class="me-2">
                                        <input type="email" class="form-control search-box" style="min-width: 300px"
                                            id="exampleFormControlInput1" placeholder="Search here..." />
                                    </div>

                                    <div class="me-2">
                                        <div
                                            class="dropdown more-dropdown dynamic-filter dynamic-filter-dropdown btn-grey">
                                            <button class="btn btn-secondary btn-grey mb-3" type="button"
                                                id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                <img src="assets/images/icons/icon-filter-list.svg" />
                                                Filters
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive">
                                        <table class="table table-bordered theme-table mb-0 mt-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style="width: 150px">
                                                        Risk ID
                                                        <a class="table-filter"><img
                                                                src="assets/images/icons/column-filter.svg" /></a>
                                                    </th>
                                                    <th scope="col">
                                                        Risk Category
                                                        <a class="table-filter"><img
                                                                src="assets/images/icons/column-filter.svg" /></a>
                                                    </th>

                                                    <th scope="col" style="min-width: 300px">
                                                        Risk description
                                                        <a class="table-filter"><img
                                                                src="assets/images/icons/column-filter.svg" /></a>
                                                    </th>
                                                    <th scope="col">
                                                        Likelihood
                                                        <a class="table-filter"><img
                                                                src="assets/images/icons/column-filter.svg" /></a>
                                                    </th>
                                                    <th scope="col">
                                                        Impact
                                                        <a class="table-filter"><img
                                                                src="assets/images/icons/column-filter.svg" /></a>
                                                    </th>
                                                    <th scope="col">
                                                        Risk rating
                                                        <a class="table-filter"><img
                                                                src="assets/images/icons/column-filter.svg" /></a>
                                                    </th>
                                                    <th scope="col">
                                                        Status
                                                        <a class="table-filter"><img
                                                                src="assets/images/icons/column-filter.svg" /></a>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>R1</td>
                                                    <td>Unauthorized Access</td>
                                                    <td>
                                                        Unauthorized individuals gaining access to sensitive
                                                        data.
                                                    </td>
                                                    <td><span class="status-danger">High</span></td>
                                                    <td><span class="status-danger">High</span></td>
                                                    <td><span class="status-danger">High</span></td>
                                                    <td><span class="status-warning">Ongoing</span></td>
                                                </tr>
                                                <tr>
                                                    <td>R1</td>
                                                    <td>Unauthorized Access</td>
                                                    <td>
                                                        Unauthorized individuals gaining access to sensitive
                                                        data.
                                                    </td>
                                                    <td><span class="status-danger">High</span></td>
                                                    <td><span class="status-danger">High</span></td>
                                                    <td><span class="status-danger">High</span></td>
                                                    <td><span class="status-warning">Ongoing</span></td>
                                                </tr>
                                                <tr>
                                                    <td>R1</td>
                                                    <td>Unauthorized Access</td>
                                                    <td>
                                                        Unauthorized individuals gaining access to sensitive
                                                        data.
                                                    </td>
                                                    <td><span class="status-danger">High</span></td>
                                                    <td><span class="status-danger">High</span></td>
                                                    <td><span class="status-danger">High</span></td>
                                                    <td><span class="status-warning">Ongoing</span></td>
                                                </tr>
                                                <tr>
                                                    <td>R1</td>
                                                    <td>Unauthorized Access</td>
                                                    <td>
                                                        Unauthorized individuals gaining access to sensitive
                                                        data.
                                                    </td>
                                                    <td><span class="status-danger">High</span></td>
                                                    <td><span class="status-danger">High</span></td>
                                                    <td><span class="status-danger">High</span></td>
                                                    <td><span class="status-warning">Ongoing</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#risk2" aria-expanded="false" aria-controls="collapseTwo">
                            Incidents (3)
                        </button>
                    </h2>
                    <div id="risk2" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">No Data</div>
                    </div>
                </div>
                <div class="accordion-item opacity-50">
                    <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#risk3" aria-expanded="false" aria-controls="collapseThree">
                            Documents (0)
                        </button>
                    </h2>
                    <div id="risk3f" class="accordion-collapse collapse" aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 