import { Component } from '@angular/core';
import { CurrentUserSessionStore } from '../../../core/store/current-user-session.store';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CurrentUserPreferenceStore } from '../../../core/store/current-user-preference.store';
import { SpiderChartComponent } from '../../../shared/components/layout/charts/spider-chart/spider-chart.component';
import { SpiderChartDataDTO } from '../../../core/modals/chart.modal';

@Component({
  selector: 'app-my-dashboard',
  standalone: true,
  imports: [SpiderChartComponent],
  templateUrl: './my-dashboard.component.html',
  styleUrl: './my-dashboard.component.scss',
})
export class MyDashboardComponent {
  constructor(
    private currentUserSessionStore: CurrentUserSessionStore,
    public currentUserPreferenceStore: CurrentUserPreferenceStore,
    private router: Router,
    private titleService: Title
  ) {}

  data: SpiderChartDataDTO[] = [
    {
      item: 'ISO 9001',
      value: 85, // Compliance score out of 100
    },
    {
      item: 'ISO 14001',
      value: 78, // Compliance score out of 100
    },
    {
      item: 'ISO 27001',
      value: 92, // Compliance score out of 100
    },
    {
      item: 'ISO 45001',
      value: 80, // Compliance score out of 100
    },
    {
      item: 'ISO 20000',
      value: 75, // Compliance score out of 100
    },
  ];

  ngOnInit() {
    this.setTitle('My Dashboard - Jethur GRC');
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  logout() {
    this.currentUserSessionStore.logout();
    this.router.navigate(['/auth/login']);
  }
}
