import { Routes } from '@angular/router';

export const riskRegisterRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import(
        './components/risk-register-list/risk-register-list.component'
      ).then((m) => m.RiskRegisterListComponent),
  },
  {
    path: ':id',
    loadComponent: () =>
      import(
        './components/risk-register-details/risk-register-details-page/risk-register-details-page.component'
      ).then((m) => m.RiskRegisterDetailsPageComponent),
    children: [
      {
        path: 'overview',
        loadComponent: () =>
          import(
            './components/risk-register-details/overview/risk-register-details-overview/risk-register-details-overview.component'
          ).then((m) => m.RiskRegisterDetailsOverviewComponent),
      },
      {
        path: 'rca',
        loadComponent: () =>
          import(
            './components/risk-register-details/rca/risk-register-details-root-cause-analysis/risk-register-details-root-cause-analysis.component'
          ).then((m) => m.RiskRegisterDetailsRootCauseAnalysisComponent),
      },
      {
        path: 'risk-analysis',
        loadComponent: () =>
          import(
            './components/risk-register-details/ra/risk-register-details-risk-analysis/risk-register-details-risk-analysis.component'
          ).then((m) => m.RiskRegisterDetailsRiskAnalysisComponent),
      },
      {
        path: 'associated-items',
        loadComponent: () =>
          import(
            './components/risk-register-details/associated-items/risk-register-details-associated-items/risk-register-details-associated-items.component'
          ).then((m) => m.RiskRegisterDetailsAssociatedItemsComponent),
      },
      {
        path: 'control-analysis',
        loadComponent: () =>
          import(
            './components/risk-register-details/control-analysis/risk-register-details-control-analysis/risk-register-details-control-analysis.component'
          ).then((m) => m.RiskRegisterDetailsControlAnalysisComponent),
      },
      {
        path: 'risk-response',
        loadComponent: () =>
          import(
            './components/risk-register-details/risk-response/risk-register-details-risk-response/risk-register-details-risk-response.component'
          ).then((m) => m.RiskRegisterDetailsRiskResponseComponent),
      },
      {
        path: 'kri',
        loadComponent: () =>
          import(
            './components/risk-register-details/kri/risk-register-details-key-risk-indicators/risk-register-details-key-risk-indicators.component'
          ).then((m) => m.RiskRegisterDetailsKeyRiskIndicatorsComponent),
      },
      {
        path: 'kri',
        loadComponent: () =>
          import(
            './components/risk-register-details/kri/risk-register-details-key-risk-indicators/risk-register-details-key-risk-indicators.component'
          ).then((m) => m.RiskRegisterDetailsKeyRiskIndicatorsComponent),
      },
      {
        path: 'residual-risk-analysis',
        loadComponent: () =>
          import(
            './components/risk-register-details/residual-risk-analysis/risk-register-details-residual-risk-analysis/risk-register-details-residual-risk-analysis.component'
          ).then((m) => m.RiskRegisterDetailsResidualRiskAnalysisComponent),
      },
      {
        path: 'residual-control-analysis',
        loadComponent: () =>
          import(
            './components/risk-register-details/residual-control-analysis/risk-register-details-residual-control-analysis/risk-register-details-residual-control-analysis.component'
          ).then((m) => m.RiskRegisterDetailsResidualControlAnalysisComponent),
      },
      {
        path: 'risk-report',
        loadComponent: () =>
          import(
            './components/risk-register-details/risk-report/risk-register-details-risk-report/risk-register-details-risk-report.component'
          ).then((m) => m.RiskRegisterDetailsRiskReportComponent),
      },
      {
        path: '**',
        redirectTo: 'overview',
      },
    ],
  },
];
