import { runInAction } from 'mobx';
import { Injectable } from '@angular/core';
import { IMasterCRUDActionBaseStore } from '../../../../../core/store/master-crud-base.store';
import { DocumentWorkflowService } from '../../services/document-workflow.service';
import { UiStore } from '../../../../../core/store/ui.store';
import { ConfirmService } from '../../../../../core/services/confirm.service';
import { AlertService } from '../../../../../core/services/alert.service';
import { DocumentWorkflowLevelDetailsStore } from './document-workflow-level.store';

@Injectable({
  providedIn: 'root',
})
export class DocumentWorkflowLevelActionStore
  implements IMasterCRUDActionBaseStore
{
  constructor(
    private documentWorkflowLevelDetailsStore: DocumentWorkflowLevelDetailsStore,
    private documentWorkflowService: DocumentWorkflowService,
    private uiStore: UiStore,
    private confirmService: ConfirmService,
    private alertService: AlertService
  ) {}

  activateItem(levelId: number): void {}

  deactivateItem(levelId: number): void {}

  deleteItem(levelId: number): void {
    this.confirmService.confirmDeleteItem().then((isConfirmed) => {
      if (isConfirmed) {
        this.uiStore.startSaving();
        this.documentWorkflowService
          .delete(
            this.documentWorkflowLevelDetailsStore.workFlowDetails.id,
            levelId
          )
          .subscribe({
            next: () => {
              runInAction(() => {
                this.uiStore.finishSaving();
                this.alertService.success('Deleted!', 'Success');
                this.refreshItems();
              });
            },
            error: (error: any) => {
              this.uiStore.finishSaving();
              this.alertService.error(
                'Error deleting incident sub category!',
                'Error!'
              );
              console.error('Error deleting incident sub category', error);
            },
          });
      }
    });
  }

  refreshItems(): void {
    this.documentWorkflowLevelDetailsStore.fetchDetails(
      this.documentWorkflowLevelDetailsStore.workFlowDetails.id
    );
  }
}
