<div id="tab3">
  <div class="row mt-4">
    <div class="col-md-12">
      @if(complianceDetailsAssociatedItemStore.isLoading){
      <app-main-loader [height]="200" [width]="200"></app-main-loader>
      }@else {
      <div>
        <app-associated-item
          [id]="'associatedRiskId'"
          [title]="'Risks'"
          [total]="complianceDetailsAssociatedItemStore.riskPagConf.totalCount"
          [coloums]="complianceDetailsAssociatedItemStore.riskColumns"
          [items]="complianceDetailsAssociatedItemStore.risks"
          [pageConf]="complianceDetailsAssociatedItemStore.riskPagConf"
          (pageChangeRequest)="complianceDetailsAssociatedItemStore.loadAssociatedRisks()"
          (searchRequest)="complianceDetailsAssociatedItemStore.searchInRisks($event)">
        </app-associated-item>

        <app-associated-item
          [id]="'associatedIncidentId'"
          [title]="'Incidents'"
          [total]="complianceDetailsAssociatedItemStore.incidentPagConf.totalCount"
          [coloums]="complianceDetailsAssociatedItemStore.incidentColumns"
          [items]="complianceDetailsAssociatedItemStore.incidents"
          [pageConf]="complianceDetailsAssociatedItemStore.incidentPagConf"
          (pageChangeRequest)="complianceDetailsAssociatedItemStore.loadAssociatedIncidents()"
          (searchRequest)="complianceDetailsAssociatedItemStore.searchInIncidents($event)">
        </app-associated-item>

        <app-associated-item
          [id]="'associatedControlId'"
          [title]="'Controls'"
          [total]="complianceDetailsAssociatedItemStore.controlPagConf.totalCount"
          [coloums]="complianceDetailsAssociatedItemStore.controlColumns"
          [items]="complianceDetailsAssociatedItemStore.controls"
          [pageConf]="complianceDetailsAssociatedItemStore.controlPagConf"
          (pageChangeRequest)="complianceDetailsAssociatedItemStore.loadAssociatedControls()"
          (searchRequest)="complianceDetailsAssociatedItemStore.searchInControls($event)">
        </app-associated-item>
      </div>
      }
    </div>
  </div>
</div>
