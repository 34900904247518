import { Routes } from '@angular/router';

export const processRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/process-list/process-list.component').then(
        (m) => m.ProcessListComponent
      ),
  },
  {
    path: ':id',
    loadComponent: () =>
      import(
        './components/process-details/process-details-page/process-details-page.component'
      ).then((m) => m.ProcessDetailsPageComponent),
    children: [
      {
        path: 'overview',
        loadComponent: () =>
          import(
            './components/process-details/overview/process-details-overview/process-details-overview.component'
          ).then((m) => m.ProcessDetailsOverviewComponent),
      },
      {
        path: 'controls',
        loadComponent: () =>
          import(
            './components/process-details/controls/process-details-controls/process-details-controls.component'
          ).then((m) => m.ProcessDetailsControlsComponent),
      },
      {
        path: 'associated-items',
        loadComponent: () =>
          import(
            './components/process-details/associated-items/process-details-associated-items/process-details-associated-items.component'
          ).then((m) => m.ProcessDetailsAssociatedItemsComponent),
      }
    ]

  }

];
