import { Injectable } from '@angular/core';
import { ComplianceActionPlanListDTO } from '../dto/compliance-action-plan.dto';
import {
  defaultActionConfig,
  defaultPaginationConfig,
  FilterCategory,
  PaginationConfig,
  QuickFilterItem,
  TableActionConfig,
  TableColumnConfigurable,
} from '../../../../core/modals/table.modal';
import { ComplianceActionPlanService } from '../services/compliance-action-plan.service';
import { ComplianceDetailsStore } from './compliance-details.store';
import { ListBaseStore } from '../../../../core/store/list-base.store';
import {
  complianceActionPlanListQuickFilters,
  complianceActionPlanListTableColumns,
} from '../config/compliance-action-plan-list.config';
import { ComplianceActionPlanConversion } from '../conversion/compliance-action-plan.conversion';
import { ConfirmService } from '../../../../core/services/confirm.service';
import { AlertService } from '../../../../core/services/alert.service';

@Injectable({ providedIn: 'root' })
export class ComplianceActionPlanStore extends ListBaseStore<ComplianceActionPlanListDTO> {
  override sortField: string = '';
  override columns: TableColumnConfigurable[];
  override quickFilters: QuickFilterItem[];
  override filterCategories: FilterCategory[] = [];
  actionConfig: TableActionConfig;

  constructor(
    private complianceActionPlanService: ComplianceActionPlanService,
    private complianceDetailsStore: ComplianceDetailsStore,
    private complianceActionPlanConv: ComplianceActionPlanConversion,
    protected override  confirmService: ConfirmService,
    protected override alertService: AlertService
  ) {
    super();
  }

  get service() {
    return this.complianceActionPlanService;
  }

  override initialize() {
    this.sortField = 'title';
    this.sortOrder = 'asc';
    this.actionConfig = defaultActionConfig;
    this.quickFilters = complianceActionPlanListQuickFilters;
    this.columns = complianceActionPlanListTableColumns;
    this.paginationConfig = defaultPaginationConfig;
    this.mainId = this.complianceDetailsStore.details.id;
    this.mainConv = this.complianceActionPlanConv;
  }

  override getMasterLists(): void {}

  resetEntireState(): void {
    this.sortField = '';
    this.sortOrder = '';
    this.searchQuery = '';
    this.isLoading = false;
    this.isInitialLoading = true;
    this.isEmptyList = true;
    this.mainId = undefined;
    this.mainConv = undefined;
    this.actionConfig = {} as TableActionConfig;
    this.paginationConfig = {} as PaginationConfig;
    this.filterCategories = [];
    this.items = [];
  }
}
