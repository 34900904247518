import { Routes } from '@angular/router';

export const incidentRegisterRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/incident-register/incident-register.component').then(
        (m) => m.IncidentRegisterComponent
      ),
  },
  {
    path: ':id',
    loadComponent: () =>
      import(
        './components/incident-details/incident-details-page/incident-details-page.component'
      ).then((m) => m.IncidentDetailsPageComponent),
    children: [
      {
        path: 'overview',
        loadComponent: () =>
          import(
            './components/incident-details/incident-details-overview/incident-details-overview.component'
          ).then((m) => m.IncidentDetailsOverviewComponent),
      },
      {
        path: 'associated-items',
        loadComponent: () =>
          import(
            './components/incident-details/associated-items/incident-details-associated-items/incident-details-associated-items.component'
          ).then((m) => m.IncidentDetailsAssociatedItemsComponent),
      },
      {
        path: 'investigation',
        loadComponent: () =>
          import(
            './components/incident-details/investigation/incident-details-investigation/incident-details-investigation.component'
          ).then((m) => m.IncidentDetailsInvestigationComponent),
      },
      {
        path: 'rca',
        loadComponent: () =>
          import(
            './components/incident-details/rca/incident-details-rca/incident-details-rca.component'
          ).then((m) => m.IncidentDetailsRcaComponent),
      },
      {
        path: 'commuincation-log',
        loadComponent: () =>
          import(
            './components/incident-details/incident-details-communication-log/incident-details-communication-log.component'
          ).then((m) => m.IncidentDetailsCommunicationLogComponent),
      },
      {
        path: 'action-plans',
        loadComponent: () =>
          import(
            './components/incident-details/action-plans/incident-details-action-plans/incident-details-action-plans.component'
          ).then((m) => m.IncidentDetailsActionPlansComponent),
      },
      {
        path: 'incident-closure',
        loadComponent: () =>
          import(
            './components/incident-details/closure/incident-details-incident-closure/incident-details-incident-closure.component'
          ).then((m) => m.IncidentDetailsIncidentClosureComponent),
      },
      {
        path: 'pir',
        loadComponent: () =>
          import(
            './components/incident-details/pir/incident-details-pir/incident-details-pir.component'
          ).then((m) => m.IncidentDetailsPirComponent),
      },
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
      },
    ],
  },
];
