import { Routes } from '@angular/router';

export const riskMitigationRoutes: Routes = [
    {
        path: '',
        loadComponent: () =>
        import('./components/risk-mitigation-list/risk-mitigation-list.component').then(
            (m) => m.RiskMitigationListComponent
        ),
    },
    {
        path: ':id',
        loadComponent: () =>
            import(
                './components/risk-mitigation-details/risk-mitigation-details-page/risk-mitigation-details-page.component'
            ).then((m) => m.RiskMitigationDetailsPageComponent),
            children: [
                {
                  path: 'overview',
                  loadComponent: () =>
                    import(
                        './components/risk-mitigation-details/risk-mitigation-details-overview/risk-mitigation-details-overview.component'
                    ).then((m) => m.RiskMitigationDetailsOverviewComponent),
                },
               
                {
                  path: '**',
                  redirectTo: 'overview',
                },
            ],
           
    },

];
