import { Component, OnDestroy, OnInit } from '@angular/core';
import { MainLoaderComponent } from '../../../../../../shared/components/loaders/main-loader/main-loader.component';
import { BreadcrumbsComponent } from '../../../../../../shared/components/breadcrumbs/breadcrumbs.component';
import { DetailsModalComponent } from '../../../../../../shared/components/modals/details-modal/details-modal.component';
import { SideFormComponent } from '../../../../../../shared/components/forms/side-form/side-form.component';
import { NoDataPageComponent } from '../../../../../../shared/components/pages/no-data-page/no-data-page.component';
import { UserImageComponent } from '../../../../../../shared/components/layout/widgets/user-image/user-image.component';
import { BasicTableComponent } from '../../../../../../shared/components/tables/basic-table/basic-table.component';
import { FormModalComponent } from '../../../../../../shared/components/modals/form-modal/form-modal.component';
import { ListPageHeaderComponent } from '../../../../../../shared/components/layout/list-page-header/list-page-header.component';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { DocumentWorkflowLevelDetailsStore } from '../../../store/document-workflow/document-workflow-level.store';
import { DocumentWorkflowLevelActionStore } from '../../../store/document-workflow/document-workflow-level-action.store';
import { WorkflowLevelFormComponent } from '../workflow-level-form/workflow-level-form.component';

@Component({
  selector: 'app-workflow-levels',
  standalone: true,
  imports: [
    RouterModule,
    ListPageHeaderComponent,
    FormModalComponent,
    BasicTableComponent,
    UserImageComponent,
    NoDataPageComponent,
    WorkflowLevelFormComponent,
    BreadcrumbsComponent,
    MainLoaderComponent
  ],
  templateUrl: './workflow-levels.component.html',
  styleUrl: './workflow-levels.component.scss'
})
export class WorkflowLevelsComponent implements OnInit, OnDestroy{
  private destroy$ = new Subject<void>();
  constructor(
    private route: ActivatedRoute,
    public documentWorkflowLevelDetailsStore: DocumentWorkflowLevelDetailsStore,
    public documentWorkflowLevelActionStore: DocumentWorkflowLevelActionStore

  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.documentWorkflowLevelDetailsStore.fetchDetails(Number(id));
        this.documentWorkflowLevelDetailsStore.initialize();
      }
    });
  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.documentWorkflowLevelDetailsStore.resetEntireState()
  }
}
