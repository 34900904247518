import { Routes } from '@angular/router';

export const riskMatrixRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import(
        './components/risk-matrix-page/risk-matrix-page.component'
      ).then((m) => m.RiskMatrixPageComponent),
  },
]