import { convertResponseToUserMinimalDTO, UserMinimalDTO } from "../../../organization/users/dto/user.dto";
import { convertResponseToRoleDetailsDTO, RoleDetailsDTO } from "../../general-settings/dto/role.dto";
import { convertResponseToWorkFlowLevelTypeDetailsDTO, WorkFlowLevelTypeDetailsDTO } from "../../general-settings/dto/workflow-level-types.dto";

export interface DocumentWorkflowDetailsDTO {
    id: number;
    type: string;
    document_type_id: number;
    status: string;
    workflow_levels:DocumentWorkflowLevelDTO[]
}

export interface DocumentWorkflowLevelDTO {
    id: number;
    level: number;
    workflow_id: number;
    approval_type: string;
    workflow_level_type: WorkFlowLevelTypeDetailsDTO| null
    participants : any[] 
}


export interface DocumentWorkflowLevelFormDTO {
    id?: number;
    parent_level?: number;
    level_type_id: number;
    approval_type ?: string;
    user_ids?: number[];
    role_id?: number;
}


export function convertResponseToDocumentWorkflowLevelDTO(response: any[],  lang: string): DocumentWorkflowLevelDTO[] {    
    return response.map((item) => {
        return {
            id: item.id,
            level: item.level,
            workflow_id: item.workflow_id,
            approval_type: item.approval_type,
            workflow_level_type: convertResponseToWorkFlowLevelTypeDetailsDTO(item.workflow_level_type, lang),
            participants: item.participants.map((participant: any) => {
                if (item.workflow_level_type.type === 'role') {
                    return convertResponseToRoleDetailsDTO(participant.role, lang);
                } else if (item.workflow_level_type.type === 'individual' || item.workflow_level_type.type === 'multiple-users') {
                    return convertResponseToUserMinimalDTO(participant.user, lang);
                }
                return []; // or handle the case differently if needed
            })
            
        };
    });
}
