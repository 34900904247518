import { Component, OnDestroy, OnInit } from '@angular/core';
import { UiStore } from '../../../../../../../core/store/ui.store';
import { RouterModule } from '@angular/router';
import { BasicTableComponent } from '../../../../../../../shared/components/tables/basic-table/basic-table.component';
import { ComplianceRequirementStore } from '../../../../store/compliance-requirement.store';
import { ComplianceDetailsStore } from '../../../../store/compliance-details.store';
import { ComplianceDetailsDTO } from '../../../../dto/compliance.dto';
import { SideFormModalComponent } from '../../../../../../../shared/components/modals/side-form-modal/side-form-modal.component';
import { SideFormComponent } from '../../../../../../../shared/components/forms/side-form/side-form.component';
import { ComplianceRequirementFormStore } from '../../../../store/compliance-requirement-form.store';
import { ListPageHeaderComponent } from '../../../../../../../shared/components/layout/list-page-header/list-page-header.component';
import { ComplianceDetailsRequirementDetailsModalComponent } from '../compliance-details-requirement-details-modal/compliance-details-requirement-details-modal.component';
import { ComplianceRequirementDetailsStore } from '../../../../store/compliance-requirement-details.store';
import { MainLoaderComponent } from '../../../../../../../shared/components/loaders/main-loader/main-loader.component';

@Component({
  selector: 'app-compliance-details-requirements',
  standalone: true,
  imports: [
    RouterModule,
    BasicTableComponent,
    SideFormModalComponent,
    SideFormComponent,
    ListPageHeaderComponent,
    ComplianceDetailsRequirementDetailsModalComponent,
    MainLoaderComponent,
  ],
  templateUrl: './compliance-details-requirements.component.html',
  styleUrl: './compliance-details-requirements.component.scss',
})
export class ComplianceDetailsRequirementsComponent
  implements OnInit, OnDestroy
{
  compliance: ComplianceDetailsDTO;

  constructor(
    public uiStore: UiStore,
    public complianceDetailsStore: ComplianceDetailsStore,
    public complianceRequirementStore: ComplianceRequirementStore,
    public complianceRequirementDetailsStore: ComplianceRequirementDetailsStore,
    public complianceRequirementFormStore: ComplianceRequirementFormStore
  ) {}

  ngOnInit(): void {
    this.complianceRequirementDetailsStore.closeModal();
    this.complianceRequirementFormStore.closeSideForm();
    this.complianceRequirementStore.initialize();
    this.complianceRequirementStore.loadItems();
    this.complianceRequirementFormStore.initialize();
  }

  ngOnDestroy(): void {
    this.complianceRequirementStore.resetEntireState();
    this.complianceRequirementFormStore.resetEntireState();
    this.complianceRequirementDetailsStore.resetEntireState();
  }
}
