import { Injectable } from '@angular/core';
import { ComplianceRequirementDetailsDTO } from '../dto/compliance-requirement.dto';
import { ComplianceRequirementService } from '../services/compliance-requirement.service';
import { runInAction } from 'mobx';
import { ComplianceDetailsStore } from './compliance-details.store';
import { FormSection } from '../../../../shared/models/form.config';
import { ComplianceStatusService } from '../../../settings/compliance/services/compliance-status.service';
import { UiStore } from '../../../../core/store/ui.store';
import { AlertService } from '../../../../core/services/alert.service';
import { TableColumnConfigurable } from '../../../../core/modals/table.modal';
import { StatusUpdateConversion } from '../../../../shared/conversion/status-update.conversion.dto';
import { DetailsStatusModalBaseStore } from '../../../../core/store/details-status-modal-base.store';
import { ComplinaceStatusUpdateConversion } from '../conversion/compliance-status-update.conversion';
import { ComplianceRequirementStatusService } from '../services/compliance-requirement-status.service';
import { ComplianceRequirementConversion } from '../conversion/compliance-requirement.conversion';
import { StatusDetailsLatestDTO } from '../../../../shared/dto/status-update.dto';
import { ComplianceRequirementStatusConfig } from '../config/compliance-requirement-status.config';
import { ComplianceRequirementDetailsAssociatedItemStore } from './compliance-requirement-details-associated-items.store';

@Injectable({ providedIn: 'root' })
export class ComplianceRequirementDetailsStore extends DetailsStatusModalBaseStore {
  protected override mainId: number | undefined;
  protected override subId: number | undefined;
  override formSections: FormSection[];
  protected override statusHistoryId: number | undefined;
  protected override statusTitle: string = 'compliance_status';
  
  override coloums: TableColumnConfigurable[];

  requirement: ComplianceRequirementDetailsDTO;

  constructor(
    private complianceDetailsStore: ComplianceDetailsStore,
    private complianceRequirementDetailsAssociatedItemStore: ComplianceRequirementDetailsAssociatedItemStore,
    private complianceRequirementStatusConfig: ComplianceRequirementStatusConfig,
    private complinaceStatusUpdateConv: ComplinaceStatusUpdateConversion,
    private complianceRequirementService: ComplianceRequirementService,
    private complianceRequirementConversion: ComplianceRequirementConversion,
    //for parent class
    statusConv: StatusUpdateConversion, //converting response in to status master format
    complianceRequirementStatusService: ComplianceRequirementStatusService, //for main service
    ComplianceStatusService: ComplianceStatusService, // for status service
    uiStore: UiStore,
    alertService: AlertService
  ) {
    super(
      uiStore,
      alertService,
      statusConv,
      complianceRequirementStatusService,
      ComplianceStatusService,
      complianceRequirementService
    );

    this.statusFormConfig = complianceRequirementStatusConfig;
    this.formSections = complianceRequirementStatusConfig.formSections;
    this.coloums = complianceRequirementStatusConfig.columns;
  }

  openModal(requirement: any) {
    this.mainId = this.complianceDetailsStore.details.id;
    this.subId = requirement.id;
    this.fetchSubItemDetails();
    this.fetchLatestStatus();
    this.fetchMasters();
    this.resetForm();
    this.fetchHistory();
    this.complianceRequirementDetailsAssociatedItemStore.setMainItemId(this.subId);
    this.complianceRequirementDetailsAssociatedItemStore.setService();
    this.complianceRequirementDetailsAssociatedItemStore.initializeData(false, false, true, false, false, true);

    this.showModal = true;
  }

  convertResToSubItemDetailsDTO(response: any) {
    return this.complianceRequirementConversion.resToComplianceRequirementDetailsDTO(
      response
    );
  }

  convertFormToUpdateStatusReq(): any {
    return this.complinaceStatusUpdateConv.formToUpdateStatusReq(
      this.formGroup
    );
  }

  convertResToStatusLatestDetailsDTO(response: any): any {
    return this.statusUpdateConversion.resToLatestStatusDetailsDTO(
      response,
      'compliance_status'
    );
  }

  protected override refreshDetails() {
    if (this.mainId) this.complianceDetailsStore.fetchDetails(this.mainId);
  }

  resetEntireState(): void {
    runInAction(() => {
      // Reset generic properties
      this.isInitialLoading = true;
      this.isFetching = false;
      this.showModal = false;
      this.mainId = undefined;
      this.subId = undefined;

      this.subItem = undefined;
      this.statusDetailsLatest = {} as StatusDetailsLatestDTO;

      // Reset form related properties
      this.isShowStatusForm = false;
      this.formGroup.reset();
      this.statuses = [];
      this.formSections = this.complianceRequirementStatusConfig.formSections;
      this.statusHistoryId = undefined;

      // Reset history related properties
      this.history = [];
      this.isShowHistory = false;
      this.actionConfig = {
        isEditable: true,
        isDeletable: false,
        isActivatable: false,
      };
      this.coloums = this.complianceRequirementStatusConfig.columns;
    });
  }
}
