import { Routes } from '@angular/router';

export const meetingRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import(
        './components/meeting-list/meeting-list.component'
      ).then((m) => m.MeetingListComponent),
  },
  {
    path: ':id',
    loadComponent: () =>
      import(
        './components/meeting-details/meeting-details-page/meeting-details-page.component'
      ).then((m) => m.MeetingDetailsPageComponent),
  }
]
